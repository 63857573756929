import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "400px",
  },
  error: {
    color: theme.palette.secondary.light,
    marginBottom: 0,
  },
  circularProgress: {
    position: "absolute",
  },
  header: {
    padding: "10px 10px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px 18px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  select: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 5,
    paddingTop: 13,
    paddingBottom: 12,
  },
  helperText: {
    width: 314,
    marginBottom: "5px",
  },
  snackbar: {
    alignItems: "center",
  },
}));

export default function AddUser(props) {
  const { currentUser, setOpenAddUser, dealers, setDealers, users, setUsers } =
    props;
  const classes = useStyles();
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState({});
  const [errors, setErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const isAdmin = currentUser.name === process.env.REACT_APP_ADMIN_ID;

  const handleCloseErrorSnackbar = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenAddUser(false);
  };

  const handleDealerChange = (e) => {
    if (e.target.value === "") {
      setSelectedDealer({});
    } else {
      const array = dealers.filter((dealer) => dealer.name === e.target.value);
      const newDealer = array[0];
      setSelectedDealer(newDealer);
    }
  };

  const handleNewUser = () => {
    const newErrors = {};
    if (!user.email) {
      newErrors.email = "Must not be empty";
    }
    if (!user.password) {
      newErrors.password = "Must not be empty";
    }
    if (!user.confirmPassword) {
      newErrors.confirmPassword = "Must not be empty";
    }
    if (user.password !== user.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      axios
        .post("/users2", user)
        .then((res) => {
          setErrors({});
          setUser({});

          setLoading(false);
          setOpenSuccessSnackbar(true);
        })
        .catch((err) => {
          const errors = err.response.data;
          console.log(err.response);
          setErrors(errors);
          setLoading(false);
        });
    }
  };

  const handleNewUserAdmin = () => {
    const newErrors = {};
    if (!selectedDealer.name) {
      newErrors.dealer = "Must not be empty";
    }
    if (!user.email) {
      newErrors.email = "Must not be empty";
    }
    if (!user.password) {
      newErrors.password = "Must not be empty";
    }
    if (!user.confirmPassword) {
      newErrors.confirmPassword = "Must not be empty";
    }
    if (user.password !== user.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else if (Object.keys(newErrors).length === 0) {
      axios
        .post("/users", { user, name: selectedDealer.name })
        .then((res) => {
          setUser({ email: "", password: "" });
          const dealerIndex = dealers.findIndex(
            (dealer) => dealer.name === selectedDealer.name
          );
          const newDealers = dealers;
          newDealers[dealerIndex].accounts = res.data.accounts;
          setDealers(newDealers);
          const userIndex = users.findIndex(
            (dealer) => Object.keys(dealer)[0] === selectedDealer.name
          );
          const newUsers = users;
          const newEmails = res.data.accounts.map((account) => account.email);
          newUsers[userIndex][selectedDealer.name] = newEmails;
          setUsers(newUsers);
          setOpenSuccessSnackbar(true);
        })
        .catch((e) => {
          setOpenErrorSnackbar(true);
          console.log(e);
        });
    }
  };

  return (
    <>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          Create a User | {currentUser?.name}
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          Create a user for dealer account
        </Typography>
      </Box>

      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <Typography
              component="label"
              className={classes.inputLabel}
              htmlFor="email"
            >
              Email address
            </Typography>
            <TextField
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="email"
              error={errors.email ? true : false}
              variant="outlined"
              fullWidth
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            {errors.email && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.email}
              </Typography>
            )}
          </FormControl>
        </Grid>

        {isAdmin && (
          <Grid item xs={12} className={classes.gridItem}>
            <FormControl className={classes.formControl} fullWidth>
              <Typography
                component="label"
                htmlFor="select-dealer"
                className={classes.inputLabel}
              >
                Select a Dealer
              </Typography>
              <Select
                style={{ marginTop: 0 }}
                variant="filled"
                className={classes.select}
                value={selectedDealer.name || ""}
                disableUnderline
                fullWidth
                id="select-dealer"
                onChange={handleDealerChange}
                inputProps={{
                  "aria-label": "Select dealer",
                }}
              >
                <MenuItem value={""} disabled>
                  <em>Select dealer:</em>
                </MenuItem>
                {dealers.map((dealer, i) => {
                  return (
                    <MenuItem key={i} value={dealer.name}>
                      {dealer.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {errors.dealer && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.dealer}
              </Typography>
            )}
          </Grid>
        )}

        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <FormHelperText
              className={classes.helperText}
              id="password-helper-text"
            >
              Password Advice: Make sure your password is unique and contains a
              combination of uppercase letters, lowercase letters, numbers, and
              symbols.
            </FormHelperText>
            <Typography
              component="label"
              className={classes.inputLabel}
              htmlFor="new-password"
            >
              New Password
            </Typography>
            <TextField
              aria-describedby="password-helper-text"
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="new-password"
              error={errors.password ? true : false}
              variant="outlined"
              type="password"
              fullWidth
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            {errors.password && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.password}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <Typography
              component="label"
              className={classes.inputLabel}
              htmlFor="confirm-password"
            >
              Confirm Password
            </Typography>
            <TextField
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="confirm-password"
              error={errors.confirmPassword ? true : false}
              variant="outlined"
              type="password"
              fullWidth
              onChange={(e) =>
                setUser({ ...user, confirmPassword: e.target.value })
              }
            />
            {errors.confirmPassword && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.confirmPassword}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <Button
            fullWidth
            disabled={loading || openSuccessSnackbar}
            type="submit"
            onClick={isAdmin ? handleNewUserAdmin : handleNewUser}
            className={classes.submitButton}
            variant="contained"
            color="primary"
            startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
          >
            Create User
            {loading && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSuccessSnackbar}
      >
        <Alert
          className={classes.snackbar}
          onClose={handleCloseSuccessSnackbar}
          severity="success"
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Something went wrong, please try again
        </Alert>
      </Snackbar>
    </>
  );
}
