import React, { useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import aprilia from "../../../assets/aprilia.png";
import vespa from "../../../assets/vespa.jpg";
import motoGuzzi from "../../../assets/moto guzzi.png";
import bsa from "../../../assets/bsa.png";
import brandNames from "../../../util/brands";
import getStockTotals from "../../../util/getStockTotals";
import AddStockItems from "./AddStockItems";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "700px",
  },
  error: {
    color: theme.palette.secondary.light,
    marginBottom: 0,
  },
  header: {
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  rows: {
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 5px",
    },
    "&>:last-child": {
      "& hr": {
        backgroundColor: theme.palette.common.mediumDarkGrey,
      },
    },
  },
  accordionContainer: {
    width: "100%",
    padding: "10px 0 0",
    "&:not(:first-child)": {
      paddingTop: "15px",
    },
  },
  accordion: {
    width: "100%",
  },
  accordionTitle: {
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 5px 0 10px",
    },
    "& div, .Mui-expanded": {
      margin: "10px 0",
    },
  },
  brandName: {
    paddingLeft: "8px",
  },
  accordionInner: {
    flexDirection: "column",
    padding: "0 10px 10px",
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 5,
    paddingTop: 13,
    paddingBottom: 12,
  },
  totals: {
    paddingTop: "10px",
    "&>div": {
      paddingTop: "5px",
    },
  },
  snackbar: {
    alignItems: "center",
  },
}));

export default function EditStockReport(props) {
  const {
    selectedReport,
    setOpenEditReport,
    rows,
    setRows,
    stockReportBrands,
    dealers,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [stockReportData, setStockReportData] = useState(selectedReport.data);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [error, setError] = useState(false);
  const totals = getStockTotals(stockReportData);

  const dealer = dealers.find((dealer) => {
    return dealer.name === selectedReport.dealerName;
  });
  const dealerBrands = dealer.brands;
  const userBrands = stockReportBrands?.brands;
  const formattedData = {};

  Object.keys(userBrands).forEach((key) => {
    if (userBrands[key] === true && dealerBrands[key] === true) {
      if (stockReportData[key]) {
        formattedData[key] = stockReportData[key];
      } else {
        formattedData[key] = {
          unitsSold: [],
          dealerStockUnits: [],
          unitsOnConsignment: [],
          dealerDemoUnits: [],
        };
      }
    }
  });

  const handleCloseErrorSnackbar = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenEditReport(false);
  };

  const handleSubmit = () => {
    setError(false);
    let validationError = false;

    Object.keys(formattedData).forEach((brandKey) => {
      Object.keys(formattedData[brandKey]).forEach((categoryKey) => {
        formattedData[brandKey][categoryKey].forEach((item, i) => {
          if (Object.values(item).includes("")) {
            validationError = true;
            return;
          }
        });
      });
    });
    if (validationError) {
      setError(true);
      return;
    }

    const newReport = { ...selectedReport, data: formattedData };
    const formattedMonth = new Date(selectedReport.month);
    axios
      .post("/reports/stock", { ...newReport, month: formattedMonth })
      .then(() => {
        const updatedRows = [
          ...rows.slice(0, rows.indexOf(selectedReport)),
          newReport,
          ...rows.slice(rows.indexOf(selectedReport) + 1),
        ];
        setRows(updatedRows);
        setOpenSuccessSnackbar(true);
      })
      .catch((e) => {
        console.log(e);
        setOpenErrorSnackbar(true);
      });
  };

  return (
    <Grid className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          Edit a Report
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          Edit a stock report for a given month
        </Typography>
      </Box>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography variant="h3">
          {format(new Date(selectedReport.month), "MMMM yyyy")} |{" "}
          {selectedReport.dealerName}
        </Typography>
      </Grid>

      <Grid container className={classes.rows}>
        {Object.keys(formattedData).map((key, index) => {
          return (
            <Grid
              item
              xs={12}
              key={index}
              className={classes.accordionContainer}
              container
            >
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  className={classes.accordionTitle}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <img
                    src={
                      key === "aprilia"
                        ? aprilia
                        : key === "vespa"
                        ? vespa
                        : key === "bsa"
                        ? bsa
                        : motoGuzzi
                    }
                    alt={
                      key === "aprilia"
                        ? "aprilia"
                        : key === "vespa"
                        ? "vespa"
                        : key === "bsa"
                        ? bsa
                        : "moto guzzi"
                    }
                    height="20px"
                  />
                  <Typography variant="h3" className={classes.brandName}>
                    {brandNames[key]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionInner}>
                  <AddStockItems
                    formattedData={formattedData}
                    setStockReportData={setStockReportData}
                    category="unitsSold"
                    brand={key}
                  />
                  <AddStockItems
                    formattedData={formattedData}
                    setStockReportData={setStockReportData}
                    category="dealerStockUnits"
                    brand={key}
                  />
                  <AddStockItems
                    formattedData={formattedData}
                    setStockReportData={setStockReportData}
                    category="unitsOnConsignment"
                    brand={key}
                  />
                  <AddStockItems
                    formattedData={formattedData}
                    setStockReportData={setStockReportData}
                    category="dealerDemoUnits"
                    brand={key}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>

      <Grid item xs={12} className={classes.gridItem} container>
        <Grid item container className={classes.totals} xs={12}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Total Units Sold: </strong>
              {totals.totalUnitsSold}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Total Dealer Stock Units: </strong>
              {totals.totalDealerStockUnits}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Total Units On Consignment: </strong>
              {totals.totalUnitsOnConsignment}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Total Dealer Demo Units: </strong>
              {totals.totalDealerDemoUnits}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.gridItem}>
        <Button
          fullWidth
          disabled={openSuccessSnackbar}
          type="submit"
          onClick={handleSubmit}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
        >
          Submit Report
        </Button>
        {error && (
          <Typography
            variant="body1"
            style={{ color: theme.palette.secondary.light }}
          >
            Please make sure you fill in all fields for each item
          </Typography>
        )}
      </Grid>

      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSuccessSnackbar}
      >
        <Alert
          className={classes.snackbar}
          onClose={handleCloseSuccessSnackbar}
          severity="success"
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Something went wrong, please try again
        </Alert>
      </Snackbar>
    </Grid>
  );
}
