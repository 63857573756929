export default function getStockTotals(stockReportData) {
  let totalUnitsSold = 0;
  let totalDealerStockUnits = 0;
  let totalUnitsOnConsignment = 0;
  let totalDealerDemoUnits = 0;
  Object.keys(stockReportData).forEach((key) => {
    totalUnitsSold = totalUnitsSold + stockReportData[key].unitsSold.length;
    totalDealerStockUnits =
      totalDealerStockUnits + stockReportData[key].dealerStockUnits.length;
    totalUnitsOnConsignment =
      totalUnitsOnConsignment + stockReportData[key].unitsOnConsignment.length;
    totalDealerDemoUnits =
      totalDealerDemoUnits + stockReportData[key].dealerDemoUnits.length;
  });
  return {
    totalUnitsSold,
    totalDealerStockUnits,
    totalUnitsOnConsignment,
    totalDealerDemoUnits,
  };
}
