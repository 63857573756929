import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  addButton: {
    padding: "10px 14px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  partItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.common.mediumLightGrey,
    padding: "13px 4px 9px 14px",
    borderRadius: "5px",
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.1)",
    flexGrow: 1,
  },
  partNumInput: {
    flexDirection: "row",
    alignItems: "center",
  },
  inputLabel: {
    ...theme.typography.h4,
    paddingRight: "5px",
    // naked input lifts text slightly
    paddingBottom: "1px",
  },
  partNum: {
    "& .MuiInputBase-input": {
      padding: 0,
      ...theme.typography.h4,
      fontWeight: 400,
    },
  },
  partQty: {
    "& .MuiInputBase-input": {
      padding: "5px 7px",
      fontSize: "8px",
      fontWeight: "600",
      lineHeight: "12px",
      color: theme.palette.common.white,
      textAlign: "center",
      backgroundColor: theme.palette.common.black,
      width: "35px",
      borderRadius: "5px",
    },
  },
  deleteButton: {
    padding: "9px",
  },
  partsGrid: {
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
    "& > .MuiGrid-item": {
      paddingBottom: 10,
    },
    [theme.breakpoints.up("md")]: {
      "& > .MuiGrid-item:nth-of-type(odd)": {
        paddingRight: 10,
      },
      "& > .MuiGrid-item:nth-of-type(even)": {
        paddingLeft: 10,
      },
    },
  },
}));

export default function EditParts(props) {
  const { partNumbers, quantity, setPartNumbers, setQuantity, errors } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.gridItem}
      justify="space-between"
    >
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Box>
          <Typography variant="h3">Parts</Typography>
          <Typography variant="subtitle2">Add or edit parts</Typography>
        </Box>
        <Button
          className={classes.addButton}
          variant={isMobile ? "text" : "contained"}
          color="primary"
          startIcon={<ExitToAppRoundedIcon />}
          onClick={() => {
            setPartNumbers({
              ...partNumbers,
              [`part${Object.keys(partNumbers).length}`]: "",
            });
            setQuantity({
              ...quantity,
              [`qty${Object.keys(quantity).length}`]: "",
            });
          }}
        >
          Add an item
        </Button>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.partsGrid}
      >
        {Object.keys(partNumbers).map((partNumber, idx) => (
          <Grid key={idx} item xs={12} md={6} container alignItems="center">
            <Box className={classes.partItem}>
              <FormControl
                style={{ flexGrow: 1 }}
                className={classes.partNumInput}
              >
                <Typography
                  component="label"
                  htmlFor="part-number"
                  className={classes.inputLabel}
                >
                  Part Number:
                </Typography>
                <InputBase
                  className={classes.partNum}
                  error={errors.partNumbers ? true : false}
                  id="part-number"
                  onChange={(e) => {
                    setPartNumbers({
                      ...partNumbers,
                      [`part${idx}`]: e.target.value,
                    });
                  }}
                  value={partNumbers[`part${idx}`] || ""}
                  placeholder="Click to edit"
                />
              </FormControl>
              <FormControl className={classes.qtyInput}>
                <InputBase
                  className={classes.partQty}
                  error={errors.partNumbers ? true : false}
                  id="qty"
                  aria-label="part quantity"
                  onChange={(e) =>
                    setQuantity({
                      ...quantity,
                      [`qty${idx}`]: e.target.value,
                    })
                  }
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  value={quantity[`qty${idx}`] || ""}
                />
              </FormControl>
              <IconButton
                className={classes.deleteButton}
                onClick={() => {
                  const partNumberValues = Object.values(partNumbers);
                  const newPartNumberArr = [
                    ...partNumberValues.slice(0, idx),
                    ...partNumberValues.slice(idx + 1),
                  ];
                  const quantityValues = Object.values(quantity);
                  const newQuantityArr = [
                    ...quantityValues.slice(0, idx),
                    ...quantityValues.slice(idx + 1),
                  ];
                  let newPartNumbers = {};
                  let newQuantity = {};
                  for (let i = 0; i < newPartNumberArr.length; i++) {
                    newPartNumbers[`part${i}`] = newPartNumberArr[i];
                    newQuantity[`qty${i}`] = newQuantityArr[i];
                  }
                  setPartNumbers(newPartNumbers);
                  setQuantity(newQuantity);
                }}
              >
                <DeleteOutlineIcon
                  style={{
                    color: theme.palette.common.black,
                    fontSize: "15px",
                  }}
                />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
