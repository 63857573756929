import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const useStyles = makeStyles((theme) => ({
  header: {
    flexWrap: "nowrap",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 0",
    },
  },
  formBlock: {
    margin: "5px 0 10px",
    backgroundColor: theme.palette.common.mediumLightGrey,
    padding: "10px 10px 14px 15px",
    borderRadius: "5px",
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  addButton: {
    padding: "8px 14px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.mediumGrey,
      boxShadow: "0px 1px 2px 0px rgb(0 0 0 / 12%)",
      "&:active, &:hover": {
        backgroundColor: theme.palette.common.mediumGrey,
        boxShadow: "0px 1px 2px 0px rgb(0 0 0 / 12%)",
      },
    },
  },
  item: {
    padding: "5px 0 0",
    [theme.breakpoints.up("md")]: {
      "&:first-child button": {
        marginTop: "23px",
      },
    },
    "&:not(:first-child) label": {
      display: "none",
    },
    "&:last-child hr": {
      display: "none",
    },
  },
  itemInner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1,
    gap: "10px",
    position: "relative",
    "& .MuiFormControl-root": {
      flexGrow: 1,
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "0 0 5px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  textInput: {
    backgroundColor: theme.palette.common.white,
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  deleteButton: {
    [theme.breakpoints.down("sm")]: {},
  },
}));

const categoryNames = {
  unitsSold: "Units Sold This Month",
  dealerStockUnits: "Dealer Stock Units",
  unitsOnConsignment: "Units On Consignment",
  dealerDemoUnits: "Dealer Demo Units",
};

export default function AddStockItems(props) {
  const { formattedData, setStockReportData, category, brand } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hasOdo =
    category === "unitsOnConsignment" || category === "dealerDemoUnits";

  let items = formattedData[brand][category];

  const handleChange = (e, i) => {
    items[i][e.target.id] = e.target.value;
    setStockReportData({
      ...formattedData,
      [brand]: { ...formattedData[brand], [category]: items },
    });
  };

  const handleAddItem = () => {
    const newItem = hasOdo
      ? { model: "", colour: "", vin: "", odo: "" }
      : { model: "", colour: "", vin: "" };
    items.push(newItem);
    setStockReportData({
      ...formattedData,
      [brand]: { ...formattedData[brand], [category]: items },
    });
  };

  const handleDeleteItem = (e, i) => {
    const newItems = [...items.slice(0, i), ...items.slice(i + 1)];
    setStockReportData({
      ...formattedData,
      [brand]: { ...formattedData[brand], [category]: newItems },
    });
  };

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.formBlock}
      justify="space-between"
    >
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className={classes.header}
      >
        <Typography variant="h3">{categoryNames[category]}:</Typography>
        <Button
          className={classes.addButton}
          variant="contained"
          color="primary"
          startIcon={<ExitToAppRoundedIcon />}
          onClick={handleAddItem}
        >
          Add an item
        </Button>
      </Grid>

      <Grid container>
        {items.map((item, i) => (
          <Grid
            key={i}
            item
            xs={12}
            container
            alignItems="center"
            className={classes.item}
          >
            <Box className={classes.itemInner}>
              <FormControl>
                <Typography
                  component="label"
                  htmlFor="model"
                  className={classes.inputLabel}
                >
                  Model:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  inputProps={{ className: classes.textInput }}
                  InputProps={{ className: classes.fieldset }}
                  id="model"
                  aria-label="Model"
                  placeholder="Model"
                  onChange={(e) => {
                    handleChange(e, i);
                  }}
                  value={item.model}
                />
              </FormControl>

              <FormControl>
                <Typography
                  component="label"
                  htmlFor="colour"
                  className={classes.inputLabel}
                >
                  Colour:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  inputProps={{ className: classes.textInput }}
                  InputProps={{ className: classes.fieldset }}
                  id="colour"
                  aria-label="Colour"
                  placeholder="Colour"
                  onChange={(e) => {
                    handleChange(e, i);
                  }}
                  value={item.colour}
                />
              </FormControl>

              <FormControl>
                <Typography
                  component="label"
                  htmlFor="vin"
                  className={classes.inputLabel}
                >
                  VIN:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  inputProps={{ className: classes.textInput }}
                  InputProps={{ className: classes.fieldset }}
                  id="vin"
                  aria-label="VIN"
                  placeholder="VIN"
                  onChange={(e) => {
                    handleChange(e, i);
                  }}
                  value={item.vin}
                />
              </FormControl>

              {hasOdo && (
                <FormControl>
                  <Typography
                    component="label"
                    htmlFor="odo"
                    className={classes.inputLabel}
                  >
                    ODO:
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{ className: classes.textInput }}
                    InputProps={{ className: classes.fieldset }}
                    id="odo"
                    aria-label="ODO"
                    placeholder="ODO"
                    onChange={(e) => handleChange(e, i)}
                    value={item.odo}
                  />
                </FormControl>
              )}

              {isMobile ? (
                <Button
                  variant="text"
                  startIcon={<CloseRoundedIcon style={{ fontSize: "16px" }} />}
                  onClick={(e) => handleDeleteItem(e, i)}
                >
                  Delete
                </Button>
              ) : (
                <IconButton
                  className={classes.deleteButton}
                  onClick={(e) => handleDeleteItem(e, i)}
                >
                  <CloseRoundedIcon
                    style={{
                      color: theme.palette.common.black,
                      fontSize: "15px",
                    }}
                  />
                </IconButton>
              )}
            </Box>
            {isMobile && (
              <Divider
                style={{
                  width: "100%",
                  marginTop: "15px",
                  marginBottom: "5px",
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
