import React, { useRef } from "react";
import pdf from "../../../assets/pdf.svg";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddPhotoAlternateRoundedIcon from "@material-ui/icons/AddPhotoAlternateRounded";

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    position: "absolute",
  },
  imageGridItem: {
    padding: "0px 6px 6px",
    width: "131px",
    height: "125px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 5px 6px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "69px",
      height: "69px",
    },
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.lightGrey}`,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& a": {
      width: "100%",
      height: "100%",
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "5px",
    },
  },
  addImageIcon: {
    padding: "23px 21px 20px 24px",
    backgroundColor: theme.palette.common.lightGrey,
    borderRadius: "50%",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "13px 12px 11px 14px",
    },
  },
  deleteIconContainer: {
    padding: "8px",
    position: "absolute",
    right: 0,
    top: 0,
  },
  deleteIcon: {
    fontSize: "15px",
    padding: "2px",
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderRadius: "50%",
  },
}));

export default function ClaimImageGrid(props) {
  const classes = useStyles();
  const { handleImageFiles, prior, imageURLs, deleteImages } = props;
  const inputRef = useRef(null);

  return (
    <>
      <input
        hidden
        accept="image/*,application/pdf"
        id="fileButton"
        multiple
        type="file"
        ref={inputRef}
        onChange={handleImageFiles}
      />

      <Grid
        container
        justify="center"
        alignItems="center"
        direction="row"
        className={classes.imageGridContainer}
      >
        {prior.images !== imageURLs.length ? (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container>
            {prior.images > 0 &&
              imageURLs.map((image, idx) => {
                return (
                  <Grid
                    item
                    key={idx}
                    xs={4}
                    md={2}
                    className={classes.imageGridItem}
                  >
                    <Grid container className={classes.imageContainer}>
                      <a href={Object.values(image)} download="Download">
                        <img
                          alt="uploaded"
                          src={
                            Object.keys(image)[0].split(".").pop() === "pdf"
                              ? pdf
                              : Object.values(image)
                          }
                          className={classes.image}
                        />
                      </a>
                      <IconButton
                        onClick={() => deleteImages(image)}
                        className={classes.deleteIconContainer}
                      >
                        <DeleteOutlineIcon className={classes.deleteIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            {prior.images < 6 &&
              Array.from(Array(6 - prior.images).keys()).map((item) => {
                return (
                  <Grid
                    item
                    key={item}
                    xs={4}
                    md={2}
                    className={classes.imageGridItem}
                  >
                    <Grid
                      className={classes.imageContainer}
                      component="button"
                      style={{ cursor: "pointer" }}
                      onClick={() => inputRef.current.click()}
                    >
                      <Box className={classes.addImageIcon}>
                        <AddPhotoAlternateRoundedIcon />
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Grid>
    </>
  );
}
