import { createMuiTheme } from "@material-ui/core/styles";

const black = "#111111";
const darkGrey = "#3A3A3A";
const mediumGrey = "#EEEEEE";
const mediumDarkGrey = "#979797";
const mediumLightGrey = "#F8F8F8";
const lightGrey = "#F5F5F5";
const red = "#e53935";
const white = "#fff";
const green = "#4AC24F";

export default createMuiTheme({
  palette: {
    common: {
      black,
      darkGrey,
      mediumDarkGrey,
      mediumGrey,
      mediumLightGrey,
      lightGrey,
      white,
      green,
    },
    primary: {
      main: black,
    },
    secondary: {
      main: red,
    },
    background: {
      paper: white,
    },
  },
  typography: {
    tab: {
      fontFamily: "Poppins",
      textTransform: "none",
      fontWeight: 600,
      fontSize: "10px",
      lineHeight: "15px",
      color: black,
    },
    button: {
      fontFamily: "Poppins",
      fontWeight: 600,
      textTransform: "none",
      fontSize: "10px",
      lineHeight: "15px",
      color: "inherit",
    },
    h2: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "21px",
      color: black,
    },
    h3: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "18px",
      color: black,
    },
    h4: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "10px",
      lineHeight: "15px",
      color: black,
    },
    h5: {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "8px",
      color: black,
      lineHeight: "12px",
    },
    body1: {
      fontFamily: "Poppins",
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 400,
      color: black,
    },
    body2: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "10px",
      color: black,
      lineHeight: "15px",
    },
    subtitle1: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "8px",
      color: black,
      lineHeight: "12px",
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "10px",
      color: "#00000066",
      lineHeight: "15px",
    },
  },
  overrides: {
    MuiTableContainer: {
      root: {
        minHeight: "60px",
      },
    },
    MuiTableCell: {
      root: {
        padding: 0,
      },
    },
  },
});
