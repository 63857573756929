import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "5px",
    paddingLeft: 30,
    paddingRight: 18,
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      marginBottom: 0,
    },
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    ...theme.typography.body1,
  },
  dialog: {
    padding: "40px 40px 20px",
    "& h2": {
      ...theme.typography.h2,
    },
  },
  buttons: {
    padding: "0 40px 30px",
  },
  cancelButton: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  button: {
    padding: "12px 30px 13px",
  },
}));

export default function StyledTableToolbar(props) {
  const classes = useToolbarStyles();
  const { numSelected, setSelected, selected, priors, setPriors } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    axios.delete(`/deletePrior/${selected}`).then(() => {
      const updatedPriors = priors.filter((prior) => {
        return !selected.includes(prior.claimNumber);
      });
      setPriors(updatedPriors);
      setSelected([]);
      setOpen(false);
    });
  };

  return (
    <Collapse in={numSelected > 0}>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" component="div">
            {numSelected} selected
          </Typography>
        ) : null}
        {numSelected > 0 ? (
          <React.Fragment>
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={handleClickOpen}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" className={classes.dialog}>
                {numSelected > 1
                  ? "Are you sure you want to delete all these claims?"
                  : "Are you sure you want to delete this claim?"}
              </DialogTitle>
              <DialogActions className={classes.buttons}>
                <Button className={classes.cancelButton} onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleDelete}
                  color="primary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        ) : null}
      </Toolbar>
    </Collapse>
  );
}

StyledTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
