import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/styles";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import EditRounded from "@material-ui/icons/EditRounded";

import brandNames from "../../../util/brands";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "500px",
  },
  error: {
    color: theme.palette.secondary.light,
    marginBottom: 0,
  },
  header: {
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  rows: {
    "&>:last-child": {
      "& hr": {
        display: "none",
      },
    },
  },
  formContainer: {
    padding: "10px 0 0",
    "& hr": {
      marginTop: "13px",
    },
  },
  formItem: {
    padding: "3px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "3px 5px",
    },
  },
  inputLabel: {
    padding: "0px 0px 5px",
  },
  select: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
  },
  button: {
    padding: "10px 15px",
    marginTop: "5px",
  },
}));

export default function ViewThroughputReport(props) {
  const {
    selectedReport,
    setOpenViewReport,
    setOpenEditReport,
    dealers,
    isAdmin,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const throughputs = selectedReport.data;

  const brands = dealers.find(
    (dealer) => dealer.name === selectedReport.dealerName
  ).brands;
  const newThroughputs = [];
  Object.keys(brands).forEach((key) => {
    if (brands[key] === true) {
      const existingThroughput = throughputs.find((throughput) => {
        return throughput.brand === key;
      });
      if (!existingThroughput) {
        newThroughputs.push({ brand: key, unitIntake: 0, jobsCompleted: 0 });
      } else {
        newThroughputs.push(existingThroughput);
      }
    }
  });

  const unitTotal = newThroughputs.reduce(
    (acc, curr) => acc + curr.unitIntake,
    0
  );
  const jobsTotal = newThroughputs.reduce(
    (acc, curr) => acc + curr.jobsCompleted,
    0
  );

  return (
    <Grid className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          View a Report
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          View a throughtputs report for a given month
        </Typography>
      </Box>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography variant="h3">
          {format(new Date(selectedReport.month), "MMMM yyyy")} |{" "}
          {selectedReport.dealerName}
        </Typography>
      </Grid>

      {isAdmin && (
        <Grid item xs={12} className={classes.gridItem}>
          <Button
            className={classes.button}
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<EditRounded style={{ fontSize: "14px" }} />}
            onClick={() => {
              setOpenEditReport(true);
              setOpenViewReport(false);
            }}
          >
            Edit Report
          </Button>
        </Grid>
      )}

      <Grid container className={classes.rows}>
        {newThroughputs.map((report, index) => {
          return (
            <Grid
              item
              xs={12}
              key={index}
              className={classes.formContainer}
              container
            >
              <Grid item xs={12} className={classes.formItem}>
                <Typography variant="h4" className={classes.brandName}>
                  {brandNames[report.brand]}
                </Typography>
              </Grid>

              <Grid item xs={6} className={classes.formItem}>
                <Typography className={classes.inputLabel} variant="body2">
                  Unit Intake: {report.unitIntake}
                </Typography>
              </Grid>

              <Grid item xs={6} className={classes.formItem}>
                <Typography className={classes.inputLabel} variant="body2">
                  Jobs Completed: {report.jobsCompleted}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.formItem}>
                <Divider />
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{ paddingTop: 10, paddingBottom: 5 }}
      >
        <Divider
          style={{ backgroundColor: theme.palette.common.mediumDarkGrey }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.formContainer}
        style={{ paddingTop: 10, paddingBottom: 10 }}
        container
      >
        <Grid item xs={12} className={classes.formItem}>
          <Typography variant="h4" className={classes.brandName}>
            Totals
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.formItem}>
          <Typography className={classes.inputLabel} variant="body2">
            Total Unit Intake: {unitTotal}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.formItem}>
          <Typography className={classes.inputLabel} variant="body2">
            Total Jobs Completed: {jobsTotal}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
