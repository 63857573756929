import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import InputBase from "@material-ui/core/InputBase";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import searchItems from "../util/searchItems";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import DashboardHeaderMobile from "../components/dashboard/DashboardHeaderMobile";
import RegoTable from "../components/dashboard/RegoTable";
import UploadFiles from "../components/modals/UploadFiles";
import ModalBase from "../components/modals/ModalBase";
import AddRego from "../components/modals/registrations/AddRego";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  subHeaderContainer: {
    backgroundColor: theme.palette.common.white,
    padding: "21px 21px 0",
    minHeight: "48px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  subHeaderText: {
    ...theme.typography.tab,
    width: 143,
    padding: "0 18px 18px",
    textAlign: "center",
  },
  underline: {
    height: "2px",
    width: 143,
    backgroundColor: theme.palette.common.black,
  },
  collapse: {
    width: "100%",
  },
  searchContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    padding: "13px 19px",
    margin: "20px 10px 0",
    [theme.breakpoints.up("md")]: {
      margin: "20px 10px 10px",
    },
  },
  searchBar: {
    border: "none",
    flexGrow: 1,
    ...theme.typography.subtitle2,
  },
}));

const RegoDashboard = (props) => {
  const { isAdmin, currentUser, MobileHeaderWithContext } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [search, setSearch] = useState("");
  const [regos, setRegos] = useState([]);
  const [dealer, setDealer] = useState("");
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const searchBarRef = useRef(null);
  const [openUploadFiles, setOpenUploadFiles] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openAddRego, setOpenAddRego] = useState(false);

  useEffect(() => {
    if (searchBarOpen === true) {
      searchBarRef.current.firstChild.focus();
    }
  }, [searchBarOpen]);

  useEffect(() => {
    let mounted = true;
    axios
      .get("/regos")
      .then((res) => {
        if (mounted) {
          setRegos(res.data.regos);
          setDealer(res.data.dealer);
        }
      })
      .catch((e) => console.log(e));

    return () => (mounted = false);
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const newRows = searchItems(e, regos);
    setRegos(newRows);
  };

  const handleDownload = () => {
    axios
      .get(`/downloadAllRegos/${currentUser.name}`, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        console.log(res);
        let name = res.headers["content-disposition"]
          .split("filename=")[1]
          .replace(/"/g, "")
          .split(".");
        const anchor = document.createElement("a");
        const a = window.URL.createObjectURL(
          new Blob([res.data], {
            type: res.headers["content-type"],
          })
        );
        anchor.href = a;
        anchor.download = `Download.${name[name.length - 1]}`;
        anchor.click();
      })
      .catch((e) => console.log(e.response));
  };

  return (
    <>
      <Grid container direction="column">
        {isMobile ? (
          <>
            <MobileHeaderWithContext>
              <DashboardHeaderMobile
                page="Warranty Registrations"
                search={search}
                handleSearch={handleSearch}
                handleDownload={handleDownload}
                currentUser={currentUser}
                isAdmin={isAdmin}
                searchBarOpen={searchBarOpen}
                setSearchBarOpen={setSearchBarOpen}
                setOpenAdd={setOpenAddRego}
                setOpenUploadFiles={setOpenUploadFiles}
              />
            </MobileHeaderWithContext>
            <Box className={classes.subHeaderContainer}>
              <Typography className={classes.subHeaderText}>
                All Registrations
              </Typography>
              <Box className={classes.underline} />
            </Box>
            <Collapse in={searchBarOpen} className={classes.collapse}>
              <Box className={classes.searchContainer}>
                <InputBase
                  ref={searchBarRef}
                  type="text"
                  className={classes.searchBar}
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search for warranty registrations..."
                />
              </Box>
            </Collapse>
          </>
        ) : (
          <DashboardHeader
            page={"Warranty Registrations"}
            handleDownload={handleDownload}
            search={search}
            handleSearch={handleSearch}
            currentUser={currentUser}
            isAdmin={isAdmin}
            setOpenAdd={setOpenAddRego}
            setOpenUploadFiles={setOpenUploadFiles}
          />
        )}
        <RegoTable
          regos={regos}
          setRegos={setRegos}
          dealer={dealer}
          isAdmin={isAdmin}
          currentUser={currentUser}
        />
        {isAdmin && openUploadFiles && (
          <ModalBase
            openModal={openUploadFiles}
            setOpenModal={setOpenUploadFiles}
          >
            <UploadFiles
              currentUser={currentUser}
              setOpenUploadFiles={setOpenUploadFiles}
              setOpenSuccessSnackbar={setOpenSuccessSnackbar}
              setOpenErrorSnackbar={setOpenErrorSnackbar}
            />
          </ModalBase>
        )}
        {openAddRego && (
          <ModalBase openModal={openAddRego} setOpenModal={setOpenAddRego}>
            <AddRego
              currentUser={currentUser}
              setOpenAddRego={setOpenAddRego}
              regos={regos}
              setRegos={setRegos}
            />
          </ModalBase>
        )}
      </Grid>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar(false)}
      >
        <Alert
          className={classes.snackbar}
          onClose={() => setOpenSuccessSnackbar(false)}
          severity="success"
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnackbar(false)}
      >
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error">
          Something went wrong, please try again
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegoDashboard;
