import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    flexGrow: 1,
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  formControl: {
    minWidth: 110,
  },
  select: {
    marginTop: "0!important",
    "& .MuiSelect-root": {
      paddingBottom: "3px",
      "& p": {
        position: "absolute",
        top: "100%",
        ...theme.typography.subtitle2,
        fontSize: "8px",
        lineHeight: "12px",
      },
    },
  },
  inputLabel: {
    transition: "none",
    transform: "none",
    top: "3px",
  },
  helperText: {
    ...theme.typography.subtitle2,
    marginTop: 0,
    fontSize: "8px",
    lineHeight: "12px",
    position: "absolute",
    top: "100%",
  },
  iconContainer: {
    padding: "15px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  addButton: {
    color: theme.palette.common.black,
  },
}));

export default function AccountsHeaderMobile(props) {
  const {
    page,
    dealers,
    dealerFilter,
    setDealerFilter,
    setOpenAddDealer,
    setOpenAddUser,
  } = props;
  const classes = useStyles();

  const sortedDealers = dealers.sort((d1, d2) => {
    return d1.name.toUpperCase() > d2.name.toUpperCase()
      ? 1
      : d1.name.toUpperCase() < d2.name.toUpperCase()
      ? -1
      : 0;
  });

  const handleChange = (e) => {
    setDealerFilter(e.target.value);
  };

  return (
    <>
      <Box className={classes.selectContainer}>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.inputLabel} value="" disabled>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              Select dealer
            </Typography>
          </InputLabel>
          <Select
            id="select-dealer"
            disableUnderline
            value={dealerFilter}
            onChange={handleChange}
            className={classes.select}
            inputProps={{ "aria-label": "Select dealer" }}
          >
            <MenuItem key={"all"} value={""}>
              <Typography className={classes.menuItemText}>View all</Typography>
            </MenuItem>
            {dealers &&
              sortedDealers.map((dealer) => {
                return page === "Users" ? (
                  <MenuItem key={dealer._id} value={dealer.name}>
                    <Typography className={classes.menuItemText}>
                      {dealer.name}
                    </Typography>
                  </MenuItem>
                ) : (
                  dealer.name !== process.env.REACT_APP_ADMIN_ID && (
                    <MenuItem key={dealer._id} value={dealer.name}>
                      <Typography className={classes.menuItemText}>
                        {dealer.name}
                      </Typography>
                    </MenuItem>
                  )
                );
              })}
          </Select>
          <FormHelperText className={classes.helperText}>
            {!dealerFilter ? "None selected" : ""}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box className={classes.iconButtons}>
        <IconButton
          disableRipple
          className={classes.addButton}
          aria-label="Add new"
          onClick={() =>
            page === "Dealers" ? setOpenAddDealer(true) : setOpenAddUser(true)
          }
        >
          <AddCircleOutlineRoundedIcon style={{ fontSize: "20px" }} />
        </IconButton>
      </Box>
    </>
  );
}
