import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import UsersTable from "../components/accounts/UsersTable";
import AccountsHeader from "../components/accounts/AccountsHeader";
import AccountsHeaderMobile from "../components/accounts/AccountsHeaderMobile";
import MobileHeaderSubMenu from "../components/menus/MobileHeaderSubMenu";
import ModalBase from "../components/modals/ModalBase";
import AddUser from "../components/modals/accounts/AddUser";
import EditUser from "../components/modals/accounts/EditUser";

const Users = (props) => {
  const {
    selectedSubTab,
    setSelectedSubTab,
    MobileHeaderWithContext,
    currentUser,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [dealerFilter, setDealerFilter] = useState("");
  const [dealers, setDealers] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);

  useEffect(() => {
    let mounted = true;

    axios
      .get("/users")
      .then((res) => {
        if (mounted) {
          setDealers(res.data.users);
          setUsers(res.data.arr);
        }
      })
      .catch((e) => console.log(e));

    return () => (mounted = false);
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <MobileHeaderWithContext>
            <AccountsHeaderMobile
              page="Users"
              dealers={dealers}
              dealerFilter={dealerFilter}
              setDealerFilter={setDealerFilter}
              setOpenAddUser={setOpenAddUser}
            />
          </MobileHeaderWithContext>

          <MobileHeaderSubMenu
            category={"Accounts"}
            selectedSubTab={selectedSubTab}
            setSelectedSubTab={setSelectedSubTab}
          />
        </>
      ) : (
        <AccountsHeader
          page="Users"
          dealers={dealers}
          dealerFilter={dealerFilter}
          setDealerFilter={setDealerFilter}
          setOpenAddUser={setOpenAddUser}
        />
      )}
      <UsersTable
        users={users}
        dealerFilter={dealerFilter}
        setOpenEditUser={setOpenEditUser}
        setSelectedUser={setSelectedUser}
      />
      <ModalBase openModal={openAddUser} setOpenModal={setOpenAddUser}>
        <AddUser
          setOpenAddUser={setOpenAddUser}
          currentUser={currentUser}
          users={users}
          setUsers={setUsers}
          dealers={dealers}
          setDealers={setDealers}
        />
      </ModalBase>
      <ModalBase openModal={openEditUser} setOpenModal={setOpenEditUser}>
        <EditUser
          setOpenEditUser={setOpenEditUser}
          currentUser={currentUser}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          dealers={dealers}
          setDealers={setDealers}
          users={users}
          setUsers={setUsers}
        />
      </ModalBase>
    </>
  );
};

export default Users;
