import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Redirect
            to={{
              pathname: "/dashboard/1",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} {...rest} />
        )
      }
    />
  );
};

export default AuthRoute;
