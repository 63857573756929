import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import EditRounded from "@material-ui/icons/EditRounded";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

const useStyles = makeStyles((theme) => ({
  cell: {
    borderRadius: "5px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "15px",
    padding: "15px 15px 10px",
  },
  vin: {
    marginBottom: 9,
  },
  status: {
    backgroundColor: theme.palette.common.mediumGrey,
    minWidth: "65px",
    width: "fit-content",
    height: "fit-content",
    padding: "5px 6px",
    marginBottom: 9,
    borderRadius: "5px",
    alignSelf: "top",
    textAlign: "center",
    justifySelf: "end",
  },
  row: {
    paddingBottom: 5,
  },
  completeJobButton: {
    width: "fit-content",
    height: "fit-content",
    justifySelf: "end",
    gridRowEnd: "span 3",
  },
  edit: {
    gridRowEnd: "span 2",
    justifySelf: "end",
    color: theme.palette.common.black,
  },
  smallerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
  },
}));

export default function MobileTableCell(props) {
  const {
    row,
    setSelectedJob,
    handleOpenJobCompletionForm,
    setOpenEditClaim,
    setOpenViewClaim,
  } = props;
  const classes = useStyles();

  return (
    <TableCell className={classes.cell} colSpan={11}>
      <Box className={classes.grid}>
        <Typography className={classes.vin} variant="h2">
          VIN: <span style={{ fontWeight: 400 }}>{row.vin}</span>
        </Typography>
        {row.status === "Approved" ? (
          <Button
            className={classes.completeJobButton}
            disableElevation
            color="primary"
            variant="contained"
            onClick={() => {
              setSelectedJob(row);
              handleOpenJobCompletionForm();
            }}
          >
            Complete
          </Button>
        ) : (
          <Box className={classes.status}>
            <Typography variant="h5">{row.status}</Typography>
          </Box>
        )}
        <Typography className={classes.row}>
          <strong>Claim: </strong>
          {row.claimNumber}
        </Typography>
        {row.status === "Completed" && (
          <IconButton
            className={classes.edit}
            aria-label={"View"}
            onClick={() => {
              setSelectedJob(row);
              setOpenViewClaim(true);
            }}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        )}
        {(row.status === "Pending" || row.status === "Declined") && (
          <IconButton
            className={classes.edit}
            aria-label={"Edit"}
            onClick={() => {
              setSelectedJob(row);
              setOpenEditClaim(true);
            }}
          >
            <EditRounded />
          </IconButton>
        )}
        <Typography className={classes.row}>
          <strong>Date Created: </strong>
          {row.createdAt}
        </Typography>
        <Typography className={classes.row}>
          <strong>Comments: </strong>
          {row.comments}
        </Typography>
      </Box>
      <Divider light />
      <Box className={classes.smallerRow}>
        <Typography variant="subtitle1">
          <strong>Model: </strong>
          {row.model}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Mileage: </strong>
          {Number(row.mileage).toLocaleString()} KM
        </Typography>
        <Typography variant="subtitle1">
          <strong>Last modified: </strong>
          {row.updatedAt}
        </Typography>
      </Box>
    </TableCell>
  );
}
