import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  welcomeTitle: {
    marginTop: "2rem",
    fontWeight: 600,
  },
  loginTitle: {
    marginTop: "3rem",
    marginBottom: "1rem",
  },
  button: {
    marginTop: "2rem",
  },
  circularProgress: {
    position: "absolute",
  },
  textfield: {
    minWidth: 300,
  },
  error: {
    color: "red",
    marginTop: "1rem",
    marginBottom: 0,
  },
}));

const Login = (props) => {
  const classes = useStyles();

  const [ui, setUi] = useState({
    loading: false,
    errors: "",
  });

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setUi({ ...ui, loading: true });
    axios
      .post("/user/login", form)
      .then((res) => {
        console.log("Logged in");
        let idToken = res.data.token;
        localStorage.setItem("Token", idToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        setUi({ errors: "", loading: false });
        props.history.push("/dashboard/1");
      })
      .catch((err) => {
        setUi({
          errors: "Wrong credentials",
          loading: false,
        });
      });
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Typography variant="h2" className={classes.welcomeTitle}>
          Welcome!
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" className={classes.loginTitle}>
          Login
        </Typography>
      </Grid>
      <form noValidate onSubmit={handleSubmit}>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <TextField
              id="email"
              name="email"
              type="email"
              label="Email"
              onChange={handleChange}
              className={classes.textfield}
              style={{ marginBottom: "1rem" }}
              error={ui.errors ? true : false}
            />
          </Grid>
          <Grid item>
            <TextField
              id="password"
              name="password"
              type="password"
              label="Password"
              onChange={handleChange}
              className={classes.textfield}
              error={ui.errors ? true : false}
            />
          </Grid>
          <Grid item>
            {ui.errors && (
              <Typography variant="subtitle2" className={classes.error}>
                {ui.errors}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button
              disabled={ui.loading}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Typography variant="button">Login</Typography>
              {ui.loading && (
                <CircularProgress
                  size={30}
                  className={classes.circularProgress}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default Login;
