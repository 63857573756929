import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  IconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function IconContainer(props) {
  const classes = useStyles();
  return (
    <Box className={classes.IconContainer} {...props}>
      {props.children}
    </Box>
  );
}
