export default function searchItems(e, items) {
  const rowData = items.map((prior) =>
    Object.values(prior).filter(
      (value) =>
        value !== true &&
        value !== false &&
        !Array.isArray(value) &&
        value !== undefined &&
        value != null
    )
  );
  const matches = rowData.map((row) =>
    row.map((option) =>
      option
        .toString()
        .toLowerCase()
        .includes(e.target.value.toString().toLowerCase())
    )
  );

  const newRows = [...items];

  matches.map((row, idx) =>
    row.includes(true)
      ? (newRows[idx].search = true)
      : (newRows[idx].search = false)
  );

  return newRows;
}
