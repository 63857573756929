import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseRounded from "@material-ui/icons/CloseRounded";
import ExitToAppRounded from "@material-ui/icons/ExitToAppRounded";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";

import ModalBase from "../modals/ModalBase";
import IconContainer from "../IconContainer";
import NavTabs from "./NavTabs";
import NavTab from "./NavTab";
import EditProfile from "../modals/EditProfile";
import AddUser from "../modals/accounts/AddUser";
import Technical from "../../pages/Technical";

const useStyles = makeStyles((theme) => ({
  drawer: {
    minHeight: "100vh",
    width: "269px",
    [theme.breakpoints.down("md")]: {
      width: "288px",
    },
  },
  drawerPaper: {
    position: "static",
    border: "none",
  },
  drawerInner: {
    padding: "37px 30px 30px",
    minHeight: "80vh",
    [theme.breakpoints.up("lg")]: {
      minHeight: "90vh",
      padding: "47px 21px 40px 20px",
    },
  },
  closeButton: {
    color: theme.palette.common.black,
    marginBottom: 28,
    paddingLeft: 0,
    "& span": { fontSize: "12px", lineHeight: "18px" },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  closeIcon: {
    fontSize: "14px",
    marginRight: 24,
  },
  heading: {
    paddingLeft: 20,
    marginBottom: 41,
  },
  navContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
  },
  editProfileButton: {
    justifyContent: "space-between",
    marginBottom: 14,
    paddingRight: 18,
    paddingLeft: 0,
    textTransform: "none",
    width: "100%",
  },
}));

const mainPages = ["Warranty Claims", "Warranty Registrations", "Accounts"];

const Sidebar = (props) => {
  const {
    id,
    currentUser,
    isAdmin,
    openDrawer,
    setOpenDrawer,
    selectedTab,
    setSelectedTab,
    selectedSubTab,
    setSelectedSubTab,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openTechDocs, setOpenTechDocs] = useState(false);

  const authenticated = localStorage.Token ? true : false;

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = () => {
    axios
      .post("/user/logout", {
        refreshToken: localStorage.refreshToken,
      })
      .then(() => {
        localStorage.removeItem("Token");
        localStorage.removeItem("refreshToken");
      })
      .then(() => delete axios.defaults.headers.common["Authorization"])
      .then(() => window.location.replace("/"))
      .catch((e) => console.log(e));
  };

  const headerTitle = isAdmin ? "Admin" : "Dealer";
  let headerSubtitle;
  if (mainPages.includes(selectedTab)) {
    headerSubtitle = "Here are all of the available claims";
  }

  return (
    <>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
        open={openDrawer}
        onClose={handleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
      >
        <Box className={classes.drawerInner}>
          {isMobile && (
            <IconButton
              disableRipple
              className={classes.closeButton}
              onClick={handleDrawer}
              aria-label="Close Menu"
            >
              <CloseRounded className={classes.closeIcon} />
              <Typography variant="button">Close Menu</Typography>
            </IconButton>
          )}

          {!isMobile && (
            <Box className={classes.heading}>
              <Typography variant="h3">
                {headerTitle} | {currentUser?.name}
              </Typography>
              <Typography variant="subtitle2">{headerSubtitle}</Typography>
            </Box>
          )}

          <Box
            component="nav"
            className={classes.navContainer}
            onClick={handleDrawer}
          >
            <NavTabs
              authenticated={authenticated}
              id={id}
              currentUser={currentUser}
              isAdmin={isAdmin}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              selectedSubTab={selectedSubTab}
              setSelectedSubTab={setSelectedSubTab}
              setOpenAddUser={setOpenAddUser}
              setOpenTechDocs={setOpenTechDocs}
            />

            <Box width="100%">
              <Button
                className={classes.editProfileButton}
                onClick={() => setOpenEditProfile(true)}
              >
                <Box>
                  <Typography align="left" variant="h3">
                    {currentUser?.name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {currentUser?.email}
                  </Typography>
                </Box>
                <IconContainer>
                  <SettingsRoundedIcon style={{ fontSize: "14px" }} />
                </IconContainer>
              </Button>

              <Box width={115}>
                <NavTab
                  icon={ExitToAppRounded}
                  label="Log out"
                  handleClick={handleLogout}
                  to={"/"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
      {openAddUser &&
        currentUser.name &&
        currentUser.index === 0 &&
        currentUser.name !== process.env.REACT_APP_ADMIN_ID && (
          <ModalBase openModal={openAddUser} setOpenModal={setOpenAddUser}>
            <AddUser
              currentUser={currentUser}
              setOpenAddUser={setOpenAddUser}
            />
          </ModalBase>
        )}

      {openEditProfile && currentUser.name && (
        <ModalBase
          openModal={openEditProfile}
          setOpenModal={setOpenEditProfile}
        >
          <EditProfile currentUser={currentUser} />
        </ModalBase>
      )}

      {openTechDocs && currentUser.name && (
        <ModalBase openModal={openTechDocs} setOpenModal={setOpenTechDocs}>
          <Technical currentUser={currentUser} />
        </ModalBase>
      )}
    </>
  );
};

export default Sidebar;
