export default function getSelectedTab(id, value) {
  if (id === process.env.REACT_APP_ADMIN_ID) {
    if (
      window.location.pathname === "/dashboard/1" &&
      value !== "Warranty Claims"
    ) {
      return "Warranty Claims";
    } else if (
      window.location.pathname.includes("/dashboard/view") &&
      value !== "Warranty Claims"
    ) {
      return "Warranty Claims";
    } else if (
      window.location.pathname === "/dashboard/2" &&
      value !== "Warranty Registrations"
    ) {
      return "Warranty Registrations";
    } else if (
      window.location.pathname.includes("/rego") &&
      value !== "Warranty Registrations"
    ) {
      return "Warranty Registrations";
    } else if (
      window.location.pathname === "/accounts/dealer" &&
      value !== "Accounts"
    ) {
      return "Accounts";
    } else if (
      window.location.pathname === "/accounts/user" &&
      value !== "Accounts"
    ) {
      return "Accounts";
    } else if (
      window.location.pathname.includes("/reports") &&
      value !== "Reports"
    ) {
      return "Reports";
    } else return value;
  } else {
    if (window.location.pathname === `/yourbrands/${id}` && value !== 0) {
      return;
    } else if (
      window.location.pathname === "/dashboard/1" &&
      value !== "Warranty Claims"
    ) {
      return "Warranty Claims";
    } else if (
      window.location.pathname.includes("/dashboard") &&
      window.location.pathname.split("dashboard/")[1].length > 1 &&
      value !== "Warranty Claims"
    ) {
      return "Warranty Claims";
    } else if (
      window.location.pathname.includes("/dashboard/view") &&
      value !== "Warranty Claims"
    ) {
      return "Warranty Claims";
    } else if (
      window.location.pathname === "/prior" &&
      value !== "Warranty Claims"
    ) {
      return "Warranty Claims";
    } else if (
      window.location.pathname === "/dashboard/2" &&
      value !== "Warranty Registrations"
    ) {
      return "Warranty Registrations";
    } else if (
      window.location.pathname.includes("/rego") &&
      value !== "Warranty Registrations"
    ) {
      return "Warranty Registrations";
    } else if (
      window.location.pathname.includes("/reports") &&
      value !== "Reports"
    ) {
      return "Reports";
      // } else if (window.location.pathname.includes("vin-lookup") && value !== 3) {
      //   return 3;
      // } else if (window.location.pathname.includes("technical") && value !== 4) {
      //   return 4;
      // } else if (
      //   window.location.pathname.includes("userProfile/") &&
      //   value !== 5
      // ) {
      //   return 5;
    } else return value;
  }
}
