import React from "react";
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "30px",
    paddingBottom: "20px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      paddingBottom: "10px",
    },
  },
  closeButton: {
    position: "absolute",
    top: 20,
    right: 28,
  },
}));

export default function ModalBase(props) {
  const { children, openModal, setOpenModal } = props;
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="lg"
      className={classes.modal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <Paper className={classes.paper}>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={() => setOpenModal(false)}
        >
          <CloseRoundedIcon />
        </IconButton>
        {children}
      </Paper>
    </Dialog>
  );
}
