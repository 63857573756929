import React from "react";
import ListItem from "@material-ui/core/ListItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import IconContainer from "../IconContainer";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    margin: "9px auto 0",
    padding: 0,
    width: "100%",
  },
  tabButton: {
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "9px 14px 8px",
    width: "100%",
    "&[isselected=true]": {
      backgroundColor: theme.palette.common.lightGrey,
    },
  },
  tabText: {
    ...theme.typography.tab,
    fontWeight: 400,
  },
  icon: {
    fontSize: "10px",
    marginRight: 11,
  },
}));

export default function SubMenuTab(props) {
  const { label, to, handleClick, selected, iconColor } = props;
  const classes = useStyles();
  const isSelected = selected === label;

  return (
    <ListItem className={classes.tabContainer} disableGutters>
      <Button
        component={to ? Link : Button}
        to={to || null}
        className={classes.tabButton}
        isselected={String(isSelected)}
        onClick={(e) => handleClick(e, label)}>
        <IconContainer color={iconColor}>
          <SvgIcon component={FiberManualRecordIcon} className={classes.icon} />
        </IconContainer>
        <Typography className={classes.tabText} isselected={String(isSelected)}>
          {label}
        </Typography>
      </Button>
    </ListItem>
  );
}
