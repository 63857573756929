import React from "react";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

const useStyles = makeStyles((theme) => ({
  hamburgerButton: {
    color: theme.palette.common.black,
  },
  mobileHeader: {
    width: "100%",
    padding: "25px 15px 15px",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function MobileHeader(props) {
  const { children, openDrawer, setOpenDrawer } = props;
  const classes = useStyles();
  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Box className={classes.mobileHeader}>
      <Box className={classes.hamburgerButton}>
        <IconButton
          disableRipple
          className={classes.iconContainer}
          onClick={handleDrawer}
          aria-label="Open Menu"
        >
          <MenuRoundedIcon className={classes.hamburgerButton} />
        </IconButton>
      </Box>
      {children}
    </Box>
  );
}
