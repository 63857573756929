import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    backgroundColor: theme.palette.common.mediumGrey,
    "& > th": {
      fontWeight: 600,
      lineHeight: 1.3,
      padding: "12px 6px",
    },
    "& > th:first-of-type": {
      borderRadius: "5px 0 0 5px",
      paddingLeft: "12px",
      "&.no-checkbox": {
        paddingLeft: "50px",
      },
    },
    "& > th:last-of-type": {
      paddingRight: "12px",
      borderRadius: "0 5px 5px 0",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function StyledTableHead(props) {
  const {
    page,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        {page !== "Warranty Registrations" && (
          <TableCell align="left" padding="checkbox">
            <Checkbox
              size="small"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all priors" }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            className={page === "Warranty Registrations" ? "no-checkbox" : ""}
            key={headCell.id}
            align={
              headCell.label === "Edit" || headCell.label === "View"
                ? "center"
                : "left"
            }
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.sortButton}
              hideSortIcon={
                headCell.label === "Edit" || headCell.label === "View"
              }
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.label !== "Edit"
                  ? createSortHandler(headCell.id)
                  : null
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
