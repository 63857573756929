import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import VerifiedUserOutlined from "@material-ui/icons/VerifiedUserOutlined";
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined";
import PeopleOutlineRounded from "@material-ui/icons/PeopleOutlineRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import PictureAsPdfRoundedIcon from "@material-ui/icons/PictureAsPdfRounded";

import getSelectedTab from "./getSelectedTab";
import NavTab from "./NavTab";
import SubMenu from "./SubMenu";
import IconContainer from "../IconContainer";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
  },
  addUserButton: {
    marginBottom: 14,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 18px 8px 13px",
    height: 40,
    width: "100%",
    backgroundColor: theme.palette.common.lightGrey,
    color: theme.palette.common.black,
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.05)",
    "&:hover": {
      backgroundColor: theme.palette.common.mediumGrey,
    },
  },
  addUserText: {
    ...theme.typography.tab,
    paddingLeft: 17,
  },
}));

export default function NavTabs(props) {
  const {
    authenticated,
    id,
    currentUser,
    isAdmin,
    selectedTab,
    setSelectedTab,
    selectedSubTab,
    setSelectedSubTab,
    setOpenAddUser,
    setOpenTechDocs,
  } = props;
  const classes = useStyles();
  const [hasStockReportBrands, setHasStockReportBrands] = useState(false);

  useEffect(() => {
    if (currentUser.brands) {
      const stockReportBrands = {
        aprilia: currentUser.brands.aprilia,
        vespa: currentUser.brands.vespa,
        motoGuzzi: currentUser.brands.motoGuzzi,
        bsa: currentUser.brands.bsa,
      };

      if (Object.values(stockReportBrands).includes(true) || isAdmin) {
        setHasStockReportBrands(true);
      } else setHasStockReportBrands(false);
    }
  }, [currentUser, isAdmin]);

  const handleClick = (e, tab) => {
    if (tab === "Warranty Claims") {
      setSelectedSubTab("View all");
    } else if (tab === "Accounts") {
      setSelectedSubTab("Dealers");
    } else if (tab === "Reports") {
      setSelectedSubTab("Throughputs Reports");
    }
    setSelectedTab(tab);
  };

  useEffect(() => {
    const newSelected = getSelectedTab(id, selectedTab);
    if (newSelected !== selectedTab) {
      if (newSelected === "Warranty Claims") {
        setSelectedSubTab("View all");
      } else if (newSelected === "Accounts") {
        if (window.location.pathname.includes("dealer"))
          setSelectedSubTab("Dealers");
        else setSelectedSubTab("Users");
      } else if (newSelected === "Reports") {
        if (window.location.pathname.includes("throughputs"))
          setSelectedSubTab("Throughputs Reports");
        else setSelectedSubTab("Stock Reports");
      }
      setSelectedTab(newSelected);
    }
    // eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <>
      {authenticated && (
        <List disablePadding className={classes.list}>
          <NavTab
            icon={VerifiedUserOutlined}
            label="Warranty Claims"
            to="/dashboard/1"
            handleClick={handleClick}
            selectedTab={selectedTab}
          >
            <SubMenu
              category="Warranty Claims"
              selectedTab={selectedTab}
              selectedSubTab={selectedSubTab}
              setSelectedSubTab={setSelectedSubTab}
            />
          </NavTab>

          <NavTab
            icon={DescriptionOutlined}
            label={"Warranty Registrations"}
            to="/dashboard/2"
            handleClick={handleClick}
            selectedTab={selectedTab}
          />

          {isAdmin && (
            <NavTab
              icon={PeopleOutlineRounded}
              label={"Accounts"}
              to="/accounts/dealer"
              handleClick={handleClick}
              selectedTab={selectedTab}
            >
              <SubMenu
                category="Accounts"
                selectedTab={selectedTab}
                selectedSubTab={selectedSubTab}
                setSelectedSubTab={setSelectedSubTab}
              />
            </NavTab>
          )}

          <NavTab
            icon={AssignmentRoundedIcon}
            label={"Reports"}
            to="/reports/throughputs"
            handleClick={handleClick}
            selectedTab={selectedTab}
          >
            <SubMenu
              category="Reports"
              selectedTab={selectedTab}
              selectedSubTab={selectedSubTab}
              setSelectedSubTab={setSelectedSubTab}
              hasStockReportBrands={hasStockReportBrands}
            />
          </NavTab>

          {!isAdmin && (
            <Button
              className={classes.addUserButton}
              onClick={() => setOpenTechDocs(true)}
            >
              <IconContainer>
                <PictureAsPdfRoundedIcon style={{ fontSize: "14px" }} />
              </IconContainer>
              <Typography className={classes.addUserText}>
                Service/Technical
              </Typography>
            </Button>
          )}

          {currentUser.index === 0 &&
            currentUser.name !== process.env.REACT_APP_ADMIN_ID && (
              <Button
                className={classes.addUserButton}
                onClick={() => setOpenAddUser(true)}
              >
                <IconContainer>
                  <AddCircleOutlineRoundedIcon style={{ fontSize: "14px" }} />
                </IconContainer>
                <Typography className={classes.addUserText}>
                  Add User
                </Typography>
              </Button>
            )}
        </List>
      )}
    </>
  );
}
