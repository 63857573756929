import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import InputBase from "@material-ui/core/InputBase";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import searchItems from "../util/searchItems";
import ClaimsTable from "../components/dashboard/ClaimsTable";
import ClaimsTableAdmin from "../components/dashboard/ClaimsTableAdmin";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import DashboardHeaderMobile from "../components/dashboard/DashboardHeaderMobile";
import MobileHeaderSubMenu from "../components/menus/MobileHeaderSubMenu";
import ModalBase from "../components/modals/ModalBase";
import UploadFiles from "../components/modals/UploadFiles";
import AddClaim from "../components/modals/claims/AddClaim";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  collapse: {
    width: "100%",
  },
  searchContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    padding: "13px 19px",
    margin: "20px 10px 0",
    [theme.breakpoints.up("md")]: {
      margin: "20px 10px 10px",
    },
  },
  searchBar: {
    border: "none",
    flexGrow: 1,
    ...theme.typography.subtitle2,
  },
}));

const ClaimsDashboard = (props) => {
  const {
    isAdmin,
    currentUser,
    selectedSubTab,
    setSelectedSubTab,
    MobileHeaderWithContext,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [search, setSearch] = useState("");
  const [priors, setPriors] = useState([]);
  const [openUploadFiles, setOpenUploadFiles] = useState(false);
  const [openAddClaim, setOpenAddClaim] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const searchBarRef = useRef(null);

  useEffect(() => {
    if (searchBarOpen === true) {
      searchBarRef.current.firstChild.focus();
    }
  }, [searchBarOpen]);

  useEffect(() => {
    let mounted = true;
    const url = isAdmin ? "/admin/priors" : "/priors";

    axios
      .get(url)
      .then((res) => {
        let priors = res.data.map((prior) => {
          return {
            ...prior,
            createdAt: format(new Date(prior.createdAt), "dd/MM/yy HH:mm"),
            updatedAt: format(new Date(prior.updatedAt), "dd/MM/yy HH:mm"),
            id: prior._id,
            search: true,
          };
        });
        if (mounted) {
          setPriors(priors);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => (mounted = false);
  }, [isAdmin]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const newRows = searchItems(e, priors);
    setPriors(newRows);
  };

  // const handleDownload = () => {
  //   axios
  //     .get(`/downloadAllClaims/${currentUser.name}`, { responseType: "arraybuffer" })
  //     .then((res) => {
  //       let name = res.headers["content-disposition"]
  //         .split("filename=")[1]
  //         .replace(/"/g, "")
  //         .split(".");
  //       const anchor = document.createElement("a");
  //       const a = window.URL.createObjectURL(
  //         new Blob([res.data], {
  //           type: res.headers["content-type"],
  //         })
  //       );
  //       anchor.href = a;
  //       anchor.download = `Download.${name[name.length - 1]}`;
  //       anchor.click();
  //     })
  //     .catch((e) => console.log(e.response));
  // };

  return (
    <>
      <Grid container direction="column">
        {isMobile ? (
          <>
            <MobileHeaderWithContext>
              <DashboardHeaderMobile
                page="Warranty Claims"
                search={search}
                handleSearch={handleSearch}
                currentUser={currentUser}
                isAdmin={isAdmin}
                searchBarOpen={searchBarOpen}
                setSearchBarOpen={setSearchBarOpen}
                setOpenUploadFiles={setOpenUploadFiles}
                setOpenAdd={setOpenAddClaim}
              />
            </MobileHeaderWithContext>
            <MobileHeaderSubMenu
              category={"Warranty Claims"}
              selectedSubTab={selectedSubTab}
              setSelectedSubTab={setSelectedSubTab}
            />
            <Collapse in={searchBarOpen} className={classes.collapse}>
              <Box className={classes.searchContainer}>
                <InputBase
                  ref={searchBarRef}
                  type="text"
                  className={classes.searchBar}
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search for warranty claims..."
                />
              </Box>
            </Collapse>
          </>
        ) : (
          <>
            <DashboardHeader
              page={"Warranty Claims"}
              search={search}
              handleSearch={handleSearch}
              currentUser={currentUser}
              isAdmin={isAdmin}
              setOpenUploadFiles={setOpenUploadFiles}
              setOpenAdd={setOpenAddClaim}
            />
          </>
        )}
        {isAdmin ? (
          <>
            <ClaimsTableAdmin
              priors={priors}
              setPriors={setPriors}
              selectedSubTab={selectedSubTab}
              currentUser={currentUser}
            />
            {openUploadFiles && (
              <ModalBase
                openModal={openUploadFiles}
                setOpenModal={setOpenUploadFiles}
              >
                <UploadFiles
                  currentUser={currentUser}
                  setOpenUploadFiles={setOpenUploadFiles}
                  setOpenSuccessSnackbar={setOpenSuccessSnackbar}
                  setOpenErrorSnackbar={setOpenErrorSnackbar}
                />
              </ModalBase>
            )}
          </>
        ) : (
          <>
            <ClaimsTable
              priors={priors}
              setPriors={setPriors}
              selectedSubTab={selectedSubTab}
              currentUser={currentUser}
            />
            {openAddClaim && (
              <ModalBase
                openModal={openAddClaim}
                setOpenModal={setOpenAddClaim}
              >
                <AddClaim
                  currentUser={currentUser}
                  setOpenAddClaim={setOpenAddClaim}
                  priors={priors}
                  setPriors={setPriors}
                />
              </ModalBase>
            )}
          </>
        )}
      </Grid>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnackbar(false)}
      >
        <Alert
          className={classes.snackbar}
          onClose={() => setOpenSuccessSnackbar(false)}
          severity="success"
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnackbar(false)}
      >
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error">
          Something went wrong, please try again
        </Alert>
      </Snackbar>
    </>
  );
};

export default ClaimsDashboard;
