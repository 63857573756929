import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import GetAppIcon from "@material-ui/icons/GetApp";

import IconContainer from "../IconContainer";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  searchContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    padding: "13px 19px",
    width: 454,
  },
  searchBar: {
    border: "none",
    flexGrow: 1,
    ...theme.typography.subtitle2,
  },
  button: {
    padding: "13px 40px",
    marginLeft: "10px",
  },
}));

export default function DashboardHeader(props) {
  const {
    page,
    search,
    handleSearch,
    isAdmin,
    setOpenUploadFiles,
    setOpenAdd,
    handleDownload,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {!isMobile && (
        <Box className={classes.header}>
          <Box className={classes.searchContainer}>
            <InputBase
              type="text"
              className={classes.searchBar}
              value={search}
              onChange={handleSearch}
              placeholder={
                page === "Warranty Claims"
                  ? "Search for warranty claims"
                  : "Search for warranty registrations"
              }
            />
            <IconContainer>
              <SvgIcon component={SearchIcon} viewBox="-5 -4 22 22" />
            </IconContainer>
          </Box>
          <Box>
            {isAdmin ? (
              <>
                {page === "Warranty Registrations" && (
                  <Button
                    style={{ padding: "13px 30px", marginLeft: "8px" }}
                    startIcon={<GetAppIcon style={{ fontSize: "12px" }} />}
                    onClick={handleDownload}
                    variant="contained"
                  >
                    Export all to XLS
                  </Button>
                )}
                <Button
                  onClick={() => setOpenUploadFiles(true)}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                >
                  Upload Files
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setOpenAdd(true)}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                Create New Ticket
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
