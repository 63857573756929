import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      padding: "30px",
      [theme.breakpoints.down("sm")]: {
        padding: "15px",
      },
    },
  },
  dialogTitle: {
    padding: "0 0 20px",
  },
  dialogContent: {
    padding: 0,
    maxWidth: "400px",
  },
  dialogText: {
    margin: 0,
    padding: "0 0 30px",
  },
  dateCompleted: {
    gap: "10px",
    padding: "0 0 30px",
  },
  datePicker: {
    "& .MuiInputBase-root": {
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "13px 11px 12px",
      fontSize: "10px",
      lineHeight: "15px",
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  uploadFiles: {
    padding: "0 0 30px",
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
  },
  comments: {
    margin: 0,
    padding: "0 0 30px",
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  dialogActions: {
    padding: 0,
    gap: "10px",
    justifyContent: "space-between",
  },
  button: {
    padding: "12px 30px 13px",
  },
  cancelButton: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function JobCompletionForm(props) {
  const { selectedPrior, handleOpenJobCompletionForm, priors, setPriors } =
    props;
  const classes = useStyles();
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const [claim, setClaim] = useState({
    date: new Date(),
  });
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);

  const handleCloseFileSizeError = () => {
    setFileSizeError(false);
  };

  const handleCloseFileFormatError = () => {
    setFileFormatError(false);
  };

  const handleSubmit = () => {
    axios
      .patch(`/prior/${selectedPrior.claimNumber}`, {
        dateOfRepair: claim.date,
        claimDetails: claim.claimDetails,
        claimComments: claim.claimComments,
        recallPhoto: claim.recallPhoto,
        status: "Completed",
      })
      .then(() => {
        const updatedPrior = {
          ...selectedPrior,
          ...claim,
          status: "Completed",
        };
        const updatedPriors = [
          ...priors.slice(0, priors.indexOf(selectedPrior)),
          updatedPrior,
          ...priors.slice(priors.indexOf(selectedPrior) + 1),
        ];
        if (file instanceof FormData) {
          file.append("prior", selectedPrior.claimNumber);
          axios
            .post("/uploadPhoto", file)
            .then(() => {
              setPriors(updatedPriors);
              handleOpenJobCompletionForm();
            })
            .catch((e) => console.log(e));
        } else {
          setPriors(updatedPriors);
          handleOpenJobCompletionForm();
        }
      })
      .catch((e) => console.log(e));
  };

  //Handling Files
  const handleUploadFile = (e) => {
    if (e.target.files[0].size > 5100000) {
      return setFileSizeError(true);
    }
    if (
      !e.target.files[0].type.includes("image/jpg") &&
      !e.target.files[0].type.includes("application/pdf") &&
      !e.target.files[0].type.includes("image/jpeg") &&
      !e.target.files[0].type.includes("image/gif") &&
      !e.target.files[0].type.includes("image/svg") &&
      !e.target.files[0].type.includes("image/png")
    )
      return setFileFormatError(true);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    setClaim({ ...claim, recallPhoto: true });
    setFileName(e.target.files[0].name);
    setFile(formData);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid style={{ padding: "10px" }}>
          <Typography
            variant="h2"
            id="modal-title"
            className={classes.dialogTitle}
          >
            Job Completion Form
          </Typography>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText
              className={classes.dialogText}
              id="modal-description"
            >
              Dealer Declaration: We certify the accuracy of all information on
              this claim. Supporting records are available for inspection at
              this dealership.
            </DialogContentText>
            <Grid
              container
              className={classes.dateCompleted}
              direction="column"
            >
              <Grid item>
                <Typography variant="h4">Date Repair Completed: </Typography>
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  inputVariant="outlined"
                  InputProps={{ className: classes.fieldset }}
                  fullWidth
                  format="dd/MM/yyyy"
                  value={claim.date}
                  onChange={(newDate) =>
                    setClaim({
                      ...claim,
                      date: newDate,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.uploadFiles}>
              <Grid item>
                <input
                  hidden
                  id="fileButton"
                  type="file"
                  onClick={(e) => (e.target.value = null)}
                  onChange={handleUploadFile}
                />
                <label htmlFor="fileButton">
                  <Button variant="contained" component="span">
                    Upload Image
                  </Button>
                </label>
              </Grid>
              <Grid
                item
                style={{
                  marginLeft: "0.5vw",
                }}
              >
                {fileName ? (
                  <Grid item container alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle2">{fileName}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          setFile({});
                          setFileName("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="subtitle2" style={{ paddingLeft: 10 }}>
                    Specific recalls only
                  </Typography>
                )}
              </Grid>
            </Grid>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                className={classes.inputLabel}
                htmlFor="comments"
              >
                Complaint / Diagnosis & Repair / Recall Details
              </Typography>
              <TextField
                className={classes.comments}
                InputProps={{ className: classes.textInput }}
                variant="outlined"
                rows={4}
                autoFocus
                id="comments"
                onChange={(e) =>
                  setClaim({
                    ...claim,
                    claimComments: e.target.value,
                  })
                }
                multiline
                fullWidth
              />
            </FormControl>
          </DialogContent>

          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={handleOpenJobCompletionForm}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Submit
            </Button>
          </DialogActions>
        </Grid>
      </MuiPickersUtilsProvider>
      <Snackbar
        open={fileSizeError}
        autoHideDuration={6000}
        onClose={handleCloseFileSizeError}
      >
        <Alert
          onClose={handleCloseFileSizeError}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Maximum file size exceeded: 5MB
        </Alert>
      </Snackbar>
      <Snackbar
        open={fileFormatError}
        autoHideDuration={6000}
        onClose={handleCloseFileFormatError}
      >
        <Alert
          onClose={handleCloseFileFormatError}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Images in these formats only: jpeg, svg, jpg, png, gif
        </Alert>
      </Snackbar>
    </>
  );
}
