import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import CheckCircleRounded from "@material-ui/icons/CheckCircleRounded";

import brandNames from "../../../util/brands";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "600px",
    },
  },
  circularProgress: {
    position: "absolute",
  },
  header: {
    padding: "10px 10px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px 18px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  selectContainer: {
    padding: "10px 5px 5px",
  },
  brandGridContainer: {
    paddingTop: "5px",
    paddingBottom: "5px",
    "& > label,h6": {
      paddingRight: "10px",
      paddingLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "5px",
        paddingLeft: "5px",
      },
    },
  },
  brandsGrid: {
    marginTop: 10,
    "& label": {
      display: "block",
      width: "100%",
    },
  },
  brandsGridItem: {
    padding: "0 10px 11px",
  },
  brandCheckbox: {
    width: "100%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    transition: "background-color 150ms linear",
    padding: "8px 21px",
    "&:hover": { backgroundColor: "inherit" },
  },
  select: {
    maxWidth: "85vw",
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
    "& .MuiInputBase-input": {
      ...theme.typography.body2,
    },
  },
  buttons: {
    marginTop: 10,
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      paddingBottom: 15,
    },
  },
  cancelButton: {
    paddingLeft: 0,
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  button: {
    padding: "12px 30px 13px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  error: {
    color: theme.palette.secondary.light,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const brandOptions = Object.values(brandNames);

const AddDealer = (props) => {
  const { currentUser, dealers, setDealers, setOpenAddDealer } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = useState({ name: "", email: "", password: "" });
  const [all, setAll] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const initialBrands = {};
  Object.keys(brandNames).forEach((key) => {
    initialBrands[key] = false;
  });
  const [brands, setBrands] = useState(initialBrands);

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenAddDealer(false);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const handleSelectBrand = (e) => {
    if (isMobile) {
      const value = e.target.value;
      let newSelected = [];
      if (value[value.length - 1] === "all") {
        if (!all) {
          setAll(true);
          newSelected = Object.keys(brands);
        } else {
          setAll(false);
        }
      } else {
        if (value.length === Object.keys(brands).length) {
          setAll(true);
        } else {
          setAll(false);
        }
        newSelected = value;
      }
      setSelectedBrands(newSelected);
      const newBrands = {};
      Object.keys(brands).forEach((key) => {
        newBrands[key] = newSelected.includes(key) ? true : false;
      });
      setBrands(newBrands);
    } else {
      if (e.target.name === "all") {
        setAll(!all);
        const updatedBrands = {};
        Object.keys(brands).forEach((key) => {
          updatedBrands[key] = e.target.checked;
        });
        setBrands(updatedBrands);
      } else {
        setAll(false);
        setBrands({ ...brands, [e.target.name]: e.target.checked });
      }
    }
  };

  useEffect(() => {
    if (Object.values(brands).every((value) => value === true)) setAll(true);
  }, [brands]);

  const handleSubmit = () => {
    if (confirmPassword !== user.password) {
      return setPasswordErr(true);
    }
    axios
      .post("/dealers", { user, brands })
      .then((res) => {
        setAll(false);
        setBrands(initialBrands);
        setPasswordErr(false);
        setUser({ name: "", email: "", password: "" });
        setDealers([res.data, ...dealers]);
        setOpenSuccessSnackbar(true);
      })
      .catch((e) => {
        console.log(e);
        setOpenErrorSnackbar(true);
      });
  };

  return (
    <React.Fragment>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          Create a Dealer | {currentUser?.name}
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          Create a new dealer account
        </Typography>
      </Box>
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <Typography
              component="label"
              htmlFor="dealer-name"
              className={classes.inputLabel}
            >
              Dealer Name
            </Typography>
            <TextField
              fullWidth
              id="dealer-name"
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              value={user.name}
              variant="outlined"
              onChange={(e) => {
                setUser({ ...user, name: e.target.value });
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <Typography
              component="label"
              htmlFor="email"
              className={classes.inputLabel}
            >
              Email Address
            </Typography>
            <TextField
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="email"
              value={user.email}
              variant="outlined"
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
              }}
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <Typography
              component="label"
              htmlFor="password"
              className={classes.inputLabel}
            >
              Password
            </Typography>
            <TextField
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="password"
              type="password"
              value={user.password}
              variant="outlined"
              onChange={(e) => {
                setUser({ ...user, password: e.target.value });
              }}
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <Typography
              component="label"
              htmlFor="confirm-password"
              className={classes.inputLabel}
            >
              Confirm Password
            </Typography>
            <TextField
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="confirm-password"
              type="password"
              value={confirmPassword}
              variant="outlined"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              fullWidth
            />
          </FormControl>
          {passwordErr && (
            <Typography variant="subtitle2" className={classes.error}>
              Passwords don't match
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} className={classes.brandGridContainer}>
          <Typography
            component="label"
            htmlFor="brands"
            className={classes.inputLabel}
            style={{ paddingTop: "10px" }}
          >
            Select Brands
          </Typography>
          <Typography variant="subtitle2">
            Select all applicable brands for dealer account
          </Typography>

          {isMobile ? (
            <Grid className={classes.selectContainer}>
              <Select
                id="brands"
                multiple
                fullWidth
                className={classes.select}
                onChange={handleSelectBrand}
                value={selectedBrands}
                renderValue={(selectedBrands) => {
                  return selectedBrands
                    .map((brand) => brandNames[brand])
                    .join(", ");
                }}
              >
                <MenuItem value="all">
                  <Typography
                    variant="body1"
                    style={{ padding: "9px 0", flexGrow: 1 }}
                  >
                    All Brands
                  </Typography>
                  <Checkbox
                    name="all"
                    checked={all}
                    icon={<></>}
                    checkedIcon={
                      <CheckCircleRounded
                        style={{
                          fontSize: "15px",
                          color: theme.palette.common.green,
                        }}
                      />
                    }
                  />
                </MenuItem>
                {brandOptions.map((brand, i) => {
                  const brandKey = Object.keys(brandNames)[i];
                  return (
                    <MenuItem key={brandKey} value={brandKey}>
                      <Typography
                        variant="body1"
                        style={{ padding: "9px 0", flexGrow: 1 }}
                      >
                        {brand}
                      </Typography>
                      <Checkbox
                        name={brandKey}
                        checked={selectedBrands.includes(brandKey)}
                        icon={<></>}
                        checkedIcon={
                          <CheckCircleRounded
                            style={{
                              fontSize: "15px",
                              color: theme.palette.common.green,
                            }}
                          />
                        }
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          ) : (
            <Grid container className={classes.brandsGrid}>
              <Grid item md={2} key="all" className={classes.brandsGridItem}>
                <input
                  hidden
                  type="checkbox"
                  id="all"
                  name="all"
                  checked={all}
                  onChange={handleSelectBrand}
                />
                <label htmlFor="all">
                  <Button
                    component="span"
                    variant="contained"
                    className={classes.brandCheckbox}
                    disableRipple
                    style={
                      all
                        ? {
                            backgroundColor: theme.palette.common.black,
                            color: theme.palette.common.white,
                          }
                        : {
                            backgroundColor: theme.palette.common.lightGrey,
                            color: theme.palette.common.black,
                          }
                    }
                  >
                    All Brands
                  </Button>
                </label>
              </Grid>
              {brandOptions.map((brand, i) => {
                const brandKey = Object.keys(brandNames)[i];
                return (
                  <Grid item md={2} key={i} className={classes.brandsGridItem}>
                    <input
                      hidden
                      type="checkbox"
                      id={brandKey}
                      name={brandKey}
                      checked={all ? true : brands[brandKey]}
                      onChange={handleSelectBrand}
                    />
                    <label htmlFor={brandKey}>
                      <Button
                        component="span"
                        variant="contained"
                        style={
                          brands[brandKey] === true
                            ? {
                                backgroundColor: theme.palette.common.black,
                                color: theme.palette.common.white,
                              }
                            : {
                                backgroundColor: theme.palette.common.lightGrey,
                                color: theme.palette.common.black,
                              }
                        }
                        disableRipple
                        className={classes.brandCheckbox}
                      >
                        {brand}
                      </Button>
                    </label>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          xs={12}
          className={classes.gridItem}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.buttons}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                Please make sure all information provided is correct before
                saving.
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} container justify="flex-end">
              <Button
                disabled={openSuccessSnackbar}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
              >
                Create Dealer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={openSuccessSnackbar}
        onClose={handleCloseSuccessSnackbar}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          style={{ alignItems: "center" }}
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Something went wrong, please try again
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AddDealer;
