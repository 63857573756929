import React, { useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import brandNames from "../../../util/brands";
import ClaimImageGrid from "./ClaimImageGrid";
import EditParts from "./EditParts";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "800px",
  },
  circularProgress: {
    position: "absolute",
  },
  header: {
    padding: "10px 10px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px 18px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  imageGridContainer: {
    padding: "10px 4px 5px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 0px",
    },
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  datePicker: {
    "& .MuiInputBase-root": {
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "13px 11px 12px",
      fontSize: "10px",
      lineHeight: "15px",
    },
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  select: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
  },
  buttons: {
    marginTop: 10,
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      paddingBottom: 15,
    },
  },
  cancelButton: {
    paddingLeft: 0,
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  button: {
    padding: "12px 30px 13px",
  },
  error: {
    color: theme.palette.secondary.light,
  },
}));

const AddClaim = (props) => {
  const { currentUser, setOpenAddClaim, priors, setPriors } = props;
  const classes = useStyles();
  const [prior, setPrior] = useState({ images: 0 });
  const [partNumbers, setPartNumbers] = useState({ part0: "" });
  const [quantity, setQuantity] = useState({ qty0: "" });
  const [dateOfFailureToggle, setDateOfFailureToggle] = useState(false);
  const [dateOfSaleToggle, setDateOfSaleToggle] = useState(false);
  const [imageURLs, setImageURLs] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [imageFormatError, setImageFormatError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const handleCloseSuccessSnackbar = (event, reason) => {
    setSuccess(false);
    setOpenAddClaim(false);
  };
  const handleCloseImageFormatSnackbar = (event, reason) => {
    setImageFormatError(false);
  };
  const handleCloseFileSizeSnackbar = (event, reason) => {
    setFileSizeError(false);
  };
  const handleCloseMaxFilesSnackbar = (event, reason) => {
    setMaxFilesError(false);
  };

  const brandOptions = [];

  Object.keys(currentUser.brands).forEach((key) => {
    if (currentUser.brands[key])
      brandOptions.push(
        <MenuItem key={key} value={key}>
          {brandNames[key]}
        </MenuItem>
      );
  });

  const toggleDateOfFailure = () => {
    if (dateOfFailureToggle === true) {
      setPrior({ ...prior, dateOfFailure: null });
    } else {
      setPrior({ ...prior, dateOfFailure: new Date() });
    }
    setDateOfFailureToggle(!dateOfFailureToggle);
  };

  const toggleDateOfSale = () => {
    if (dateOfSaleToggle === true) {
      setPrior({ ...prior, dateOfSale: null });
    } else {
      setPrior({ ...prior, dateOfSale: new Date() });
    }
    setDateOfSaleToggle(!dateOfSaleToggle);
  };

  const handleImageFiles = (e) => {
    let newImageFileList = Array.from(e.target.files);
    for (const file of newImageFileList) {
      if (
        !file.type.includes("image/jpg") &&
        !file.type.includes("image/jpeg") &&
        !file.type.includes("image/gif") &&
        !file.type.includes("image/svg") &&
        !file.type.includes("image/png") &&
        !file.type.includes("application/pdf")
      )
        return setImageFormatError(true);
    }
    for (const file of newImageFileList) {
      if (file.size > 5100000) return setFileSizeError(true);
    }
    if (newImageFileList.length + imageURLs.length > 6) setMaxFilesError(true);
    else {
      let arr = [];
      if (imageFiles.length === 0) {
        setImageFiles(newImageFileList);
      } else {
        setImageFiles([...imageFiles, ...newImageFileList]);
      }
      setPrior({
        ...prior,
        images: newImageFileList.length + imageURLs.length,
      });
      newImageFileList.forEach((file) => {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          arr.push({ [file.name]: fileReader.result });
          setImageURLs([...imageURLs, ...arr]);
        };
      });
    }
  };

  const deleteImages = (imageToDelete) => {
    //FILES
    const filesCopy = [...imageFiles];
    const nameToDelete = Object.keys(imageToDelete)[0];
    const filteredImageFiles = filesCopy.filter(
      (file) => file.name !== nameToDelete
    );
    setImageFiles(filteredImageFiles);
    ///IMAGE URLS
    const imageURLsCopy = [...imageURLs];
    const toDelete = Object.values(imageToDelete)[0];
    const filteredImageURLs = imageURLsCopy.filter(
      (image) => Object.values(image)[0] !== toDelete
    );
    setImageURLs(filteredImageURLs);
    setPrior({ ...prior, images: filteredImageURLs.length });
  };

  const handleCancel = () => {
    setOpenAddClaim(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = new FormData();

    let p = Object.fromEntries(Object.entries(partNumbers).sort());
    let q = Object.fromEntries(Object.entries(quantity).sort());
    Object.entries(p).forEach((o) => (o[1] === "" ? delete p[o[0]] : null));
    Object.entries(q).forEach((o) => (o[1] === "" ? delete q[o[0]] : null));

    for (let index = 0; index < Object.keys(p).length; index++) {
      const number = Object.keys(p)[index].split("part")[1];
      if (q[`qty${number}`] === undefined || q[`qty${index}`] === "") {
        setLoading(false);
        return setErrors({
          ...errors,
          partNumbers: "Please check part number has quantity or vice versa",
        });
      }
    }

    for (let index = 0; index < Object.keys(q).length; index++) {
      const number = Object.keys(q)[index].split("qty")[1];
      if (p[`part${number}`] === undefined || p[`part${index}`] === "") {
        setLoading(false);
        return setErrors({
          ...errors,
          partNumbers: "Please check part number has quantity or vice versa",
        });
      }
    }

    axios
      .post("/prior", { prior, p, q })
      .then((res) => {
        const newPrior = res.data;
        newPrior.id = res.data._id;
        newPrior.createdAt = format(
          new Date(res.data.createdAt),
          "dd/MM/yyyy HH:mm"
        );
        newPrior.updatedAt = format(
          new Date(res.data.updatedAt),
          "dd/MM/yyyy HH:mm"
        );
        const updatedPriors = [newPrior, ...priors];
        setPriors(updatedPriors);

        if (imageFiles.length > 0) {
          data.append("prior", res.data._id);
          for (let i = 0; i < imageFiles.length; i++) {
            data.append("files", imageFiles[i]);
          }
          axios
            .post("/upload", data)
            .then((res) => {
              console.log("ok");
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      })
      .then(() => {
        setErrors({});
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setErrors(err.response.data);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box className={classes.header}>
          <Typography variant="h3" id="modal-title">
            Add Claim | {currentUser?.name}
          </Typography>
          <Typography variant="subtitle2" id="modal-description">
            Submit a new claim
          </Typography>
        </Box>
        <Grid container direction="row" className={classes.root}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Grid
              item
              container
              alignItems="center"
              spacing={1}
              style={{ paddingBottom: 1 }}
            >
              <Grid item>
                <Typography variant="h4">Date of Failure</Typography>
              </Grid>
              <Grid item>
                <Switch
                  size="small"
                  checked={dateOfFailureToggle}
                  onChange={toggleDateOfFailure}
                  name="checked"
                  inputProps={{
                    "aria-label": "add/remove date of failure",
                  }}
                />
              </Grid>
            </Grid>
            <KeyboardDatePicker
              className={classes.datePicker}
              maxDate={Date.now()}
              inputVariant="outlined"
              InputProps={{ className: classes.fieldset }}
              disabled={!dateOfFailureToggle}
              fullWidth
              format="dd/MM/yyyy"
              value={
                dateOfFailureToggle === false ? undefined : prior.dateOfFailure
              }
              onChange={(newValue) =>
                setPrior({ ...prior, dateOfFailure: newValue })
              }
            />
          </Grid>

          <Grid item xs={12} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="customer-name"
                className={classes.inputLabel}
              >
                Customer Name
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="customer-name"
                fullWidth
                onChange={(e) =>
                  setPrior({ ...prior, customerName: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="mileage"
                className={classes.inputLabel}
              >
                Mileage (km)
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="mileage"
                fullWidth
                error={errors.mileage ? true : false}
                onChange={(e) =>
                  setPrior({ ...prior, mileage: e.target.value })
                }
              />
            </FormControl>
            {errors.mileage && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.mileage}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl} fullWidth>
              <Typography
                component="label"
                htmlFor="select-brand"
                className={classes.inputLabel}
              >
                Brand
              </Typography>
              <Select
                variant="filled"
                className={classes.select}
                error={errors.brand ? true : false}
                disableUnderline
                fullWidth
                defaultValue={""}
                id="select-brand"
                value={prior.brand || ""}
                onChange={(e) => setPrior({ ...prior, brand: e.target.value })}
                inputProps={{
                  "aria-label": "Select brand",
                }}
              >
                <MenuItem value={""} disabled>
                  <em>Select brand:</em>
                </MenuItem>
                {brandOptions.map((option) => option)}
              </Select>
            </FormControl>
            {errors.brand && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.brand}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="model"
                className={classes.inputLabel}
              >
                Model
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="model"
                fullWidth
                onChange={(e) => setPrior({ ...prior, model: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="vin"
                className={classes.inputLabel}
              >
                VIN #
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="vin"
                error={errors.vin ? true : false}
                fullWidth
                onChange={(e) => setPrior({ ...prior, vin: e.target.value })}
              />
            </FormControl>
            {errors.vin && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.vin}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Grid
              item
              container
              alignItems="center"
              spacing={1}
              style={{ paddingBottom: 1 }}
            >
              <Grid item>
                <Typography variant="h4">Date of Sale</Typography>
              </Grid>
              <Grid item>
                <Switch
                  size="small"
                  checked={dateOfSaleToggle}
                  onChange={toggleDateOfSale}
                  name="checked"
                  inputProps={{ "aria-label": "add/remove date of sale" }}
                />
              </Grid>
            </Grid>
            <KeyboardDatePicker
              className={classes.datePicker}
              maxDate={Date.now()}
              inputVariant="outlined"
              InputProps={{ className: classes.fieldset }}
              value={dateOfSaleToggle === false ? undefined : prior.dateOfSale}
              disabled={!dateOfSaleToggle}
              fullWidth
              format="dd/MM/yyyy"
              onChange={(newValue) =>
                setPrior({ ...prior, dateOfSale: newValue })
              }
            />
          </Grid>

          <Grid item sm={6} />

          <Grid item xs={12} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="symptoms"
                className={classes.inputLabel}
              >
                Symptoms/Customer Complaint
              </Typography>
              <TextField
                variant="outlined"
                InputProps={{ className: classes.textInput }}
                id="symptoms"
                rows={matches ? 5 : 10}
                multiline
                fullWidth
                onChange={(e) =>
                  setPrior({ ...prior, symptoms: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="diagnosis"
                className={classes.inputLabel}
              >
                Diagnosis - Cause of failure
              </Typography>
              <TextField
                id="diagnosis"
                variant="outlined"
                InputProps={{ className: classes.textInput }}
                rows={matches ? 5 : 10}
                multiline
                fullWidth
                onChange={(e) =>
                  setPrior({ ...prior, diagnosis: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          {/* IMAGES */}
          <Grid item xs={12} className={classes.imageGridContainer}>
            <ClaimImageGrid
              handleImageFiles={handleImageFiles}
              prior={prior}
              imageURLs={imageURLs}
              deleteImages={deleteImages}
            />
          </Grid>

          {/* PARTS */}
          <EditParts
            partNumbers={partNumbers}
            setPartNumbers={setPartNumbers}
            quantity={quantity}
            setQuantity={setQuantity}
            errors={errors}
          />

          <Grid
            item
            container
            alignItems="center"
            xs={12}
            className={classes.gridItem}
          >
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              className={classes.buttons}
            >
              <Button className={classes.cancelButton} onClick={handleCancel}>
                Cancel
              </Button>

              <Button
                className={classes.button}
                disabled={loading || success}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
              >
                Submit Claim
                {loading && (
                  <CircularProgress
                    size={20}
                    className={classes.circularProgress}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <Snackbar
        open={success}
        onClose={handleCloseSuccessSnackbar}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          style={{ alignItems: "center" }}
        >
          Claim submitted!
        </Alert>
      </Snackbar>
      <Snackbar
        open={imageFormatError}
        autoHideDuration={6000}
        onClose={handleCloseImageFormatSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseImageFormatSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Images in these formats only: jpeg, svg, jpg, png, gif, pdf
        </Alert>
      </Snackbar>
      <Snackbar
        open={fileSizeError}
        autoHideDuration={6000}
        onClose={handleCloseFileSizeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseFileSizeSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Maximum per each file: 5MB
        </Alert>
      </Snackbar>
      <Snackbar
        open={maxFilesError}
        autoHideDuration={6000}
        onClose={handleCloseMaxFilesSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseMaxFilesSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Maximum files allowed: 6
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AddClaim;
