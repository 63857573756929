import React, { useState } from "react";
import axios from "axios";
import PlacesAutocomplete from "react-places-autocomplete";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import brandNames from "../../../util/brands";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    position: "absolute",
  },
  root: {
    maxWidth: "800px",
  },
  header: {
    padding: "10px 10px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  formControl: {
    width: "100%",
  },
  datePicker: {
    "& .MuiInputBase-root": {
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "13px 11px 12px",
      fontSize: "10px",
      lineHeight: "15px",
    },
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  select: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
  },
  cancelButton: {
    paddingLeft: 0,
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  button: {
    padding: "12px 30px 13px",
    height: "100%",
    width: "100%",
  },
  buttons: {
    marginTop: 20,
    gap: "10px",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      paddingBottom: 15,
    },
  },
  fileUpload: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 5,
    },
  },
  fileButton: {
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  fileName: {
    paddingLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      justifyContent: "center",
    },
  },
  iconButton: {
    padding: 10,
    fontSize: "15px",
  },
  error: {
    color: theme.palette.secondary.light,
  },
}));

const AddRego = (props) => {
  const { currentUser, setOpenAddRego, regos, setRegos } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [alreadyRegisteredError, setAlreadyRegisteredError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [rego, setRego] = useState({
    address: "",
    dateOfSale: new Date(),
  });

  const brandOptions = [];
  Object.keys(currentUser.brands).forEach((key) => {
    if (currentUser.brands[key])
      brandOptions.push(
        <MenuItem key={key} value={key}>
          {brandNames[key]}
        </MenuItem>
      );
  });

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenAddRego(false);
  };

  const handleCloseRegisteredError = (event, reason) => {
    setAlreadyRegisteredError(false);
  };

  const handleCloseFileSizeError = (event, reason) => {
    setFileSizeError(false);
  };

  //Handling Files
  const handleChange = (e) => {
    if (e.target.files[0].size > 5100000) {
      return setFileSizeError(true);
    }
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setFileName(e.target.files[0].name);
    setFile(formData);
  };

  const handleChangeBrand = (event) => {
    setRego({ ...rego, brand: event.target.value });
  };

  const handleChangeAddress = (address) => {
    setRego({ ...rego, address });
  };

  const handleSelectAddress = (address) => {
    setRego({ ...rego, address });
  };

  const searchOptions = {
    componentRestrictions: { country: ["nz"] },
  };

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post("/regos", { rego })
      .then((res) => {
        if (file) {
          file.append("vin", rego.vin);
          axios
            .post("/pdi", file)
            .then(() => {
              setRegos([res.data, ...regos]);
              setOpenSuccessSnackbar(true);
              setLoading(false);
            })
            .catch((e) => {
              console.log(e);
              setLoading(false);
            });
        }
      })
      .catch((e) => {
        const errors = e.response.data.errmsg;
        if (errors.includes("E11000" && "vin")) {
          setAlreadyRegisteredError(true);
          setErrors({ vin: "This VIN  has already been registered" });
        } else {
          console.log(e);
        }
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box className={classes.header}>
          <Typography variant="h3" id="modal-title">
            Add Registration | {currentUser?.name}
          </Typography>
          <Typography variant="subtitle2" id="modal-description">
            Submit a new registration
          </Typography>
        </Box>
        <Grid container className={classes.root}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="customer-name"
                className={classes.inputLabel}
              >
                Customer Name
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="customer-name"
                fullWidth
                onChange={(e) => {
                  setRego({ ...rego, name: e.target.value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={9} md={4} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <PlacesAutocomplete
                searchOptions={searchOptions}
                value={rego.address}
                onChange={handleChangeAddress}
                onSelect={handleSelectAddress}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <React.Fragment>
                    <Typography
                      component="label"
                      htmlFor="customer-address"
                      className={classes.inputLabel}
                    >
                      Customer Address
                    </Typography>
                    <TextField
                      variant="outlined"
                      inputProps={{ className: classes.textInput }}
                      InputProps={{ className: classes.fieldset }}
                      fullWidth
                      id="customer-address"
                      {...getInputProps({})}
                    />
                    <MenuList disablePadding>
                      {suggestions.map((suggestion) => {
                        return (
                          <MenuItem {...getSuggestionItemProps(suggestion)}>
                            {suggestion.description}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </React.Fragment>
                )}
              </PlacesAutocomplete>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={3}
            md={2}
            className={classes.gridItem}
            style={{ paddingLeft: 5 }}
          >
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="postcode"
                className={classes.inputLabel}
              >
                Post Code
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                fullWidth
                id="postcode"
                onChange={(e) => {
                  setRego({ ...rego, postCode: e.target.value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="customer-email"
                className={classes.inputLabel}
              >
                Customer Email
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                fullWidth
                id="customer-email"
                onChange={(e) => {
                  setRego({ ...rego, email: e.target.value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="customer-phone"
                className={classes.inputLabel}
              >
                Customer Phone
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                fullWidth
                id="customer-phone"
                onChange={(e) => {
                  setRego({ ...rego, phone: e.target.value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginTop: 5, paddingBottom: isMobile ? 5 : 0 }}
          >
            <Typography variant="h3">Vehicle information</Typography>
          </Grid>

          <Grid item xs={12} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl} fullWidth>
              <Typography
                component="label"
                htmlFor="select-brand"
                className={classes.inputLabel}
              >
                Brand
              </Typography>
              <Select
                variant="filled"
                className={classes.select}
                value={rego.brand || ""}
                disableUnderline
                fullWidth
                id="select-brand"
                onChange={handleChangeBrand}
                inputProps={{
                  "aria-label": "Select brand",
                }}
              >
                <MenuItem value={""} disabled>
                  <em>Select brand:</em>
                </MenuItem>
                {brandOptions.map((option) => option)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="model"
                className={classes.inputLabel}
              >
                Model
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="model"
                fullWidth
                onChange={(e) => {
                  setRego({ ...rego, model: e.target.value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="vin"
                className={classes.inputLabel}
              >
                VIN #
              </Typography>
              <TextField
                variant="outlined"
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                fullWidth
                id="vin"
                error={alreadyRegisteredError ? true : false}
                onChange={(e) => {
                  setRego({ ...rego, vin: e.target.value });
                }}
              />
              {errors.vin && alreadyRegisteredError && (
                <Typography variant="subtitle2" className={classes.error}>
                  {errors.vin}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="plate-number"
                className={classes.inputLabel}
              >
                Registration Plate Number
              </Typography>
              <TextField
                fullWidth
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="plate-number"
                variant="outlined"
                onChange={(e) => {
                  setRego({ ...rego, plate: e.target.value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="key-number"
                className={classes.inputLabel}
              >
                Key Number
              </Typography>
              <TextField
                fullWidth
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="key-number"
                variant="outlined"
                onChange={(e) => {
                  setRego({ ...rego, keyNumber: e.target.value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                htmlFor="colour"
                className={classes.inputLabel}
              >
                Colour
              </Typography>
              <TextField
                fullWidth
                inputProps={{ className: classes.textInput }}
                InputProps={{ className: classes.fieldset }}
                id="colour"
                variant="outlined"
                onChange={(e) => {
                  setRego({ ...rego, colour: e.target.value });
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.inputLabel}>Date of Sale</Typography>
            <KeyboardDatePicker
              className={classes.datePicker}
              fullWidth
              inputVariant="outlined"
              InputProps={{ className: classes.fieldset }}
              format="dd/MM/yyyy"
              maxDate={Date.now()}
              value={rego.dateOfSale}
              onChange={(newValue) => {
                setRego({ ...rego, dateOfSale: newValue });
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              className={classes.buttons}
            >
              {isMobile && (
                <Grid item xs={12}>
                  <Typography variant="h3">Documentation</Typography>
                </Grid>
              )}
              <Grid item className={classes.fileUpload}>
                <input
                  hidden
                  id="fileButton"
                  type="file"
                  onClick={(e) => (e.target.value = null)}
                  onChange={handleChange}
                />
                <label htmlFor="fileButton" className={classes.fileButton}>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    component="span"
                  >
                    <Grid container direction="column">
                      <Typography
                        variant="button"
                        style={{ textAlign: "center" }}
                      >
                        Upload PDI - Mandatory
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          textAlign: "center",
                          color: theme.palette.common.white,
                        }}
                      >
                        5MB File Limit
                      </Typography>
                    </Grid>
                  </Button>
                </label>
                {fileName && (
                  <React.Fragment>
                    <Grid
                      item
                      container
                      alignItems="center"
                      className={classes.fileName}
                    >
                      <Grid item>
                        <Typography variant="subtitle2">{fileName}</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setFile({});
                            setFileName("");
                          }}
                          className={classes.iconButton}
                        >
                          <CloseIcon style={{ fontSize: "15px" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button
                  disabled={
                    rego.vin &&
                    rego.brand &&
                    rego.model &&
                    rego.colour &&
                    rego.dateOfSale &&
                    rego.dateOfSale <= Date.now() &&
                    rego.name &&
                    rego.address &&
                    rego.postCode &&
                    fileName &&
                    !loading &&
                    !openSuccessSnackbar
                      ? false
                      : true
                  }
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleSubmit}
                  startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
                >
                  Submit WR
                  {loading && (
                    <CircularProgress
                      size={20}
                      className={classes.circularProgress}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <Snackbar
        open={openSuccessSnackbar}
        onClose={handleCloseSuccessSnackbar}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          style={{ alignItems: "center" }}
        >
          Registration succesfully added!
        </Alert>
      </Snackbar>
      <Snackbar
        open={alreadyRegisteredError}
        autoHideDuration={6000}
        onClose={handleCloseRegisteredError}
      >
        <Alert
          onClose={handleCloseRegisteredError}
          severity="error"
          style={{ alignItems: "center" }}
        >
          {`VIN ${rego.vin} has already been registered `}
        </Alert>
      </Snackbar>
      <Snackbar
        open={fileSizeError}
        autoHideDuration={6000}
        onClose={handleCloseFileSizeError}
      >
        <Alert
          onClose={handleCloseFileSizeError}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Maximum file size exceeded: 5MB
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AddRego;
