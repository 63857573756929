import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import EditRounded from "@material-ui/icons/EditRounded";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import StyledTablePagination from "../StyledTablePagination";
import StyledTableRow from "../StyledTableRow";
import getMonthsList from "../../util/getMonthsList";
import ModalBase from "../modals/ModalBase";
import EditThroughputReport from "../modals/reports/EditThroughputReport";
import ViewThroughputReport from "../modals/reports/ViewThroughputReport";
import getDueDate from "../../util/getDueDate";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0 10px 20px",
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
  headerRow: {
    backgroundColor: theme.palette.common.mediumGrey,
    "& > th": {
      fontWeight: 600,
      lineHeight: 1.3,
      padding: "12px 6px",
    },
    "& > th:first-of-type": {
      paddingLeft: "50px",
      borderRadius: "5px 0 0 5px",
    },
    "& > th:last-of-type": {
      paddingRight: "12px",
      borderRadius: "0 5px 5px 0",
    },
  },
  mobileHeaderRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  mobileCell: {
    borderRadius: "5px",
  },
  mobileGrid: {
    padding: "15px",
  },
  mobileCellContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    padding: "12px",
  },
  name: {
    marginBottom: 9,
  },
}));

export default function ThroughputsTable(props) {
  const { dealers, dealerFilter, monthFilter, isAdmin } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [rows, setRows] = useState([]);
  const [openEditReport, setOpenEditReport] = useState(false);
  const [openViewReport, setOpenViewReport] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    let mounted = true;
    axios
      .get("/reports/throughputs")
      .then((res) => {
        if (mounted) setReports(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    const monthsList = getMonthsList();
    let newRows = [];
    dealers.forEach((dealer) => {
      if (dealer.name !== process.env.REACT_APP_ADMIN_ID) {
        monthsList.forEach((month) => {
          const report = reports.find((report) => {
            return (
              format(new Date(report.month), "MMMM yyyy") ===
                format(new Date(month), "MMMM yyyy") &&
              report.dealerName === dealer.name
            );
          });
          if (!report) {
            newRows.push({
              month: month,
              dealerName: dealer.name,
              data: [],
            });
          } else {
            report.month = format(new Date(report.month), "dd MMMM yyyy");
            newRows.push(report);
          }
        });
      }
    });
    setRows(newRows);
  }, [reports, dealers]);

  let filteredReports = rows;
  if (dealerFilter || monthFilter) {
    filteredReports = rows.filter((report) => {
      return (
        (dealerFilter ? report.dealerName === dealerFilter : true) &&
        (monthFilter
          ? format(new Date(report.month), "MMMM yyyy") === monthFilter
          : true)
      );
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [dealerFilter, monthFilter]);

  useEffect(() => {
    if (filteredReports.length / rowsPerPage < page) {
      setPage(0);
    }
  }, [filteredReports.length, rowsPerPage, page]);

  return (
    <>
      <div className={classes.root}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {!isMobile && (
            <TableHead>
              <TableRow className={classes.headerRow}>
                {isAdmin && <TableCell align="left">Dealer</TableCell>}
                <TableCell align="left">Month</TableCell>
                <TableCell align="left">Total Unit Intake</TableCell>
                <TableCell align="left">Total Jobs Completed</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            <TableRow></TableRow>
            {filteredReports
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((report, i) => {
                const totalUnitIntake = report.data.reduce(
                  (acc, curr) => acc + curr.unitIntake,
                  0
                );
                const totalJobsCompleted = report.data.reduce(
                  (acc, curr) => acc + curr.jobsCompleted,
                  0
                );
                const dueDate = getDueDate(report.month);
                const isEditable = dueDate > new Date() || isAdmin;
                const formattedDate = format(
                  new Date(report.month),
                  "MMMM yyyy"
                );

                return (
                  <StyledTableRow key={i}>
                    {isMobile ? (
                      <TableCell className={classes.mobileCell} colSpan={11}>
                        <Box
                          className={classes.mobileGrid}
                          onClick={() => {
                            setSelectedReport(report);
                            isEditable
                              ? isAdmin
                                ? setOpenViewReport(true)
                                : setOpenEditReport(true)
                              : setOpenViewReport(true);
                          }}
                        >
                          <Box className={classes.mobileHeaderRow}>
                            {isAdmin && (
                              <Typography className={classes.name} variant="h2">
                                {report.dealerName}
                              </Typography>
                            )}
                            <Typography
                              className={classes.name}
                              variant={isAdmin ? "body1" : "h2"}
                            >
                              {formattedDate}
                            </Typography>
                          </Box>
                          <Box className={classes.mobileCellContent}>
                            <Box>
                              <Typography>
                                <strong>Total Unit Intake: </strong>
                                {totalUnitIntake}
                              </Typography>
                              <Typography>
                                <strong>Total Jobs Completed: </strong>
                                {totalJobsCompleted}
                              </Typography>
                            </Box>
                            <IconButton style={{ paddingBottom: "20px" }}>
                              {isEditable ? (
                                <EditRounded />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                      </TableCell>
                    ) : (
                      <>
                        {isAdmin && (
                          <TableCell
                            component="th"
                            padding="none"
                            scope="row"
                            align="left"
                          >
                            {report.dealerName}
                          </TableCell>
                        )}
                        {isAdmin ? (
                          <TableCell align="left">{formattedDate}</TableCell>
                        ) : (
                          <TableCell
                            component="th"
                            padding="none"
                            scope="row"
                            align="left"
                          >
                            {formattedDate}
                          </TableCell>
                        )}
                        <TableCell align="left">{totalUnitIntake}</TableCell>
                        <TableCell align="left">{totalJobsCompleted}</TableCell>
                        <TableCell align="center">
                          {isEditable ? (
                            <Button
                              className="table-button"
                              disableElevation
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setSelectedReport(report);
                                isAdmin
                                  ? setOpenViewReport(true)
                                  : setOpenEditReport(true);
                              }}
                            >
                              {isAdmin ? "View/Edit" : "Edit"}
                            </Button>
                          ) : (
                            <Button
                              className="table-button"
                              disableElevation
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setSelectedReport(report);
                                setOpenViewReport(true);
                              }}
                            >
                              View
                            </Button>
                          )}
                        </TableCell>
                      </>
                    )}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
        <StyledTablePagination
          count={filteredReports.length}
          rowsPerPage={rowsPerPage}
          page={
            Math.floor(filteredReports.length / rowsPerPage) < page ? 0 : page
          }
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <ModalBase openModal={openEditReport} setOpenModal={setOpenEditReport}>
        <EditThroughputReport
          selectedReport={selectedReport}
          setOpenEditReport={setOpenEditReport}
          rows={rows}
          setRows={setRows}
          dealers={dealers}
        />
      </ModalBase>
      <ModalBase openModal={openViewReport} setOpenModal={setOpenViewReport}>
        <ViewThroughputReport
          selectedReport={selectedReport}
          setOpenViewReport={setOpenViewReport}
          setOpenEditReport={setOpenEditReport}
          dealers={dealers}
          isAdmin={isAdmin}
        />
      </ModalBase>
    </>
  );
}
