import { format } from "date-fns";

export default function getMonthsList() {
  const monthsList = [];
  const currentMonth = new Date();
  const startMonth = new Date("1 February 2023");

  if (currentMonth >= startMonth) {
    while (currentMonth >= startMonth) {
      monthsList.push(format(currentMonth, "dd MMMM yyyy"));
      currentMonth.setMonth(currentMonth.getMonth() - 1);
    }
  }

  return monthsList;
}
