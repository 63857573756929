import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    "&>.MuiToolbar-root": {
      gap: "10px",
      paddingRight: "16px",
    },
    "& .MuiTablePagination-actions": {
      marginLeft: 8,
      "& .MuiIconButton-root:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiIconButton-root:last-of-type": {
        paddingRight: 0,
      },
    },
  },
  paginationSelect: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    fontSize: 11,
    marginRight: 20,
  },
}));

export default function StyledTablePagination(props) {
  const classes = useStyles();

  return (
    <TablePagination
      className={classes.paginationContainer}
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      backIconButtonProps={{ disableRipple: true }}
      nextIconButtonProps={{ edge: "end", disableRipple: true }}
      SelectProps={{ variant: "filled", className: classes.paginationSelect }}
      {...props}
    />
  );
}
