import React from "react";
import ListItem from "@material-ui/core/ListItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import ExpandMoreRounded from "@material-ui/icons/ExpandMoreRounded";

import IconContainer from "../IconContainer";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "0 auto 18px",
    padding: 0,
    width: "100%",
  },
  tabButton: {
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 18px 8px 13px",
    height: 40,
    width: "100%",
    backgroundColor: theme.palette.common.lightGrey,
    color: theme.palette.common.black,
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.05)",
    textDecoration: "none",
    "&[isselected=true]": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    "&[isselected=false]&:hover": {
      backgroundColor: theme.palette.common.mediumGrey,
    },
  },
  tabText: {
    ...theme.typography.tab,
    flexGrow: 1,
    "&[isselected=true]": {
      color: theme.palette.common.white,
    },
  },
  icon: {
    fontSize: "14px",
    marginRight: 17,
  },
  dropDownIcon: {
    fontSize: "22px",
    backgroundColor: theme.palette.common.lightGrey,
    borderRadius: "50%",
    "&[isselected=true]": {
      backgroundColor: theme.palette.common.darkGrey,
    },
  },
}));

export default function NavTab(props) {
  const { children, icon, label, to, handleClick, selectedTab } = props;
  const classes = useStyles();
  const isSelected = selectedTab ? selectedTab === label : false;

  return (
    <ListItem className={classes.tabContainer} disableGutters>
      <Button
        className={classes.tabButton}
        isselected={String(isSelected)}
        component={Link}
        to={to}
        onClick={(e) => handleClick(e, label)}
      >
        {icon && (
          <IconContainer>
            <SvgIcon component={icon} className={classes.icon} />
          </IconContainer>
        )}
        <Typography className={classes.tabText} isselected={String(isSelected)}>
          {label}
        </Typography>
        {children && (
          <SvgIcon
            component={isSelected ? ExpandMoreRounded : ChevronRightRounded}
            className={classes.dropDownIcon}
            isselected={String(isSelected)}
          />
        )}
      </Button>
      {children}
    </ListItem>
  );
}
