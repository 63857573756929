import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt from "jwt-decode";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import Login from "./components/Login";
import ClaimsDashboard from "./pages/ClaimsDashboard";
import { Route, Switch, withRouter } from "react-router-dom";
import AuthRoute from "./util/AuthRoute";
import AuthRoute2 from "./util/AuthRoute2";
import RegoDashboard from "./pages/RegoDashboard";
import Dealers from "./pages/Dealers";
// import VinLookup from "./pages/VinLookup";
import Users from "./pages/Users";
import Sidebar from "./components/menus/Sidebar";
import MobileHeader from "./components/MobileHeader";
import Reports from "./pages/Reports";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    padding: 0,
    display: "flex",
  },
  main: {
    flex: 1,
    width: "100%",
    backgroundColor: theme.palette.common.lightGrey,
    padding: "40px 87px 37px 85px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.between("lg", 1425)]: {
      padding: "40px 15px 37px 15px",
    },
  },
}));

const App = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Warranty Claims");
  const [selectedSubTab, setSelectedSubTab] = useState("View all");
  const [oldToken, setOldToken] = useState(false);

  const MobileHeaderWithContext = (headerProps) => (
    <MobileHeader
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      {...headerProps}
    />
  );

  const refreshAuthLogic = async (failedRequest) => {
    setOldToken(true);
    axios.defaults.headers.common["Authorization"] = localStorage.refreshToken;
    await axios
      .post("/token", { oldToken: localStorage.Token })
      .then((res) => {
        localStorage.setItem("Token", res.data);
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + res.data;
        setOldToken(false);
        return Promise.resolve();
      })
      .catch((e) => {
        localStorage.removeItem("Token");
        localStorage.removeItem("refreshToken");
        window.location.replace("/");
      });
  };

  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  let admin = false;
  let id = null;

  let authenticated = localStorage.Token ? true : false;

  if (authenticated) {
    axios.defaults.headers.common["Authorization"] = localStorage.Token;
    id = jwt(localStorage.Token).name;
    if (id === process.env.REACT_APP_ADMIN_ID) admin = true;
  }

  useEffect(() => {
    if (authenticated && !oldToken) {
      setLoading(true);
      axios
        .get(`/user/${id}`)
        .then((res) => {
          setCurrentUser(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, authenticated, oldToken]);

  return (
    <Box className={classes.root} flexDirection={isMobile ? "column" : "row"}>
      {loading ? (
        <CircularProgress
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
          }}
        />
      ) : (
        <>
          {authenticated && (
            <Sidebar
              id={id}
              currentUser={currentUser}
              isAdmin={admin}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              selectedSubTab={selectedSubTab}
              setSelectedSubTab={setSelectedSubTab}
            />
          )}
          <Box className={classes.main}>
            {admin === true ? (
              <Switch>
                <AuthRoute
                  exact
                  path="/"
                  component={Login}
                  authenticated={authenticated}
                />
                <AuthRoute2
                  exact
                  path="/dashboard/1"
                  component={ClaimsDashboard}
                  authenticated={authenticated}
                  currentUser={currentUser}
                  isAdmin={admin}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                />
                <AuthRoute2
                  exact
                  path="/dashboard/2"
                  component={RegoDashboard}
                  authenticated={authenticated}
                  currentUser={currentUser}
                  isAdmin={admin}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                />
                <AuthRoute2
                  exact
                  path={"/accounts/dealer"}
                  component={Dealers}
                  authenticated={authenticated}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                  currentUser={currentUser}
                />
                <AuthRoute2
                  exact
                  path={"/accounts/user"}
                  component={Users}
                  authenticated={authenticated}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                  currentUser={currentUser}
                />
                <AuthRoute2
                  exact
                  path={"/reports/throughputs"}
                  component={Reports}
                  authenticated={authenticated}
                  isAdmin={admin}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                  currentUser={currentUser}
                />
                <AuthRoute2
                  exact
                  path={"/reports/stock-reports"}
                  component={Reports}
                  authenticated={authenticated}
                  isAdmin={admin}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                  currentUser={currentUser}
                />
                <Route path="*" component={() => "404 NOT FOUND"} />
              </Switch>
            ) : (
              <Switch>
                <AuthRoute
                  exact
                  path="/"
                  component={Login}
                  authenticated={authenticated}
                />
                <AuthRoute2
                  exact
                  path="/dashboard/1"
                  component={ClaimsDashboard}
                  authenticated={authenticated}
                  isAdmin={admin}
                  currentUser={currentUser}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                />
                <AuthRoute2
                  exact
                  path="/dashboard/2"
                  component={RegoDashboard}
                  authenticated={authenticated}
                  currentUser={currentUser}
                  isAdmin={admin}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                />
                <AuthRoute2
                  exact
                  path={"/reports/throughputs"}
                  component={Reports}
                  authenticated={authenticated}
                  isAdmin={admin}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                  currentUser={currentUser}
                />
                <AuthRoute2
                  exact
                  path={"/reports/stock-reports"}
                  component={Reports}
                  authenticated={authenticated}
                  isAdmin={admin}
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  MobileHeaderWithContext={MobileHeaderWithContext}
                  currentUser={currentUser}
                />
                {/* <AuthRoute2
                  exact
                  path={"/vin-lookup"}
                  component={VinLookup}
                  authenticated={authenticated}
                /> */}
                <Route path="*" component={() => "404 NOT FOUND"} />
              </Switch>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(App);
