import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import EditRounded from "@material-ui/icons/EditRounded";

import StyledTablePagination from "../StyledTablePagination";
import StyledTableRow from "../StyledTableRow";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
  headerRow: {
    backgroundColor: theme.palette.common.mediumGrey,
    "& > th": {
      fontWeight: 600,
      lineHeight: 1.3,
      padding: "12px 6px",
    },
    "& > th:first-of-type": {
      paddingLeft: "50px",
      borderRadius: "5px 0 0 5px",
      width: "30%",
    },
    "& > th:last-of-type": {
      paddingRight: "35px",
      borderRadius: "0 5px 5px 0",
    },
  },
  mobileCell: {
    borderRadius: "5px",
  },
  mobileGrid: {
    padding: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  email: {
    marginBottom: 9,
  },
}));

export default function UsersTable(props) {
  const { users, dealerFilter, setOpenEditUser, setSelectedUser } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const userRows = [];

  users.forEach((dealer) => {
    const dealerName = Object.keys(dealer)[0];
    dealer[dealerName].forEach((account) => {
      userRows.push({ email: account, dealer: dealerName });
    });
  });

  const filteredUsers = dealerFilter
    ? userRows.filter((user) => user.dealer === dealerFilter)
    : userRows;

  useEffect(() => {
    setPage(0);
  }, [dealerFilter]);

  useEffect(() => {
    if (filteredUsers.length / rowsPerPage < page) {
      setPage(0);
    }
  }, [filteredUsers.length, rowsPerPage, page]);

  return (
    <>
      <TableContainer className={classes.root}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {!isMobile && (
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Dealer</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            <TableRow></TableRow>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, idx) => {
                return (
                  <StyledTableRow key={user.email}>
                    {isMobile ? (
                      <TableCell className={classes.mobileCell} colSpan={11}>
                        <Box
                          className={classes.mobileGrid}
                          onClick={() => {
                            setSelectedUser(user);
                            setOpenEditUser(true);
                          }}
                        >
                          <Box>
                            <Typography className={classes.email} variant="h2">
                              {user.email}
                            </Typography>
                            <Typography>
                              <strong>Dealer: </strong>
                              {user.dealer}
                            </Typography>
                          </Box>
                          <IconButton>
                            <EditRounded />
                          </IconButton>
                        </Box>
                      </TableCell>
                    ) : (
                      <>
                        <TableCell
                          component="th"
                          padding="none"
                          scope="row"
                          align="left"
                        >
                          {user.email}
                        </TableCell>
                        <TableCell align="left">{user.dealer}</TableCell>
                        <TableCell align="right">
                          <Button
                            className="table-button"
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setSelectedUser(user);
                              setOpenEditUser(true);
                            }}
                          >
                            Edit User
                          </Button>
                        </TableCell>
                      </>
                    )}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={Math.floor(filteredUsers.length / rowsPerPage) < page ? 0 : page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
