const brandNames = {
  aprilia: "Aprilia",
  motoGuzzi: "Moto Guzzi",
  vespa: "Vespa",
  piaggio: "Piaggio",
  pgo: "PGO",
  lifan: "Lifan",
  znen: "Znen",
  torrot: "Torrot",
  italjet: "Italjet",
  sym: "SYM",
  lambretta: "Lambretta",
  hanway: "Hanway",
  keeway: "Keeway",
  motoMorini: "Moto Morini",
  bsa: "BSA",
};

export default brandNames;
