import React, { useState, useEffect } from "react";
import axios from "axios";
import pdf from "../../../assets/pdf.svg";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";

import brandNames from "../../../util/brands";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "800px",
  },
  circularProgress: {
    position: "absolute",
  },
  header: {
    padding: "10px 10px 18px",
  },
  gridItem: {
    padding: "8px 10px",
  },
  fieldName: {
    ...theme.typography.h4,
    padding: "5px 0",
  },
  formControl: {
    width: "100%",
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  select: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
  },
  imageGridContainer: {
    padding: "8px 4px",
  },
  imageGridItem: {
    padding: "0px 6px 6px",
    width: "131px",
    height: "125px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "69px",
      height: "69px",
    },
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.lightGrey}`,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& a": {
      width: "100%",
      height: "100%",
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "5px",
    },
  },
  partsGrid: {
    marginTop: 5,
    "& > .MuiGrid-item": {
      paddingBottom: 10,
    },
    [theme.breakpoints.up("md")]: {
      "& > .MuiGrid-item:nth-of-type(odd)": {
        paddingRight: 10,
      },
      "& > .MuiGrid-item:nth-of-type(even)": {
        paddingLeft: 10,
      },
    },
  },
  partItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.common.lightGrey,
    padding: "12px 13px 10px 14px",
    borderRadius: "5px",
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.1)",
    flexGrow: 1,
  },
  partNumContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  partLabel: {
    ...theme.typography.h4,
    paddingRight: "5px",
  },
  partNum: {
    padding: 0,
    ...theme.typography.h4,
    fontWeight: 400,
  },
  partQty: {
    padding: "5px 7px",
    fontSize: "8px",
    fontWeight: "600",
    lineHeight: "12px",
    color: theme.palette.common.white,
    textAlign: "center",
    backgroundColor: theme.palette.common.black,
    width: "35px",
    borderRadius: "5px",
  },
  buttons: {
    marginTop: 10,
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
      paddingBottom: 10,
    },
  },
  cancelButton: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  button: {
    padding: "12px 30px 13px",
  },
}));

const ViewClaim = (props) => {
  const { selectedPrior, currentUser, setOpenViewClaim, priors, setPriors } =
    props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [prior, setPrior] = useState(selectedPrior);
  const [imageURLs, setImageURLs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [extension, setExtension] = useState("");
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const isAdmin = currentUser.name === process.env.REACT_APP_ADMIN_ID;

  useEffect(() => {
    let mounted = true;
    if (selectedPrior.recallPhoto) {
      axios
        .get(`/recallPhotos/${selectedPrior.claimNumber}`, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          if (mounted) {
            let name = res.headers["content-disposition"]
              .split("filename=")[1]
              .replace(/"/g, "")
              .split(".");
            setExtension(name[name.length - 1]);
            const a = window.URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            document.querySelector("#recallPhotoLink").href = a;
          }
        })
        .catch((e) => console.log(e));
    }

    if (selectedPrior.images > 0) {
      const imgURLArray = [];
      for (let index = 0; index < selectedPrior.images; index++) {
        axios.defaults.headers.common["Authorization"] = localStorage.Token;
        axios
          .get(`/images/${selectedPrior.id}/${index}`, {
            responseType: "arraybuffer",
          })
          .then((res) => {
            const name = res.headers["content-disposition"]
              .split("filename=")[1]
              .replace(/"/g, "");

            const url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: res.headers["content-type"],
              })
            );
            imgURLArray.push({ [name]: url });

            setImageURLs([...imgURLArray]);
          })
          .catch((e) => console.log(e));
      }
    }
    return () => {
      mounted = false;
    };
  }, [selectedPrior]);

  const handleApprove = () => {
    axios
      .patch(`/admin/prior/${selectedPrior.id}`, {
        status: "Approved",
        comments: prior.comments,
      })
      .then(() => {
        const updatedPriors = [
          ...priors.slice(0, priors.indexOf(selectedPrior)),
          { ...selectedPrior, ...prior, status: "Approved" },
          ...priors.slice(priors.indexOf(selectedPrior) + 1),
        ];
        setPriors(updatedPriors);
        setOpenSuccessSnackbar(true);
        setPrior({ ...prior, status: "Approved" });
      })
      .catch((e) => console.log(e));
  };

  const handleDecline = () => {
    axios
      .patch(`/admin/prior/${selectedPrior.id}`, {
        status: "Declined",
        comments: prior.comments,
      })
      .then(() => {
        const updatedPriors = [
          ...priors.slice(0, priors.indexOf(selectedPrior)),
          { ...selectedPrior, ...prior, status: "Declined" },
          ...priors.slice(priors.indexOf(selectedPrior) + 1),
        ];
        setPriors(updatedPriors);
        setOpenSuccessSnackbar(true);
        setPrior({ ...prior, status: "Declined" });
      })
      .catch((e) => console.log(e));
  };

  const handlePending = () => {
    axios
      .patch(`/admin/prior/${selectedPrior.id}`, {
        status: "Pending",
        comments: prior.comments,
      })
      .then(() => {
        const updatedPriors = [
          ...priors.slice(0, priors.indexOf(selectedPrior)),
          { ...selectedPrior, ...prior, status: "Pending" },
          ...priors.slice(priors.indexOf(selectedPrior) + 1),
        ];
        setPriors(updatedPriors);
        setOpenSuccessSnackbar(true);
        setPrior({ ...prior, status: "Pending" });
      })
      .catch((e) => console.log(e));
  };

  const handleComments = () => {
    setLoading(true);
    axios
      .patch(`/admin/prior/${selectedPrior.id}`, {
        comments: prior.comments,
      })
      .then(() => {
        const updatedPriors = [
          ...priors.slice(0, priors.indexOf(selectedPrior)),
          { ...selectedPrior, ...prior },
          ...priors.slice(priors.indexOf(selectedPrior) + 1),
        ];
        setPriors(updatedPriors);
        setLoading(false);
        setOpenSuccessSnackbar(true);
      })
      .catch((e) => console.log(e));
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenViewClaim(false);
  };

  const handleCancel = () => {
    setOpenViewClaim(false);
  };

  return !selectedPrior ? (
    <Typography style={{ paddingRight: "40px" }}>404 Not found</Typography>
  ) : (
    <React.Fragment>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          View Claim | {currentUser?.name}
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          {isAdmin
            ? "View claim and set claim status"
            : "View submitted claim information"}
        </Typography>
      </Box>
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={6} className={classes.gridItem}>
          <Typography className={classes.fieldName}>Date of Failure</Typography>
          <Typography>
            {prior.dateOfFailure
              ? format(new Date(prior.dateOfFailure), "dd/MM/yyyy") || ""
              : "-"}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography className={classes.fieldName}>Customer Name</Typography>
          <Typography>{prior.customerName || "-"}</Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography className={classes.fieldName}>Mileage (km)</Typography>
          <Typography>{prior.mileage}</Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography className={classes.fieldName}>Brand</Typography>
          <Typography>{brandNames[prior.brand] || "-"}</Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography className={classes.fieldName}>Model</Typography>
          <Typography>{prior.model || "-"}</Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography className={classes.fieldName}>VIN #</Typography>
          <Typography>{prior.vin}</Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <Typography className={classes.fieldName}>Date of Sale</Typography>
          <Typography>
            {prior.dateOfSale
              ? format(new Date(prior.dateOfSale), "dd/MM/yyyy") || ""
              : "-"}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Typography className={classes.fieldName}>
            Symptoms/Customer Complaint
          </Typography>
          <Typography>{prior.symptoms || "-"}</Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Typography className={classes.fieldName}>
            Diagnosis - Cause of failure
          </Typography>
          <Typography>{prior.diagnosis || "-"}</Typography>
        </Grid>

        {/* IMAGES */}
        {prior.images > 0 && (
          <Grid item xs={12}>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
              className={classes.imageGridContainer}
            >
              {prior.images !== imageURLs.length ? (
                <div className={classes.root}>
                  <CircularProgress />
                </div>
              ) : (
                <Grid container className={classes.imageGrid}>
                  {prior.images > 0 &&
                    imageURLs.map((image, idx) => {
                      return (
                        <Grid
                          item
                          key={idx}
                          xs={4}
                          md={2}
                          className={classes.imageGridItem}
                        >
                          <Grid container className={classes.imageContainer}>
                            <a href={Object.values(image)} download="Download">
                              <img
                                alt="uploaded"
                                src={
                                  Object.keys(image)[0].split(".").pop() ===
                                  "pdf"
                                    ? pdf
                                    : Object.values(image)
                                }
                                className={classes.image}
                              />
                            </a>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {/* PARTS */}
        <Grid
          item
          container
          xs={12}
          className={classes.gridItem}
          justify="space-between"
        >
          <Typography className={classes.fieldName}>Parts</Typography>

          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.partsGrid}
          >
            {selectedPrior.partNumbers &&
            Object.keys(selectedPrior.partNumbers).length > 0 ? (
              Object.keys(selectedPrior.partNumbers).map((partNumber, idx) => (
                <Grid
                  key={idx}
                  item
                  xs={12}
                  md={6}
                  container
                  alignItems="center"
                >
                  <Box className={classes.partItem}>
                    <Box
                      style={{ flexGrow: 1 }}
                      className={classes.partNumContainer}
                    >
                      <Typography className={classes.partLabel}>
                        Part Number:
                      </Typography>
                      <Typography className={classes.partNum}>
                        {selectedPrior.partNumbers[`part${idx}`]}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.partQty}>
                        {String(selectedPrior.quantity[`qty${idx}`]).padStart(
                          2,
                          "0"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography>-</Typography>
            )}
          </Grid>
        </Grid>

        {prior.status === "Completed" && (
          <>
            {prior.recallPhoto && (
              <Grid item className={classes.gridItem} xs={12}>
                <a
                  id="recallPhotoLink"
                  download={`Recall Photo.${extension}`}
                  href={"!#"}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={prior.recallPhoto ? false : true}
                    startIcon={
                      <SaveAltRoundedIcon style={{ fontSize: "15px" }} />
                    }
                  >
                    Download Recall Photo
                  </Button>
                </a>
              </Grid>
            )}
          </>
        )}

        {prior.status === "Completed" && (
          <>
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <Typography className={classes.fieldName}>
                Dealer Comments
              </Typography>
              <Typography>{prior.claimComments || "-"}</Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem}>
              {isAdmin ? (
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    htmlFor="tnz-comments"
                    className={classes.fieldName}
                  >
                    TNZ Comments
                  </Typography>
                  <TextField
                    id="tnz-comments"
                    variant="outlined"
                    fullWidth
                    InputProps={{ className: classes.textInput }}
                    multiline
                    rows={5}
                    value={prior.comments}
                    onChange={(e) =>
                      setPrior({ ...prior, comments: e.target.value })
                    }
                  />
                </FormControl>
              ) : (
                <>
                  <Typography className={classes.fieldName}>
                    TNZ Comments
                  </Typography>
                  <Typography>{prior.comments || "-"}</Typography>
                </>
              )}
            </Grid>
          </>
        )}

        {prior.status !== "Completed" && isAdmin && (
          <>
            <Grid item className={classes.gridItem} xs={12}>
              <FormControl className={classes.formControl}>
                <Typography
                  component="label"
                  htmlFor="comments"
                  className={classes.fieldName}
                >
                  Comments
                </Typography>
                <TextField
                  id="comments"
                  variant="outlined"
                  InputProps={{ className: classes.textInput }}
                  fullWidth
                  multiline
                  rows={5}
                  value={prior.comments}
                  onChange={(e) =>
                    setPrior({ ...prior, comments: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid item className={classes.gridItem} xs={12}>
              <Grid
                container
                className={classes.buttons}
                justify="space-between"
              >
                {!isMobile && (
                  <Grid item style={{ flexGrow: 1 }}>
                    <Button
                      className={classes.button}
                      style={{ width: "100%" }}
                      color="primary"
                      variant="contained"
                      onClick={handleApprove}
                    >
                      Approve
                    </Button>
                  </Grid>
                )}

                <Grid item style={{ flexGrow: 1 }}>
                  <Button
                    className={classes.button}
                    style={{ width: "100%" }}
                    color="primary"
                    variant="contained"
                    onClick={handlePending}
                  >
                    Pending
                  </Button>
                </Grid>

                <Grid item style={{ flexGrow: 1 }}>
                  <Button
                    className={classes.button}
                    style={{
                      backgroundColor: theme.palette.common.mediumLightGrey,
                      width: "100%",
                    }}
                    variant="contained"
                    onClick={handleDecline}
                  >
                    Decline
                  </Button>
                </Grid>

                {isMobile && (
                  <Grid item style={{ flexGrow: 1 }} xs={12}>
                    <Button
                      className={classes.button}
                      endIcon={
                        <CheckRoundedIcon style={{ fontSize: "10px" }} />
                      }
                      style={{ width: "100%" }}
                      color="primary"
                      variant="contained"
                      onClick={handleApprove}
                    >
                      Approve
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {prior.status === "Completed" && isAdmin && (
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            className={classes.gridItem}
          >
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              className={classes.buttons}
            >
              <Button className={classes.cancelButton} onClick={handleCancel}>
                Cancel
              </Button>

              <Button
                className={classes.button}
                disabled={loading || openSuccessSnackbar}
                color="primary"
                variant="contained"
                onClick={handleComments}
                startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
              >
                Save changes
                {loading && (
                  <CircularProgress
                    size={20}
                    className={classes.circularProgress}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={openSuccessSnackbar}
        onClose={handleCloseSuccessSnackbar}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          style={{ alignItems: "center" }}
        >
          Claim succesfully edited!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ViewClaim;
