import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 26,
  },
  select: {
    backgroundColor: theme.palette.common.white,
    minWidth: 184,
    marginTop: 9,
    border: `1px solid ${theme.palette.common.black}`,
    "& .MuiSelect-root": {
      padding: "13px 32px 12px 20px",
    },
  },
  menuItemText: {
    fontSize: "10px",
    paddingRight: "10px",
  },
  button: {
    padding: "13px 40px",
    marginLeft: 7,
  },
}));

export default function AccountsHeader(props) {
  const {
    page,
    dealers,
    dealerFilter,
    setDealerFilter,
    setOpenAddDealer,
    setOpenAddUser,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const sortedDealers = dealers.sort((d1, d2) => {
    return d1.name.toUpperCase() > d2.name.toUpperCase()
      ? 1
      : d1.name.toUpperCase() < d2.name.toUpperCase()
      ? -1
      : 0;
  });

  const handleChange = (e) => {
    setDealerFilter(e.target.value);
  };

  return (
    <>
      {!isMobile && (
        <Box className={classes.header}>
          <Box className={classes.selectContainer}>
            <Typography style={{ fontWeight: 600 }}>
              Find available dealers
            </Typography>
            <Select
              displayEmpty
              disableUnderline
              variant="filled"
              value={dealerFilter}
              onChange={handleChange}
              className={classes.select}
              inputProps={{ "aria-label": "Select dealer" }}
            >
              <MenuItem value={""} disabled>
                <em>Select a dealer:</em>
              </MenuItem>
              <MenuItem key={"all"} value={""}>
                <Typography className={classes.menuItemText}>
                  View all
                </Typography>
              </MenuItem>
              {dealers &&
                sortedDealers.map((dealer) => {
                  return page === "Users" ? (
                    <MenuItem key={dealer._id} value={dealer.name}>
                      <Typography className={classes.menuItemText}>
                        {dealer.name}
                      </Typography>
                    </MenuItem>
                  ) : (
                    dealer.name !== process.env.REACT_APP_ADMIN_ID && (
                      <MenuItem key={dealer._id} value={dealer.name}>
                        <Typography className={classes.menuItemText}>
                          {dealer.name}
                        </Typography>
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          </Box>
          <Box>
            {page === "Dealers" ? (
              <Button
                onClick={() => setOpenAddDealer(true)}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                Create New Dealer
              </Button>
            ) : (
              <Button
                onClick={() => setOpenAddUser(true)}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                Create New User
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
