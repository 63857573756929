import React, { useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";

import brandNames from "../../../util/brands";
import { Divider } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "500px",
  },
  error: {
    color: theme.palette.secondary.light,
    marginBottom: 0,
  },
  header: {
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  rows: {
    "&>:last-child": {
      "& hr": {
        backgroundColor: theme.palette.common.mediumDarkGrey,
      },
    },
  },
  formContainer: {
    padding: "10px 0 0",
    "& hr": {
      marginTop: "13px",
    },
  },
  formItem: {
    padding: "3px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "3px 5px",
    },
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    padding: "0px 0px 5px",
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 5,
    paddingTop: 13,
    paddingBottom: 12,
  },
  helperText: {
    marginBottom: "5px",
  },
  snackbar: {
    alignItems: "center",
  },
}));

export default function EditThroughputReport(props) {
  const { selectedReport, setOpenEditReport, rows, setRows, dealers } = props;
  const classes = useStyles();
  const [throughputs, setThroughputs] = useState(selectedReport.data);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const brands = dealers.find(
    (dealer) => dealer.name === selectedReport.dealerName
  ).brands;
  const newThroughputs = [];
  Object.keys(brands).forEach((key) => {
    if (brands[key] === true) {
      const existingThroughput = throughputs.find((throughput) => {
        return throughput.brand === key;
      });
      if (!existingThroughput) {
        newThroughputs.push({ brand: key, unitIntake: 0, jobsCompleted: 0 });
      } else {
        newThroughputs.push(existingThroughput);
      }
    }
  });

  const unitTotal = newThroughputs.reduce(
    (acc, curr) => acc + curr.unitIntake,
    0
  );
  const jobsTotal = newThroughputs.reduce(
    (acc, curr) => acc + curr.jobsCompleted,
    0
  );

  const handleCloseErrorSnackbar = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenEditReport(false);
  };

  const handleChangeUnitIntake = (e, index) => {
    newThroughputs[index].unitIntake = Number(e.target.value);
    setThroughputs(newThroughputs);
  };

  const handleChangeJobsCompleted = (e, index) => {
    newThroughputs[index].jobsCompleted = Number(e.target.value);
    setThroughputs(newThroughputs);
  };

  const handleSubmit = () => {
    const newReport = { ...selectedReport, data: newThroughputs };
    const formattedMonth = new Date(selectedReport.month);
    axios
      .post("/reports/throughputs", { ...newReport, month: formattedMonth })
      .then(() => {
        const updatedRows = [
          ...rows.slice(0, rows.indexOf(selectedReport)),
          newReport,
          ...rows.slice(rows.indexOf(selectedReport) + 1),
        ];
        setRows(updatedRows);
        setOpenSuccessSnackbar(true);
      })
      .catch((e) => {
        console.log(e);
        setOpenErrorSnackbar(true);
      });
  };

  return (
    <Grid className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          Edit a Report
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          Edit a throughtputs report for a given month
        </Typography>
      </Box>

      <Grid item xs={12} className={classes.gridItem}>
        <FormHelperText className={classes.helperText} id="unit-helper-text">
          Throughput example: A customer comes to a workshop (workshop order
          opened), got their vehicle serviced, repaired or even upgraded (the
          workshop order is closed/completed), that counts as 1 count.
        </FormHelperText>
        <FormHelperText className={classes.helperText} id="jobs-helper-text">
          Only record jobs completed with different VIN numbers; if a bike is in
          more than once, record intake only but reduce from completed. Please
          only record for the brands you represent.
        </FormHelperText>
      </Grid>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography variant="h3">
          {format(new Date(selectedReport.month), "MMMM yyyy")} |{" "}
          {selectedReport.dealerName}
        </Typography>
      </Grid>

      <Grid container className={classes.rows}>
        {newThroughputs.map((report, index) => {
          return (
            <Grid
              item
              xs={12}
              key={index}
              className={classes.formContainer}
              container
            >
              <Grid item xs={12} className={classes.formItem}>
                <Typography variant="h3" className={classes.brandName}>
                  {brandNames[report.brand]}
                </Typography>
              </Grid>

              <Grid item xs={6} className={classes.formItem}>
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    className={classes.inputLabel}
                    htmlFor="unit-intake"
                    variant="body2"
                  >
                    Unit Intake
                  </Typography>
                  <TextField
                    type="number"
                    value={report.unitIntake}
                    inputProps={{ className: classes.textInput }}
                    InputProps={{ className: classes.fieldset }}
                    id="unit-intake"
                    aria-describedby="unit-helper-text"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChangeUnitIntake(e, index)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} className={classes.formItem}>
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    className={classes.inputLabel}
                    htmlFor="jobs-completed"
                    variant="body2"
                  >
                    Jobs Completed
                  </Typography>
                  <TextField
                    type="number"
                    value={report.jobsCompleted}
                    aria-describedby="jobs-helper-text"
                    inputProps={{ className: classes.textInput }}
                    InputProps={{ className: classes.fieldset }}
                    id="jobs-completed"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChangeJobsCompleted(e, index)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.formItem}>
                <Divider />
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{ paddingTop: "10px" }}
        container
      >
        <Grid item xs={6}>
          <Typography variant="h4">Total Unit Intake</Typography>
          <Typography variant="body1">{unitTotal}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4">Total Jobs Completed</Typography>
          <Typography variant="body1">{jobsTotal}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.gridItem}>
        <Button
          fullWidth
          disabled={openSuccessSnackbar}
          type="submit"
          onClick={handleSubmit}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
        >
          Submit Report
        </Button>
      </Grid>

      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSuccessSnackbar}
      >
        <Alert
          className={classes.snackbar}
          onClose={handleCloseSuccessSnackbar}
          severity="success"
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Something went wrong, please try again
        </Alert>
      </Snackbar>
    </Grid>
  );
}
