import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import CheckCircleRounded from "@material-ui/icons/CheckCircleRounded";

import brandNames from "../../../util/brands";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "600px",
    },
  },
  circularProgress: {
    position: "absolute",
  },
  header: {
    padding: "10px 10px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px 18px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  selectContainer: {
    padding: "10px 5px 5px",
  },
  brandGridContainer: {
    paddingTop: "10px",
    paddingBottom: "5px",
    "& > label,h6": {
      paddingRight: "10px",
      paddingLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "5px",
        paddingLeft: "5px",
      },
    },
  },
  brandsGrid: {
    marginTop: 10,
    "& label": {
      display: "block",
      width: "100%",
    },
  },
  brandsGridItem: {
    padding: "0 10px 11px",
  },
  brandCheckbox: {
    width: "100%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    transition: "background-color 150ms linear",
    padding: "8px 21px",
    "&:hover": { backgroundColor: "inherit" },
  },
  select: {
    maxWidth: "85vw",
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
    "& .MuiInputBase-input": {
      ...theme.typography.body2,
    },
  },
  buttons: {
    marginTop: 10,
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      paddingBottom: 15,
    },
  },
  cancelButton: {
    paddingLeft: 0,
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  button: {
    padding: "12px 30px 13px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  error: {
    color: theme.palette.secondary.light,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const brandOptions = Object.values(brandNames);

const EditDealer = (props) => {
  const {
    currentUser,
    selectedDealer,
    setSelectedDealer,
    setOpenEditDealer,
    dealers,
    setDealers,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [all, setAll] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [dealerName, setDealerName] = useState(selectedDealer.name);
  const initBrands = {};
  Object.keys(brandNames).forEach((key) => {
    initBrands[key] = selectedDealer.brands[key] === true;
  });
  const [brands, setBrands] = useState(initBrands);

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenEditDealer(false);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  useEffect(() => {
    if (Object.values(brands).every((value) => value === true)) setAll(true);
    const newSelected = [];
    Object.keys(brands).forEach((key) => {
      if (brands[key] === true) newSelected.push(key);
    });
    setSelectedBrands(newSelected);
  }, [brands]);

  const handleDealerChange = (e) => {
    if (e.target.value === "") {
      setAll(false);
      const updatedBrands = {};
      Object.keys(brands).forEach((key) => {
        updatedBrands[key] = false;
      });
      setBrands(updatedBrands);
    } else {
      const newDealer = dealers.find(
        (dealer) => dealer.name === e.target.value
      );
      const hasEveryBrand =
        Object.values(newDealer.brands).filter((value) => value === true)
          .length === Object.values(brandNames).length;
      setAll(hasEveryBrand);
      setSelectedDealer(newDealer || {});
      setDealerName(newDealer.name);
      const newBrands = {};
      Object.keys(brandNames).forEach((key) => {
        newBrands[key] = newDealer.brands[key] === true;
      });
      setBrands(newBrands);
    }
  };

  const handleSelectBrand = (e) => {
    if (isMobile) {
      const value = e.target.value;
      let newSelected = [];
      if (value[value.length - 1] === "all") {
        if (!all) {
          setAll(true);
          newSelected = Object.keys(brands);
        } else {
          setAll(false);
        }
      } else {
        if (value.length === Object.keys(brands).length) {
          setAll(true);
        } else {
          setAll(false);
        }
        newSelected = value;
      }
      setSelectedBrands(newSelected);
      const updatedBrands = {};
      Object.keys(brands).forEach((key) => {
        updatedBrands[key] = newSelected.includes(key) ? true : false;
      });
      setBrands(updatedBrands);
    } else {
      if (e.target.name === "all") {
        setAll(!all);
        const updatedBrands = {};
        Object.keys(brands).forEach((key) => {
          updatedBrands[key] = e.target.checked;
        });
        setBrands(updatedBrands);
      } else {
        setAll(false);
        setBrands({
          ...brands,
          [e.target.name]: e.target.checked,
        });
      }
    }
  };

  const handleSubmit = () => {
    axios
      .patch("/user/dealer", { name: dealerName, brands: brands })
      .then((res) => {
        const updatedDealers = [
          ...dealers.slice(0, dealers.indexOf(selectedDealer)),
          res.data,
          ...dealers.slice(dealers.indexOf(selectedDealer) + 1),
        ];
        setDealers(updatedDealers);
        setOpenSuccessSnackbar(true);
      })
      .catch((e) => {
        setOpenErrorSnackbar(true);
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          Edit a Dealer | {currentUser?.name}
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          Edit an existing dealer account
        </Typography>
      </Box>
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <FormControl className={classes.formControl} fullWidth>
            <Typography
              component="label"
              htmlFor="select-dealer"
              className={classes.inputLabel}
            >
              Select a Dealer
            </Typography>
            <Select
              style={{ marginTop: 0 }}
              variant="filled"
              className={classes.select}
              value={selectedDealer.name || ""}
              disableUnderline
              fullWidth
              id="select-brand"
              onChange={handleDealerChange}
              inputProps={{
                "aria-label": "Select dealer",
              }}
            >
              <MenuItem value={""} disabled>
                <em>Select dealer:</em>
              </MenuItem>
              {dealers.map((dealer, i) => {
                return (
                  <MenuItem key={i} value={dealer.name}>
                    {dealer.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.brandGridContainer}>
          <Typography
            component="label"
            htmlFor="brands"
            className={classes.inputLabel}
            style={{ paddingTop: "10px" }}
          >
            Select Brands
          </Typography>
          <Typography variant="subtitle2">
            Select all applicable brands for dealer account
          </Typography>

          {isMobile ? (
            <Grid className={classes.selectContainer}>
              <Select
                id="brands"
                multiple
                fullWidth
                className={classes.select}
                onChange={handleSelectBrand}
                value={selectedBrands}
                renderValue={(selectedBrands) => {
                  return selectedBrands
                    .map((brand) => brandNames[brand])
                    .join(", ");
                }}
              >
                <MenuItem value="all">
                  <Typography
                    variant="body1"
                    style={{ padding: "9px 0", flexGrow: 1 }}
                  >
                    All Brands
                  </Typography>
                  <Checkbox
                    name="all"
                    checked={all}
                    icon={<></>}
                    checkedIcon={
                      <CheckCircleRounded
                        style={{
                          fontSize: "15px",
                          color: theme.palette.common.green,
                        }}
                      />
                    }
                  />
                </MenuItem>
                {brandOptions.map((brand, i) => {
                  const brandKey = Object.keys(brandNames)[i];
                  return (
                    <MenuItem key={brandKey} value={brandKey}>
                      <Typography
                        variant="body1"
                        style={{ padding: "9px 0", flexGrow: 1 }}
                      >
                        {brand}
                      </Typography>
                      <Checkbox
                        name={brandKey}
                        checked={selectedBrands.includes(brandKey) || false}
                        icon={<></>}
                        checkedIcon={
                          <CheckCircleRounded
                            style={{
                              fontSize: "15px",
                              color: theme.palette.common.green,
                            }}
                          />
                        }
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          ) : (
            <Grid container className={classes.brandsGrid}>
              <Grid item md={2} key="all" className={classes.brandsGridItem}>
                <input
                  hidden
                  type="checkbox"
                  id="all"
                  name="all"
                  checked={all}
                  onChange={handleSelectBrand}
                />
                <label htmlFor="all">
                  <Button
                    component="span"
                    variant="contained"
                    className={classes.brandCheckbox}
                    disableRipple
                    style={
                      all
                        ? {
                            backgroundColor: theme.palette.common.black,
                            color: theme.palette.common.white,
                          }
                        : {
                            backgroundColor: theme.palette.common.lightGrey,
                            color: theme.palette.common.black,
                          }
                    }
                  >
                    All Brands
                  </Button>
                </label>
              </Grid>
              {brandOptions.map((brand, i) => {
                const brandKey = Object.keys(brandNames)[i];
                return (
                  <Grid item md={2} key={i} className={classes.brandsGridItem}>
                    <input
                      hidden
                      type="checkbox"
                      id={brandKey}
                      name={brandKey}
                      checked={all ? true : brands[brandKey] ? true : false}
                      onChange={handleSelectBrand}
                    />
                    <label htmlFor={brandKey}>
                      <Button
                        component="span"
                        variant="contained"
                        style={
                          brands[brandKey] === true
                            ? {
                                backgroundColor: theme.palette.common.black,
                                color: theme.palette.common.white,
                              }
                            : {
                                backgroundColor: theme.palette.common.lightGrey,
                                color: theme.palette.common.black,
                              }
                        }
                        disableRipple
                        className={classes.brandCheckbox}
                      >
                        {brand}
                      </Button>
                    </label>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          xs={12}
          className={classes.gridItem}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            className={classes.buttons}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                Please make sure all information provided is correct before
                saving.
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} container justify="flex-end">
              <Button
                disabled={openSuccessSnackbar}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
              >
                Save Information
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={openSuccessSnackbar}
        onClose={handleCloseSuccessSnackbar}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          style={{ alignItems: "center" }}
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Something went wrong, please try again
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default EditDealer;
