import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import DealersTable from "../components/accounts/DealersTable";
import AccountsHeader from "../components/accounts/AccountsHeader";
import AccountsHeaderMobile from "../components/accounts/AccountsHeaderMobile";
import MobileHeaderSubMenu from "../components/menus/MobileHeaderSubMenu";
import ModalBase from "../components/modals/ModalBase";
import AddDealer from "../components/modals/accounts/AddDealer";
import EditDealer from "../components/modals/accounts/EditDealer";

const Dealers = (props) => {
  const {
    selectedSubTab,
    setSelectedSubTab,
    MobileHeaderWithContext,
    currentUser,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [dealerFilter, setDealerFilter] = useState("");
  const [dealers, setDealers] = useState([]);
  const [openAddDealer, setOpenAddDealer] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState({});
  const [openEditDealer, setOpenEditDealer] = useState(false);

  useEffect(() => {
    let mounted = true;
    axios
      .get("/users")
      .then((res) => {
        if (mounted) setDealers(res.data.users);
      })
      .catch((e) => console.log(e));

    return () => (mounted = false);
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <MobileHeaderWithContext>
            <AccountsHeaderMobile
              page="Dealers"
              dealers={dealers}
              dealerFilter={dealerFilter}
              setDealerFilter={setDealerFilter}
              setOpenAddDealer={setOpenAddDealer}
            />
          </MobileHeaderWithContext>

          <MobileHeaderSubMenu
            category={"Accounts"}
            selectedSubTab={selectedSubTab}
            setSelectedSubTab={setSelectedSubTab}
          />
        </>
      ) : (
        <AccountsHeader
          page="Dealers"
          dealers={dealers}
          dealerFilter={dealerFilter}
          setDealerFilter={setDealerFilter}
          setOpenAddDealer={setOpenAddDealer}
        />
      )}

      <DealersTable
        dealers={dealers}
        dealerFilter={dealerFilter}
        setOpenEditDealer={setOpenEditDealer}
        setSelectedDealer={setSelectedDealer}
      />
      <ModalBase openModal={openAddDealer} setOpenModal={setOpenAddDealer}>
        <AddDealer
          currentUser={currentUser}
          setOpenAddDealer={setOpenAddDealer}
          dealers={dealers}
          setDealers={setDealers}
        />
      </ModalBase>
      <ModalBase openModal={openEditDealer} setOpenModal={setOpenEditDealer}>
        <EditDealer
          currentUser={currentUser}
          setOpenEditDealer={setOpenEditDealer}
          selectedDealer={selectedDealer}
          setSelectedDealer={setSelectedDealer}
          dealers={dealers}
          setDealers={setDealers}
        />
      </ModalBase>
    </>
  );
};

export default Dealers;
