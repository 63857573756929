import React from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EditRounded from "@material-ui/icons/EditRounded";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import aprilia from "../../../assets/aprilia.png";
import vespa from "../../../assets/vespa.jpg";
import motoGuzzi from "../../../assets/moto guzzi.png";
import bsa from "../../../assets/bsa.png";
import brandNames from "../../../util/brands";
import getStockTotals from "../../../util/getStockTotals";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "600px",
  },
  error: {
    color: theme.palette.secondary.light,
    marginBottom: 0,
  },
  header: {
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  rows: {
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 5px",
    },
    "&>:last-child": {
      "& hr": {
        display: "none",
      },
    },
  },
  formContainer: {
    width: "100%",
    padding: "10px 0 0",
    "&:not(:first-child)": {
      paddingTop: "15px",
    },
  },
  accordion: {
    width: "100%",
  },
  accordionTitle: {
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 5px 0 10px",
    },
    "& div, .Mui-expanded": {
      margin: "10px 0",
    },
  },
  brandName: {
    paddingLeft: "8px",
  },
  accordionInner: {
    flexDirection: "column",
    padding: "0 10px 10px",
  },
  inputLabel: {
    padding: "0px 0px 5px",
  },
  table: {
    backgroundColor: theme.palette.common.lightGrey,
    borderRadius: "5px",
    padding: "8px",
    margin: "8px 0",
  },
  headerRow: {
    "& th": {
      ...theme.typography.h4,
      padding: "5px",
    },
  },
  tableRow: {
    "& td": {
      padding: "5px",
    },
  },
  button: {
    padding: "10px 15px",
    marginTop: "5px",
  },
  totals: {
    paddingTop: "10px",
    "&>div": {
      paddingTop: "5px",
    },
  },
}));

export default function ViewStockReport(props) {
  const {
    selectedReport,
    isAdmin,
    setOpenEditReport,
    setOpenViewReport,
    stockReportBrands,
    dealers,
  } = props;
  const classes = useStyles();
  const stockReportData = selectedReport.data;
  const totals = getStockTotals(stockReportData);

  const dealer = dealers.find((dealer) => {
    return dealer.name === selectedReport.dealerName;
  });
  const dealerBrands = dealer.brands;
  const userBrands = stockReportBrands?.brands;
  const formattedData = {};

  Object.keys(userBrands).forEach((key) => {
    if (userBrands[key] === true && dealerBrands[key] === true) {
      if (stockReportData[key]) {
        formattedData[key] = stockReportData[key];
      } else {
        formattedData[key] = {
          unitsSold: [],
          dealerStockUnits: [],
          unitsOnConsignment: [],
          dealerDemoUnits: [],
        };
      }
    }
  });

  return (
    <Grid className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          View a Report
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          View a stock report for a given month
        </Typography>
      </Box>

      <Grid item xs={12} className={classes.gridItem}>
        <Typography variant="h3">
          {format(new Date(selectedReport.month), "MMMM yyyy")} |{" "}
          {selectedReport.dealerName}
        </Typography>
      </Grid>

      {isAdmin && (
        <Grid item xs={12} className={classes.gridItem}>
          <Button
            className={classes.button}
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<EditRounded style={{ fontSize: "14px" }} />}
            onClick={() => {
              setOpenEditReport(true);
              setOpenViewReport(false);
            }}
          >
            Edit Report
          </Button>
        </Grid>
      )}

      <Grid container className={classes.rows}>
        {Object.keys(formattedData).map((key, index) => {
          return (
            <Grid
              item
              xs={12}
              key={index}
              className={classes.formContainer}
              container
            >
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  className={classes.accordionTitle}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <img
                    src={
                      key === "aprilia"
                        ? aprilia
                        : key === "vespa"
                        ? vespa
                        : key === "bsa"
                        ? bsa
                        : motoGuzzi
                    }
                    alt={
                      key === "aprilia"
                        ? "aprilia"
                        : key === "vespa"
                        ? "vespa"
                        : key === "bsa"
                        ? bsa
                        : "moto guzzi"
                    }
                    height="20px"
                  />
                  <Typography variant="h3" className={classes.brandName}>
                    {brandNames[key]}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className={classes.accordionInner}>
                  <Grid container className={classes.tablesGrid}>
                    <Grid item xs={12} className={classes.table}>
                      <Typography className={classes.inputLabel} variant="h3">
                        Units Sold:
                      </Typography>
                      {formattedData[key].unitsSold.length > 0 ? (
                        <Table>
                          <TableHead>
                            <TableRow className={classes.headerRow}>
                              <TableCell align="left">Model</TableCell>
                              <TableCell align="left">Colour</TableCell>
                              <TableCell align="left">VIN</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {formattedData[key].unitsSold.map((item, i) => {
                              return (
                                <TableRow className={classes.tableRow} key={i}>
                                  <TableCell>{item.model}</TableCell>
                                  <TableCell>{item.colour}</TableCell>
                                  <TableCell>{item.vin}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : (
                        <Typography variant="body2">None</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} className={classes.table}>
                      <Typography className={classes.inputLabel} variant="h3">
                        Dealer Stock Units:
                      </Typography>
                      {formattedData[key].dealerStockUnits.length > 0 ? (
                        <Table>
                          <TableHead>
                            <TableRow className={classes.headerRow}>
                              <TableCell align="left">Model</TableCell>
                              <TableCell align="left">Colour</TableCell>
                              <TableCell align="left">VIN</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {formattedData[key].dealerStockUnits.map(
                              (item, i) => {
                                return (
                                  <TableRow
                                    className={classes.tableRow}
                                    key={i}
                                  >
                                    <TableCell>{item.model}</TableCell>
                                    <TableCell>{item.colour}</TableCell>
                                    <TableCell>{item.vin}</TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      ) : (
                        <Typography variant="body2">None</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} className={classes.table}>
                      <Typography className={classes.inputLabel} variant="h3">
                        Units On Consignment:
                      </Typography>
                      {formattedData[key].unitsOnConsignment.length > 0 ? (
                        <Table>
                          <TableHead>
                            <TableRow className={classes.headerRow}>
                              <TableCell align="left">Model</TableCell>
                              <TableCell align="left">Colour</TableCell>
                              <TableCell align="left">VIN</TableCell>
                              <TableCell align="left">ODO</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {formattedData[key].unitsOnConsignment.map(
                              (item, i) => {
                                return (
                                  <TableRow
                                    className={classes.tableRow}
                                    key={i}
                                  >
                                    <TableCell>{item.model}</TableCell>
                                    <TableCell>{item.colour}</TableCell>
                                    <TableCell>{item.vin}</TableCell>
                                    <TableCell>{item.odo}</TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      ) : (
                        <Typography variant="body2">None</Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} className={classes.table}>
                      <Typography className={classes.inputLabel} variant="h3">
                        Dealer Demo Units:
                      </Typography>
                      {formattedData[key].dealerDemoUnits.length > 0 ? (
                        <Table>
                          <TableHead>
                            <TableRow className={classes.headerRow}>
                              <TableCell align="left">Model</TableCell>
                              <TableCell align="left">Colour</TableCell>
                              <TableCell align="left">VIN</TableCell>
                              <TableCell align="left">ODO</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {formattedData[key].dealerDemoUnits.map(
                              (item, i) => {
                                return (
                                  <TableRow
                                    className={classes.tableRow}
                                    key={i}
                                  >
                                    <TableCell>{item.model}</TableCell>
                                    <TableCell>{item.colour}</TableCell>
                                    <TableCell>{item.vin}</TableCell>
                                    <TableCell>{item.odo}</TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      ) : (
                        <Typography variant="body2">None</Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.gridItem}
        style={{ paddingTop: 10, paddingBottom: 10 }}
        container
      >
        <Grid item container className={classes.totals} xs={12}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Total Units Sold: </strong>
              {totals.totalUnitsSold}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Total Dealer Stock Units: </strong>
              {totals.totalDealerStockUnits}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Total Units On Consignment: </strong>
              {totals.totalUnitsOnConsignment}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              <strong>Total Dealer Demo Units: </strong>
              {totals.totalDealerDemoUnits}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
