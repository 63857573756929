import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditRounded from "@material-ui/icons/EditRounded";

const useStyles = makeStyles((theme) => ({
  cell: {
    borderRadius: "5px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "15px",
    padding: "15px",
  },
  dealer: {
    marginBottom: 9,
  },
  status: {
    fontWeight: 600,
    minWidth: "65px",
    width: "fit-content",
    height: "fit-content",
    padding: "5px 6px",
    marginBottom: 9,
    borderRadius: "5px",
    alignSelf: "top",
    textAlign: "center",
    justifySelf: "end",
  },
  edit: {
    gridRowEnd: "span 2",
    justifySelf: "end",
  },
  smallerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
  },
}));

export default function AdminMobileTableCell(props) {
  const { row, setSelectedPrior, setOpenViewClaim } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <TableCell className={classes.cell} colSpan={11}>
      <Box className={classes.grid}>
        <Typography className={classes.dealer} variant="h2">
          {row.dealer}
        </Typography>
        <Box
          className={classes.status}
          style={{
            backgroundColor:
              row.status === "Approved"
                ? theme.palette.common.green
                : theme.palette.common.mediumGrey,
          }}
        >
          <Typography
            variant="h5"
            style={{
              color:
                row.status === "Approved"
                  ? theme.palette.common.white
                  : theme.palette.common.black,
            }}
          >
            {row.status}
          </Typography>
        </Box>
        <Typography>
          <strong>Claim: </strong>
          {row.claimNumber}
        </Typography>
        <IconButton
          className={classes.edit}
          onClick={() => {
            setSelectedPrior(row);
            setOpenViewClaim(true);
          }}
        >
          <EditRounded />
        </IconButton>
        <Typography>
          <strong>Date Created: </strong>
          {row.createdAt}
        </Typography>
        <Typography className={classes.comments}>
          <strong>Comments: </strong>
          {row.comments}
        </Typography>
      </Box>
      <Divider light />
      <Box className={classes.smallerRow}>
        <Typography variant="subtitle1">
          <strong>VIN: </strong>
          {row.vin}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Mileage: </strong>
          {Number(row.mileage).toLocaleString()} KM
        </Typography>
        <Typography variant="subtitle1">
          <strong>Model: </strong>
          {row.model}
        </Typography>
      </Box>
    </TableCell>
  );
}
