import React from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";

import getMonthsList from "../../util/getMonthsList";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  formControl: {
    minWidth: 110,
  },
  select: {
    marginTop: "0!important",
    "& .MuiSelect-root": {
      paddingBottom: "3px",
      "& p": {
        position: "absolute",
        top: "100%",
        ...theme.typography.subtitle2,
        fontSize: "8px",
        lineHeight: "12px",
      },
    },
  },
  inputLabel: {
    transition: "none",
    transform: "none",
    top: "3px",
  },
  helperText: {
    ...theme.typography.subtitle2,
    marginTop: 0,
    fontSize: "8px",
    lineHeight: "12px",
    position: "absolute",
    top: "100%",
  },
}));

export default function ReportsHeaderMobile(props) {
  const {
    dealers,
    dealerFilter,
    setDealerFilter,
    monthFilter,
    setMonthFilter,
    isAdmin,
  } = props;
  const classes = useStyles();

  const monthsList = getMonthsList();

  const sortedDealers = dealers.sort((d1, d2) => {
    return d1.name.toUpperCase() > d2.name.toUpperCase()
      ? 1
      : d1.name.toUpperCase() < d2.name.toUpperCase()
      ? -1
      : 0;
  });

  return (
    <>
      {isAdmin && (
        <Box className={classes.selectContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.inputLabel} value="" disabled>
              <Typography variant="body1" style={{ fontWeight: 600 }}>
                Select dealer
              </Typography>
            </InputLabel>
            <Select
              id="select-dealer"
              disableUnderline
              value={dealerFilter}
              onChange={(e) => setDealerFilter(e.target.value)}
              className={classes.select}
              inputProps={{ "aria-label": "Select dealer" }}
            >
              <MenuItem key={"all"} value={""}>
                <Typography className={classes.menuItemText}>
                  View all
                </Typography>
              </MenuItem>
              {dealers &&
                sortedDealers.map((dealer) => {
                  return (
                    dealer.name !== process.env.REACT_APP_ADMIN_ID && (
                      <MenuItem key={dealer._id} value={dealer.name}>
                        <Typography className={classes.menuItemText}>
                          {dealer.name}
                        </Typography>
                      </MenuItem>
                    )
                  );
                })}
            </Select>
            <FormHelperText className={classes.helperText}>
              {!dealerFilter ? "None selected" : ""}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
      <Box className={classes.selectContainer}>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.inputLabel} value="" disabled>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              Select month
            </Typography>
          </InputLabel>
          <Select
            id="select-month"
            disableUnderline
            value={monthFilter}
            onChange={(e) => setMonthFilter(e.target.value)}
            className={classes.select}
            inputProps={{ "aria-label": "Select month" }}
          >
            <MenuItem key={"all"} value={""}>
              <Typography className={classes.menuItemText}>View all</Typography>
            </MenuItem>
            {monthsList.map((month) => {
              const formattedMonth = format(new Date(month), "MMMM yyyy");
              return (
                <MenuItem key={formattedMonth} value={formattedMonth}>
                  <Typography className={classes.menuItemText}>
                    {formattedMonth}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText className={classes.helperText}>
            {!monthFilter ? "None selected" : ""}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
}
