import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CheckCircleRounded from "@material-ui/icons/CheckCircleRounded";

import { getComparator, stableSort } from "../../util/sortFunctions";
import StyledTableHead from "./StyledTableHead";
import StyledTableToolbar from "./StyledTableToolbar";
import StyledTableRow from "../StyledTableRow";
import StyledTablePagination from "../StyledTablePagination";
import MobileTableCell from "./MobileTableCell";
import JobCompletionForm from "./JobCompletionForm";
import ModalBase from "../modals/ModalBase";
import EditClaim from "../modals/claims/EditClaim";
import ViewClaim from "../modals/claims/ViewClaim";

const headCells = [
  { id: "vin", label: "VIN #" },
  { id: "claimNumber", label: "Claim #" },
  { id: "createdAt", label: "Date Created" },
  { id: "updatedAt", label: "Last Modified" },
  { id: "mileage", label: "Mileage" },
  { id: "model", label: "Model" },
  { id: "status", label: "Status" },
  { id: "comments", label: "Comments/Order #" },
  { id: "edit", label: "Edit" },
  // { id: "download", label: "Export to XLS" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0 10px 20px",
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
}));

export default function ClaimsTable(props) {
  const { priors, setPriors, selectedSubTab, currentUser } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("claimNumber");
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openJobCompletionForm, setOpenJobCompletionForm] = useState(false);
  const [selectedPrior, setSelectedPrior] = useState({});
  const [openEditClaim, setOpenEditClaim] = useState(false);
  const [openViewClaim, setOpenViewClaim] = useState(false);

  const filteredPriors = priors.filter((prior) => {
    if (selectedSubTab === "Approved") {
      return prior.search && prior.status === "Approved";
    } else if (selectedSubTab === "Pending") {
      return prior.search && prior.status === "Pending";
    } else return prior.search;
  });

  const handleOpenJobCompletionForm = () => {
    setOpenJobCompletionForm(!openJobCompletionForm);
  };

  // const handleDownload = (claim) => {
  //   axios
  //     .get(`/downloadClaim/${claim}`, { responseType: "arraybuffer" })
  //     .then((res) => {
  //       let name = res.headers["content-disposition"]
  //         .split("filename=")[1]
  //         .replace(/"/g, "")
  //         .split(".");
  //       const anchor = document.createElement("a");
  //       const a = window.URL.createObjectURL(
  //         new Blob([res.data], {
  //           type: res.headers["content-type"],
  //         })
  //       );
  //       anchor.href = a;
  //       anchor.download = `Download.${name[name.length - 1]}`;
  //       anchor.click();
  //     })
  //     .catch((e) => console.log(e.response));
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = priors.map((n) => n.claimNumber);

      setSelectedRows(newSelecteds);
      return;
    }
    setSelectedRows([]);
  };

  const handleClick = (event, name) => {
    if (
      event.target.matches(
        ".MuiButton-root, .MuiButton-label, .MuiTouchRipple-root, .MuiButtonBase-root .MuiSvgIcon-root, .MuiButtonBase-root path"
      )
    )
      return;

    const selectedIndex = selectedRows.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [selectedSubTab]);

  useEffect(() => {
    if (filteredPriors.length / rowsPerPage < page) {
      setPage(0);
    }
  }, [filteredPriors.length, rowsPerPage, page]);

  const isSelected = (name) => selectedRows.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <StyledTableToolbar
        numSelected={selectedRows.length}
        selected={selectedRows}
        setSelected={setSelectedRows}
        priors={priors}
        setPriors={setPriors}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {!isMobile && (
            <StyledTableHead
              page="Warranty Claims"
              classes={classes}
              numSelected={selectedRows.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={priors.length}
              headCells={headCells}
            />
          )}
          <TableBody>
            <TableRow></TableRow>
            {stableSort(filteredPriors, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.claimNumber);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    onClick={(event) => handleClick(event, row.claimNumber)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    key={row.claimNumber}
                    selected={isItemSelected}
                  >
                    {isMobile ? (
                      <MobileTableCell
                        row={row}
                        isItemSelected={isItemSelected}
                        setSelectedJob={setSelectedPrior}
                        handleOpenJobCompletionForm={
                          handleOpenJobCompletionForm
                        }
                        setOpenEditClaim={setOpenEditClaim}
                        setOpenViewClaim={setOpenViewClaim}
                        labelId={labelId}
                      />
                    ) : (
                      <>
                        <TableCell align="left" className="checkbox">
                          <Checkbox
                            size="small"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.vin}
                        </TableCell>
                        <TableCell align="left">{row.claimNumber}</TableCell>
                        <TableCell align="left">{row.createdAt}</TableCell>
                        <TableCell align="left">{row.updatedAt}</TableCell>
                        <TableCell align="left">
                          {Number(row.mileage).toLocaleString()} KM
                        </TableCell>
                        <TableCell align="left">{row.model}</TableCell>
                        {row.dateOfRepair ? (
                          <TableCell align="left">{`${row.status} on ${format(
                            new Date(row.dateOfRepair),
                            "dd/MM/yyyy"
                          )}`}</TableCell>
                        ) : (
                          <TableCell align="left">{row.status}</TableCell>
                        )}
                        <TableCell align="left">{row.comments}</TableCell>
                        <TableCell align="center">
                          {row.status === "Approved" ? (
                            <Button
                              className="table-button"
                              disableElevation
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                setSelectedPrior(row);
                                handleOpenJobCompletionForm();
                              }}
                            >
                              Complete
                            </Button>
                          ) : row.status === "Completed" ? (
                            <IconButton
                              className="icon-button"
                              onClick={() => {
                                setSelectedPrior(row);
                                setOpenViewClaim(true);
                              }}
                            >
                              <CheckCircleRounded
                                style={{ color: theme.palette.common.green }}
                              />
                            </IconButton>
                          ) : (
                            <Button
                              className="table-button"
                              disableElevation
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setSelectedPrior(row);
                                setOpenEditClaim(true);
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </TableCell>
                        {/* <TableCell align="center">
                        <IconButton
                          onClick={() => handleDownload(row.claimNumber)}
                        >
                          <GetAppIcon color="secondary" />
                        </IconButton>
                      </TableCell> */}
                      </>
                    )}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination
        count={filteredPriors.length}
        rowsPerPage={rowsPerPage}
        page={Math.floor(filteredPriors.length / rowsPerPage) < page ? 0 : page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ModalBase
        openModal={openJobCompletionForm}
        setOpenModal={setOpenJobCompletionForm}
      >
        <JobCompletionForm
          selectedPrior={selectedPrior}
          handleOpenJobCompletionForm={handleOpenJobCompletionForm}
          priors={priors}
          setPriors={setPriors}
        />
      </ModalBase>
      <ModalBase openModal={openEditClaim} setOpenModal={setOpenEditClaim}>
        <EditClaim
          currentUser={currentUser}
          setOpenEditClaim={setOpenEditClaim}
          selectedPrior={selectedPrior}
          priors={priors}
          setPriors={setPriors}
        />
      </ModalBase>
      <ModalBase openModal={openViewClaim} setOpenModal={setOpenViewClaim}>
        <ViewClaim
          currentUser={currentUser}
          setOpenViewClaim={setOpenViewClaim}
          selectedPrior={selectedPrior}
        />
      </ModalBase>
    </div>
  );
}
