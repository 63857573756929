import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import brandNames from "../util/brands";

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    position: "absolute",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: 700,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: 700,
    },
  },
  heading: {
    marginBottom: 25,
  },
  filesContainer: {
    display: "flex",
    flexDirection: "column",
  },
  select: {
    marginBottom: 10,
    backgroundColor: theme.palette.common.white,
    minWidth: 317,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.black}`,
    "& .MuiSelect-root": {
      padding: "13px 32px 12px 14px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 260,
    },
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
  },
  filesListTitle: {
    paddingBottom: 9,
  },
  fileRow: {
    width: "100%",
    padding: 0,
    margin: "0 0 10px",
  },
  file: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    alignItems: "center",
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: theme.palette.common.mediumLightGrey,
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    color: theme.palette.common.black,

    wordWrap: "wrap",
  },
  fileText: {
    textDecoration: "none",
    flexGrow: 1,
    paddingTop: 13,
    paddingBottom: 12,
  },
  submitButton: {
    marginTop: 20,
    paddingTop: 13,
    paddingBottom: 12,
  },
}));

export default function Technical(props) {
  const { currentUser } = props;
  const classes = useStyles();
  const [brandOptions, setBrandOptions] = useState([]);
  const [brand, setBrand] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    let mounted = true;
    const options = [];
    if (currentUser.brands && mounted) {
      Object.keys(currentUser.brands).forEach((key) => {
        if (currentUser.brands[key])
          options.push(
            <MenuItem key={key} value={key}>
              {brandNames[key]}
            </MenuItem>
          );
      });
      setBrandOptions(options);
    }
    return () => (mounted = false);
  }, [currentUser]);

  useEffect(() => {
    setFiles([]);
    const array = [];
    axios
      .get(`/techLength/${brand}`)
      .then((res) => {
        if (res.data.value > 0) {
          for (let index = 0; index < res.data.value; index++) {
            axios.defaults.headers.common["Authorization"] = localStorage.Token;
            axios
              .get(`/techDocs/${brand}/${index}`, {
                responseType: "arraybuffer",
              })
              .then((res) => {
                const n = res.headers["content-disposition"]
                  .split("filename=")[1]
                  .replace(/"/g, "");
                const url = window.URL.createObjectURL(
                  new Blob([res.data], { type: res.headers["content-type"] })
                );
                array.push({ [n]: url });
                setFiles([...array]);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [brand]);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.heading}>
          <Typography variant="h3" id="modal-title">
            Technnical Documentation
          </Typography>
          <Typography variant="subtitle2" id="modal-description">
            View technical docs
          </Typography>
        </Box>

        <Box>
          <Box className={classes.filesContainer}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                className={classes.inputLabel}
                htmlFor="select-brand"
              >
                Select brand
              </Typography>
              <Select
                variant="filled"
                className={classes.select}
                disableUnderline
                displayEmpty
                fullWidth
                id="select-brand"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                inputProps={{ "aria-label": "Select brand" }}
              >
                {brandOptions.map((option) => option)}
              </Select>
            </FormControl>
            {brand && (
              <>
                {files.length > 0 ? (
                  <>
                    <List disablePadding>
                      {files.map((file, i) => {
                        return (
                          <ListItem
                            className={classes.fileRow}
                            disableGutters
                            key={i}
                          >
                            <Box className={classes.file}>
                              <Typography
                                className={classes.fileText}
                                variant="h4"
                                component="a"
                                href={Object.values(file)[0]}
                                download={Object.keys(file)[0]}
                              >
                                {Object.keys(file)[0]}
                              </Typography>
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                ) : (
                  <Typography variant="body2">
                    <em>None available</em>
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
