import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      "& > td": {
        padding: "12px 6px",
      },
      "& > td.checkbox": {
        paddingLeft: "12px",
        paddingRight: "12px",
      },
      "& > td:first-child": {
        borderRadius: "5px 0 0 5px",
      },
      "& > th": {
        padding: "12px 6px",
      },
      "& > th:first-child": {
        paddingLeft: "50px",
        borderRadius: "5px 0 0 5px",
      },
      "& > td:last-of-type": {
        paddingRight: "12px",
        borderRadius: "0 5px 5px 0",
      },
      "& .table-button": {
        padding: "11px 21px",
      },
      "& .icon-button": {
        padding: 6.5,
      },
    },
  },
}));

export default function StyledTableRow(props) {
  const classes = useStyles();
  const { children, ...rest } = props;

  return (
    <TableRow hover tabIndex={-1} className={classes.row} {...rest}>
      {children}
    </TableRow>
  );
}
