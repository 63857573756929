import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import { getComparator, stableSort } from "../../util/sortFunctions";
import StyledTableHead from "./StyledTableHead";
import StyledTableRow from "../StyledTableRow";
import StyledTablePagination from "../StyledTablePagination";
import brandNames from "../../util/brands";
import ModalBase from "../modals/ModalBase";
import ViewRego from "../modals/registrations/ViewRego";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0 10px 20px",
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
  mobileCell: {
    borderRadius: "5px",
  },
  mobileGrid: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "15px",
    padding: "15px 15px 10px",
  },
  name: {
    marginBottom: 9,
    gridColumnStart: "span 2",
  },
  mobileGridRow: {
    paddingBottom: 2,
    "&:first-of-type": {
      alignSelf: "flex-start",
    },
    "&:nth-of-type(even)": {
      justifySelf: "flex-end",
    },
  },
}));

export default function RegoTable(props) {
  const { regos, dealer, isAdmin, currentUser, setRegos } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRego, setSelectedRego] = useState({});
  const [openViewRego, setOpenViewRego] = useState(false);

  let headCells;
  if (dealer === process.env.REACT_APP_ADMIN_ID) {
    headCells = [
      { id: "dealer", label: "Dealer" },
      { id: "vin", label: "VIN #" },
      { id: "brand", label: "Brand" },
      { id: "model", label: "Model" },
      { id: "name", label: "Customer Name" },
      { id: "keyNumber", label: "Key Number" },
      { id: "createdAt", label: "Date Created" },
      { id: "view", label: "View" },
    ];
  } else {
    headCells = [
      { id: "vin", label: "VIN #" },
      { id: "brand", label: "Brand" },
      { id: "model", label: "Model" },
      { id: "name", label: "Customer Name" },
      { id: "keyNumber", label: "Key Number" },
      { id: "createdAt", label: "Date Created" },
      { id: "view", label: "View" },
      // { id: "download", label: "Export to XLS" },
    ];
  }

  const filteredRegos = regos.filter((rego) => rego.search);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (filteredRegos.length / rowsPerPage < page) {
      setPage(0);
    }
  }, [filteredRegos.length, rowsPerPage, page]);

  // const handleDownload = (vin) => {
  //   axios
  //     .get(`/downloadRego/${vin}`, { responseType: "arraybuffer" })
  //     .then((res) => {
  //       let name = res.headers["content-disposition"]
  //         .split("filename=")[1]
  //         .replace(/"/g, "")
  //         .split(".");
  //       const anchor = document.createElement("a");
  //       const a = window.URL.createObjectURL(
  //         new Blob([res.data], {
  //           type: res.headers["content-type"],
  //         })
  //       );
  //       anchor.href = a;
  //       anchor.download = `Download.${name[name.length - 1]}`;
  //       anchor.click();
  //     })
  //     .catch((e) => console.log(e.response));
  // };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {!isMobile && (
            <StyledTableHead
              page="Warranty Registrations"
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredRegos.length}
              headCells={headCells}
            />
          )}
          <TableBody>
            <TableRow></TableRow>
            {stableSort(filteredRegos, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow role="checkbox" key={row.vin}>
                    {isMobile ? (
                      <TableCell
                        className={classes.mobileCell}
                        colSpan={11}
                        onClick={() => {
                          setSelectedRego(row);
                          setOpenViewRego(true);
                        }}
                      >
                        <Box className={classes.mobileGrid}>
                          <Typography className={classes.name} variant="h2">
                            {row.name}
                          </Typography>
                          <Typography
                            className={classes.mobileGridRow}
                            style={{ alignSelf: "flex-end" }}
                          >
                            <strong>Brand: </strong>
                            {brandNames[row.brand]}
                          </Typography>
                          <Typography className={classes.mobileGridRow}>
                            <strong>Dealership: </strong>
                            {row.dealer}
                          </Typography>
                          <Typography className={classes.mobileGridRow}>
                            <strong>Model: </strong>
                            {row.model}
                          </Typography>
                          <Typography className={classes.mobileGridRow}>
                            <strong>Key Number: </strong>
                            {row.keyNumber || "-"}
                          </Typography>
                          <Typography className={classes.mobileGridRow}>
                            <strong>Date Created: </strong>
                            {format(
                              new Date(row.createdAt),
                              "dd/MM/yyyy HH:mm"
                            )}
                          </Typography>
                          <Typography className={classes.mobileGridRow}>
                            <strong>VIN: </strong>
                            {row.vin}
                          </Typography>
                        </Box>
                      </TableCell>
                    ) : (
                      <>
                        {isAdmin && (
                          <TableCell
                            align="left"
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.dealer}
                          </TableCell>
                        )}
                        <TableCell
                          component={isAdmin ? "td" : "th"}
                          scope="row"
                          padding="none"
                          align="left"
                        >
                          {row.vin}
                        </TableCell>
                        <TableCell align="left">
                          {brandNames[row.brand]}
                        </TableCell>
                        <TableCell align="left">{row.model}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.keyNumber}</TableCell>
                        <TableCell align="left">
                          {format(new Date(row.createdAt), "dd/MM/yyyy HH:mm")}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            className="table-button"
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setSelectedRego(row);
                              setOpenViewRego(true);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                        {/* <TableCell align="left">
                        <IconButton onClick={() => handleDownload(row.vin)}>
                          <GetAppIcon color="secondary" />
                        </IconButton>
                      </TableCell> */}
                      </>
                    )}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledTablePagination
        count={filteredRegos.length}
        rowsPerPage={rowsPerPage}
        page={Math.floor(filteredRegos.length / rowsPerPage) < page ? 0 : page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ModalBase openModal={openViewRego} setOpenModal={setOpenViewRego}>
        <ViewRego
          currentUser={currentUser}
          setOpenViewRego={setOpenViewRego}
          selectedRego={selectedRego}
          regos={regos}
          setRegos={setRegos}
        />
      </ModalBase>
    </div>
  );
}
