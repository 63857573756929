import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MobileHeaderSubMenu from "../components/menus/MobileHeaderSubMenu";
import ReportsHeader from "../components/reports/ReportsHeader";
import ReportsHeaderMobile from "../components/reports/ReportsHeaderMobile";
import ThroughputsTable from "../components/reports/ThroughputsTable";
import StockReportsTable from "../components/reports/StockReportsTable";

export default function Reports(props) {
  const {
    isAdmin,
    currentUser,
    selectedSubTab,
    setSelectedSubTab,
    MobileHeaderWithContext,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [dealerFilter, setDealerFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState("");
  const [dealers, setDealers] = useState([]);
  const [stockReportBrands, setStockReportBrands] = useState({});

  useEffect(() => {
    let mounted = true;

    if (currentUser.brands) {
      const newStockReportBrands = {
        aprilia: currentUser.brands.aprilia,
        vespa: currentUser.brands.vespa,
        motoGuzzi: currentUser.brands.motoGuzzi,
        bsa: currentUser.brands.bsa,
      };

      if (Object.values(newStockReportBrands).includes(true) || isAdmin) {
        setStockReportBrands({
          hasStockReportBrands: true,
          brands: isAdmin
            ? { aprilia: true, vespa: true, motoGuzzi: true, bsa: true }
            : newStockReportBrands,
        });
      } else setStockReportBrands({ hasStockReportBrands: false });
    }

    if (isAdmin) {
      axios
        .get("/users")
        .then((res) => {
          if (mounted) setDealers(res.data.users);
        })
        .catch((e) => console.log(e));
    } else {
      setDealers([currentUser]);
    }

    return () => (mounted = false);
  }, [currentUser, isAdmin]);

  return (
    <>
      {isMobile ? (
        <>
          <MobileHeaderWithContext>
            <ReportsHeaderMobile
              dealerFilter={dealerFilter}
              setDealerFilter={setDealerFilter}
              monthFilter={monthFilter}
              setMonthFilter={setMonthFilter}
              dealers={dealers}
              isAdmin={isAdmin}
            />
          </MobileHeaderWithContext>
          <MobileHeaderSubMenu
            category={"Reports"}
            selectedSubTab={selectedSubTab}
            setSelectedSubTab={setSelectedSubTab}
            hasStockReportBrands={
              stockReportBrands.hasStockReportBrands || isAdmin
            }
          />
        </>
      ) : (
        <ReportsHeader
          dealerFilter={dealerFilter}
          setDealerFilter={setDealerFilter}
          monthFilter={monthFilter}
          setMonthFilter={setMonthFilter}
          dealers={dealers}
          isAdmin={isAdmin}
        />
      )}
      {selectedSubTab === "Throughputs Reports" && (
        <ThroughputsTable
          dealerFilter={dealerFilter}
          monthFilter={monthFilter}
          dealers={dealers}
          currentUser={currentUser}
          isAdmin={isAdmin}
        />
      )}
      {selectedSubTab === "Stock Reports" &&
        stockReportBrands.hasStockReportBrands === true && (
          <StockReportsTable
            dealerFilter={dealerFilter}
            monthFilter={monthFilter}
            dealers={dealers}
            currentUser={currentUser}
            isAdmin={isAdmin}
            stockReportBrands={stockReportBrands}
          />
        )}
    </>
  );
}
