import React from "react";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import SubMenuTab from "./SubmenuTab";

const useStyles = makeStyles((theme) => ({
  collapse: {
    width: "100%",
  },
  list: {
    width: "100%",
    padding: "0 32px",
  },
}));

export default function SubMenu(props) {
  const {
    category,
    selectedTab,
    selectedSubTab,
    setSelectedSubTab,
    hasStockReportBrands,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const colors = theme.palette.common;

  const handleClick = (e, tab) => {
    setSelectedSubTab(tab);
  };

  return (
    <>
      <Collapse in={category === selectedTab} className={classes.collapse}>
        <List disablePadding className={classes.list}>
          {category === "Warranty Claims" && (
            <>
              <SubMenuTab
                label="Approved"
                handleClick={handleClick}
                selected={selectedSubTab}
                iconColor={colors.black}
              />
              <SubMenuTab
                label="Pending"
                handleClick={handleClick}
                selected={selectedSubTab}
                iconColor={colors.mediumDarkGrey}
              />
              <SubMenuTab
                label="View all"
                handleClick={handleClick}
                selected={selectedSubTab}
                iconColor={
                  selectedSubTab === "View all"
                    ? colors.white
                    : colors.lightGrey
                }
              />
            </>
          )}
          {category === "Accounts" && (
            <>
              <SubMenuTab
                label="Dealers"
                to="/accounts/dealer"
                handleClick={handleClick}
                selected={selectedSubTab}
                iconColor={colors.black}
              />
              <SubMenuTab
                label="Users"
                to="/accounts/user"
                handleClick={handleClick}
                selected={selectedSubTab}
                iconColor={colors.mediumDarkGrey}
              />
            </>
          )}
          {category === "Reports" && (
            <>
              <SubMenuTab
                label="Throughputs Reports"
                to="/reports/throughputs"
                handleClick={handleClick}
                selected={selectedSubTab}
                iconColor={colors.black}
              />
              {hasStockReportBrands && (
                <SubMenuTab
                  label="Stock Reports"
                  to="/reports/stock-reports"
                  handleClick={handleClick}
                  selected={selectedSubTab}
                  iconColor={colors.mediumDarkGrey}
                />
              )}
            </>
          )}
        </List>
      </Collapse>
    </>
  );
}
