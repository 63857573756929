import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import EditRounded from "@material-ui/icons/EditRounded";

import StyledTablePagination from "../StyledTablePagination";
import StyledTableRow from "../StyledTableRow";

import brandNames from "../../util/brands";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
  headerRow: {
    backgroundColor: theme.palette.common.mediumGrey,
    "& > th": {
      fontWeight: 600,
      lineHeight: 1.3,
      padding: "12px 6px",
    },
    "& > th:first-of-type": {
      paddingLeft: "50px",
      borderRadius: "5px 0 0 5px",
    },
    "& > th:last-of-type": {
      paddingRight: "40px",
      borderRadius: "0 5px 5px 0",
    },
  },
  mobileCell: {
    borderRadius: "5px",
  },
  mobileGrid: {
    padding: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {
    marginBottom: 9,
  },
}));

export default function DealersTable(props) {
  const { dealers, dealerFilter, setSelectedDealer, setOpenEditDealer } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filteredDealers = dealerFilter
    ? dealers.filter((dealer) => dealer.name === dealerFilter)
    : dealers.filter(
        (dealer) => dealer.name !== process.env.REACT_APP_ADMIN_ID
      );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [dealerFilter]);

  useEffect(() => {
    if (filteredDealers.length / rowsPerPage < page) {
      setPage(0);
    }
  }, [filteredDealers.length, rowsPerPage, page]);

  return (
    <>
      <div className={classes.root}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          {!isMobile && (
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell align="left">Dealer</TableCell>
                <TableCell align="left">Brands</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            <TableRow></TableRow>
            {filteredDealers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((dealer) => {
                let brandList = [];
                Object.keys(dealer.brands).forEach((key) => {
                  if (dealer.brands[key] === true) {
                    brandList.push(brandNames[key]);
                  }
                });

                return (
                  <StyledTableRow key={dealer._id}>
                    {isMobile ? (
                      <TableCell className={classes.mobileCell} colSpan={11}>
                        <Box
                          className={classes.mobileGrid}
                          onClick={() => {
                            setSelectedDealer(dealer);
                            setOpenEditDealer(true);
                          }}
                        >
                          <Box>
                            <Typography className={classes.name} variant="h2">
                              {dealer.name}
                            </Typography>
                            <Typography>
                              <strong>Brands: </strong>
                              {brandList.join(", ")}
                            </Typography>
                          </Box>
                          <IconButton>
                            <EditRounded />
                          </IconButton>
                        </Box>
                      </TableCell>
                    ) : (
                      <>
                        <TableCell
                          component="th"
                          padding="none"
                          scope="row"
                          align="left"
                        >
                          {dealer.name}
                        </TableCell>
                        <TableCell align="left">
                          {brandList.join(", ")}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            className="table-button"
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setSelectedDealer(dealer);
                              setOpenEditDealer(true);
                            }}
                          >
                            Edit Dealer
                          </Button>
                        </TableCell>
                      </>
                    )}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <StyledTablePagination
        count={filteredDealers.length}
        rowsPerPage={rowsPerPage}
        page={
          Math.floor(filteredDealers.length / rowsPerPage) < page ? 0 : page
        }
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
