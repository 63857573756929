import React from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
  },
  tab: {
    ...theme.typography.tab,
    padding: 0,
  },
  tabAccounts: {
    ...theme.typography.tab,
    padding: 0,
    minWidth: "130px",
  },
  mobileTabs: {
    padding: "0 21px",
  },
  flexContainerClaims: {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
    },
  },
  flexContainerAccounts: {
    justifyContent: "center",
    "&:has(>:only-child)": {
      justifyContent: "flex-start",
    },
  },
}));

export default function MobileHeaderSubMenu({
  category,
  selectedSubTab,
  setSelectedSubTab,
  hasStockReportBrands,
}) {
  const classes = useStyles();

  const handleClick = (e, tab) => {
    setSelectedSubTab(tab);
  };

  return (
    <Box className={classes.container}>
      {category === "Warranty Claims" && (
        <Tabs
          centered
          classes={{
            flexContainer: classes.flexContainerClaims,
          }}
          className={classes.mobileTabs}
          value={selectedSubTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleClick}
        >
          <Tab
            disableRipple
            className={classes.tab}
            label="View all"
            value="View all"
          />
          <Tab
            disableRipple
            className={classes.tab}
            label="Pending"
            value="Pending"
          />
          <Tab
            disableRipple
            className={classes.tab}
            label="Approved"
            value="Approved"
          />
        </Tabs>
      )}
      {category === "Accounts" && (
        <Tabs
          centered
          classes={{
            flexContainer: classes.flexContainerAccounts,
          }}
          className={classes.mobileTabs}
          value={selectedSubTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleClick}
        >
          <Tab
            disableRipple
            className={classes.tabAccounts}
            component={Link}
            to="/accounts/dealer"
            label="Dealers"
            value="Dealers"
          />
          <Tab
            disableRipple
            className={classes.tabAccounts}
            component={Link}
            to="/accounts/user"
            label="Users"
            value="Users"
          />
        </Tabs>
      )}
      {category === "Reports" && (
        <Tabs
          centered
          classes={{
            flexContainer: classes.flexContainerAccounts,
          }}
          className={classes.mobileTabs}
          value={selectedSubTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleClick}
        >
          <Tab
            disableRipple
            className={classes.tabAccounts}
            component={Link}
            to="/reports/throughputs"
            label="Throughputs Reports"
            value="Throughputs Reports"
          />
          {hasStockReportBrands && (
            <Tab
              disableRipple
              className={classes.tabAccounts}
              component={Link}
              to="/reports/stock-reports"
              label="Stock Reports"
              value="Stock Reports"
            />
          )}
        </Tabs>
      )}
    </Box>
  );
}
