import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import SvgIcon from "@material-ui/core/SvgIcon";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  user: {
    flexGrow: 1,
    paddingLeft: "18px",
  },
  iconContainer: {
    padding: "15px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down(400)]: {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  addButton: {
    fontSize: "10px",
    color: theme.palette.common.black,
  },
}));

export default function DashboardHeaderMobile(props) {
  const {
    currentUser,
    isAdmin,
    searchBarOpen,
    setSearchBarOpen,
    setOpenUploadFiles,
    setOpenAdd,
    page,
    handleDownload,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Box className={classes.user}>
        <Typography variant="body1">
          Hi <strong>{currentUser?.name}</strong>
        </Typography>
        <Typography variant="subtitle2">{currentUser?.email}</Typography>
      </Box>
      <Box className={classes.iconButtons}>
        {isAdmin ? (
          <>
            {page === "Warranty Registrations" && (
              <IconButton
                disableRipple
                className={classes.iconContainer}
                onClick={handleDownload}
                aria-label="Export all to XLS"
              >
                <GetAppIcon
                  style={{
                    fontSize: "24px",
                    paddingTop: "2px",
                    color: theme.palette.common.black,
                  }}
                />
              </IconButton>
            )}
            <IconButton
              disableRipple
              className={classes.iconContainer}
              onClick={() => setOpenUploadFiles(true)}
              aria-label="Upload files"
            >
              <SvgIcon component={UploadIcon} viewBox="-5 -4 22 22" />
            </IconButton>
          </>
        ) : (
          <IconButton
            disableRipple
            className={classes.addButton}
            aria-label="Add new"
            onClick={() => setOpenAdd(true)}
          >
            <AddCircleOutlineRoundedIcon />
          </IconButton>
        )}

        <IconButton
          onClick={() => setSearchBarOpen(!searchBarOpen)}
          disableRipple
          className={classes.iconContainer}
          aria-label="Search"
        >
          <SvgIcon component={SearchIcon} viewBox="-5 -4 22 22" />
        </IconButton>
      </Box>
    </>
  );
}
