import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import brandNames from "../../../util/brands";
import ClaimImageGrid from "./ClaimImageGrid";
import EditParts from "./EditParts";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "800px",
  },
  circularProgress: {
    position: "absolute",
  },
  header: {
    padding: "10px 10px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px 18px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  imageGridContainer: {
    padding: "10px 4px 5px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 0px",
    },
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  datePicker: {
    "& .MuiInputBase-root": {
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "13px 11px 12px",
      fontSize: "10px",
      lineHeight: "15px",
    },
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  select: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
  },
  buttons: {
    marginTop: 10,
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      paddingBottom: 15,
    },
  },
  cancelButton: {
    paddingLeft: 0,
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  button: {
    padding: "12px 30px 13px",
  },
  error: {
    color: theme.palette.secondary.light,
  },
}));

const EditClaim = (props) => {
  const { selectedPrior, currentUser, setOpenEditClaim, priors, setPriors } =
    props;
  const classes = useStyles();
  const [prior, setPrior] = useState(selectedPrior);
  const [partNumbers, setPartNumbers] = useState(
    selectedPrior.partNumbers || {}
  );
  const [quantity, setQuantity] = useState(selectedPrior.quantity || {});
  const [dateOfFailureToggle, setDateOfFailureToggle] = useState(
    selectedPrior.dateOfFailure ? true : false
  );
  const [dateOfSaleToggle, setDateOfSaleToggle] = useState(
    selectedPrior.dateOfSale ? true : false
  );
  const [imageURLs, setImageURLs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState("");
  const [imageFilesToDelete, setImageFilesToDelete] = useState([]);
  const [imageFormatError, setImageFormatError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const brandOptions = [];
  Object.keys(currentUser.brands).forEach((key) => {
    if (currentUser.brands[key])
      brandOptions.push(
        <MenuItem key={key} value={key}>
          {brandNames[key]}
        </MenuItem>
      );
  });

  const handleCloseSuccessSnackbar = (event, reason) => {
    setSuccess(false);
    setOpenEditClaim(false);
  };
  const handleCloseImageFormatSnackbar = (event, reason) => {
    setImageFormatError(false);
  };
  const handleCloseFileSizeSnackbar = (event, reason) => {
    setFileSizeError(false);
  };
  const handleCloseMaxFilesSnackbar = (event, reason) => {
    setMaxFilesError(false);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (selectedPrior.images > 0) {
        const imgURLArray = [];
        for (let index = 0; index < selectedPrior.images; index++) {
          axios.defaults.headers.common["Authorization"] = localStorage.Token;
          axios
            .get(`/images/${selectedPrior.id}/${index}`, {
              responseType: "arraybuffer",
            })
            .then((res) => {
              const name = res.headers["content-disposition"]
                .split("filename=")[1]
                .replace(/"/g, "");

              const url = window.URL.createObjectURL(
                new Blob([res.data], {
                  type: res.headers["content-type"],
                })
              );
              imgURLArray.push({ [name]: url });

              setImageURLs([...imgURLArray]);
            })
            .catch((e) => console.log(e));
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [selectedPrior.id, selectedPrior.images]);

  const toggleDateOfFailure = () => {
    if (dateOfFailureToggle === true) {
      setPrior({ ...prior, dateOfFailure: null });
    } else {
      setPrior({ ...prior, dateOfFailure: new Date() });
    }
    setDateOfFailureToggle(!dateOfFailureToggle);
  };

  const toggleDateOfSale = () => {
    if (dateOfSaleToggle === true) {
      setPrior({ ...prior, dateOfSale: null });
    } else {
      setPrior({ ...prior, dateOfSale: new Date() });
    }
    setDateOfSaleToggle(!dateOfSaleToggle);
  };

  const handleImageFiles = (e) => {
    let newImageFileList = Array.from(e.target.files);
    for (const file of newImageFileList) {
      if (
        !file.type.includes("image/jpg") &&
        !file.type.includes("image/jpeg") &&
        !file.type.includes("image/gif") &&
        !file.type.includes("image/svg") &&
        !file.type.includes("image/png") &&
        !file.type.includes("application/pdf")
      )
        return setImageFormatError(true);
    }
    for (const file of newImageFileList) {
      if (file.size > 5100000) return setFileSizeError(true);
    }
    if (newImageFileList.length + imageURLs.length > 6) setMaxFilesError(true);
    else {
      let arr = [];
      if (imageFiles.length === 0) {
        setImageFiles(newImageFileList);
      } else {
        setImageFiles([...imageFiles, ...newImageFileList]);
      }
      setPrior({
        ...prior,
        images: newImageFileList.length + imageURLs.length,
      });
      newImageFileList.forEach((file) => {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          arr.push({ [file.name]: fileReader.result });
          setImageURLs([...imageURLs, ...arr]);
        };
      });
    }
  };

  const deleteImages = (imageToDelete) => {
    if (Object.values(imageToDelete)[0].includes("blob")) {
      setImageFilesToDelete([
        ...imageFilesToDelete,
        Object.keys(imageToDelete)[0],
      ]);
    }
    //FILES
    const filesCopy = [...imageFiles];
    const nameToDelete = Object.keys(imageToDelete)[0];
    const filteredImageFiles = filesCopy.filter(
      (file) => file.name !== nameToDelete
    );
    setImageFiles(filteredImageFiles);
    ///IMAGE URLS
    const imageURLsCopy = [...imageURLs];
    const toDelete = Object.values(imageToDelete)[0];
    const filteredImageURLs = imageURLsCopy.filter(
      (image) => Object.values(image)[0] !== toDelete
    );
    setImageURLs(filteredImageURLs);
    setPrior({ ...prior, images: filteredImageURLs.length });
  };

  const handleCancel = () => {
    setOpenEditClaim(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = new FormData();

    let p = Object.fromEntries(Object.entries(partNumbers).sort());
    let q = Object.fromEntries(Object.entries(quantity).sort());
    Object.entries(p).forEach((o) => (o[1] === "" ? delete p[o[0]] : null));
    Object.entries(q).forEach((o) => (o[1] === "" ? delete q[o[0]] : null));

    for (let index = 0; index < Object.keys(p).length; index++) {
      const number = Object.keys(p)[index].split("part")[1];
      if (q[`qty${number}`] === undefined || q[`qty${index}`] === "") {
        setLoading(false);
        return setErrors({
          ...errors,
          partNumbers: "Please check part number has quantity or vice versa",
        });
      }
    }

    for (let index = 0; index < Object.keys(q).length; index++) {
      const number = Object.keys(q)[index].split("qty")[1];
      if (p[`part${number}`] === undefined || p[`part${index}`] === "") {
        setLoading(false);
        return setErrors({
          ...errors,
          partNumbers: "Please check part number has quantity or vice versa",
        });
      }
    }

    if (imageFilesToDelete.length > 0) {
      axios
        .post(`/deleteFile/${selectedPrior.id}`, imageFilesToDelete)
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }

    const updatedPrior = {
      model: prior.model,
      vin: prior.vin,
      dateOfFailure: prior.dateOfFailure,
      dateOfSale: prior.dateOfSale,
      diagnosis: prior.diagnosis,
      partNumbers: p,
      quantity: q,
      symptoms: prior.symptoms,
      brand: prior.brand,
      mileage: prior.mileage,
      customerName: prior.customerName,
      status: "Pending",
      images: prior.images,
    };

    axios
      .patch(`/prior/${selectedPrior.id}`, updatedPrior)
      .then((res) => {
        if (imageFiles.length > 0) {
          data.append("prior", res.data._id);
          for (let i = 0; i < imageFiles.length; i++) {
            data.append("files", imageFiles[i]);
          }
          axios
            .post("/upload", data)
            .then((res) => {
              console.log("ok");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .then(() => {
        const updatedPriors = [
          ...priors.slice(0, priors.indexOf(selectedPrior)),
          { ...selectedPrior, ...updatedPrior },
          ...priors.slice(priors.indexOf(selectedPrior) + 1),
        ];
        setPriors(updatedPriors);
        setErrors({});
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setErrors(err.response.data);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {selectedPrior.status === "Completed" ||
      selectedPrior.status === "Approved" ? (
        <Typography style={{ paddingRight: "40px" }}>
          Unable to edit!
        </Typography>
      ) : (
        <React.Fragment>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box className={classes.header}>
              <Typography variant="h3" id="modal-title">
                Edit Claim | {currentUser?.name}
              </Typography>
              <Typography variant="subtitle2" id="modal-description">
                Access and edit any information needed
              </Typography>
            </Box>
            <Grid container direction="row" className={classes.root}>
              <Grid item xs={12} md={6} className={classes.gridItem}>
                <Grid
                  item
                  container
                  alignItems="center"
                  spacing={1}
                  style={{ paddingBottom: 1 }}
                >
                  <Grid item>
                    <Typography variant="h4">Date of Failure</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      size="small"
                      checked={dateOfFailureToggle}
                      onChange={toggleDateOfFailure}
                      name="checked"
                      inputProps={{
                        "aria-label": "add/remove date of failure",
                      }}
                    />
                  </Grid>
                </Grid>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  inputVariant="outlined"
                  InputProps={{ className: classes.fieldset }}
                  disabled={!dateOfFailureToggle}
                  fullWidth
                  format="dd/MM/yyyy"
                  value={
                    dateOfFailureToggle === false
                      ? undefined
                      : prior.dateOfFailure
                  }
                  onChange={(newValue) =>
                    setPrior({ ...prior, dateOfFailure: newValue })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    htmlFor="customer-name"
                    className={classes.inputLabel}
                  >
                    Customer Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    inputProps={{ className: classes.textInput }}
                    InputProps={{ className: classes.fieldset }}
                    id="customer-name"
                    fullWidth
                    value={prior.customerName}
                    onChange={(e) =>
                      setPrior({ ...prior, customerName: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    htmlFor="mileage"
                    className={classes.inputLabel}
                  >
                    Mileage (km)
                  </Typography>
                  <TextField
                    variant="outlined"
                    inputProps={{ className: classes.textInput }}
                    InputProps={{ className: classes.fieldset }}
                    id="mileage"
                    fullWidth
                    error={errors.mileage ? true : false}
                    value={prior.mileage}
                    onChange={(e) =>
                      setPrior({ ...prior, mileage: e.target.value })
                    }
                  />
                </FormControl>
                {errors.mileage && (
                  <Typography variant="subtitle2" className={classes.error}>
                    {errors.mileage}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={6} md={6} className={classes.gridItem}>
                <FormControl className={classes.formControl} fullWidth>
                  <Typography
                    component="label"
                    htmlFor="select-brand"
                    className={classes.inputLabel}
                  >
                    Brand
                  </Typography>
                  <Select
                    variant="filled"
                    className={classes.select}
                    disableUnderline
                    fullWidth
                    id="select-brand"
                    value={prior.brand || ""}
                    onChange={(e) =>
                      setPrior({ ...prior, brand: e.target.value })
                    }
                    inputProps={{
                      "aria-label": "Select brand",
                    }}
                  >
                    <MenuItem value={""} disabled>
                      <em>Select brand:</em>
                    </MenuItem>
                    {brandOptions.map((option) => option)}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    htmlFor="model"
                    className={classes.inputLabel}
                  >
                    Model
                  </Typography>
                  <TextField
                    variant="outlined"
                    inputProps={{ className: classes.textInput }}
                    InputProps={{ className: classes.fieldset }}
                    id="model"
                    fullWidth
                    value={prior.model}
                    onChange={(e) =>
                      setPrior({ ...prior, model: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    htmlFor="vin"
                    className={classes.inputLabel}
                  >
                    VIN #
                  </Typography>
                  <TextField
                    variant="outlined"
                    inputProps={{ className: classes.textInput }}
                    InputProps={{ className: classes.fieldset }}
                    id="vin"
                    error={errors.vin ? true : false}
                    fullWidth
                    value={prior.vin}
                    onChange={(e) =>
                      setPrior({ ...prior, vin: e.target.value })
                    }
                  />
                </FormControl>
                {errors.vin && (
                  <Typography variant="subtitle2" className={classes.error}>
                    {errors.vin}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} md={6} className={classes.gridItem}>
                <Grid
                  item
                  container
                  alignItems="center"
                  spacing={1}
                  style={{ paddingBottom: 1 }}
                >
                  <Grid item>
                    <Typography variant="h4">Date of Sale</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      size="small"
                      checked={dateOfSaleToggle}
                      onChange={toggleDateOfSale}
                      name="checked"
                      inputProps={{ "aria-label": "add/remove date of sale" }}
                    />
                  </Grid>
                </Grid>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  inputVariant="outlined"
                  InputProps={{ className: classes.fieldset }}
                  value={
                    dateOfSaleToggle === false ? undefined : prior.dateOfSale
                  }
                  disabled={!dateOfSaleToggle}
                  fullWidth
                  format="dd/MM/yyyy"
                  onChange={(newValue) =>
                    setPrior({ ...prior, dateOfSale: newValue })
                  }
                />
              </Grid>

              <Grid item sm={6} />

              <Grid item xs={12} md={6} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    htmlFor="symptoms"
                    className={classes.inputLabel}
                  >
                    Symptoms/Customer Complaint
                  </Typography>
                  <TextField
                    variant="outlined"
                    InputProps={{ className: classes.textInput }}
                    id="symptoms"
                    rows={matches ? 5 : 10}
                    multiline
                    fullWidth
                    value={prior.symptoms}
                    onChange={(e) =>
                      setPrior({ ...prior, symptoms: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} className={classes.gridItem}>
                <FormControl className={classes.formControl}>
                  <Typography
                    component="label"
                    htmlFor="diagnosis"
                    className={classes.inputLabel}
                  >
                    Diagnosis - Cause of failure
                  </Typography>
                  <TextField
                    id="diagnosis"
                    variant="outlined"
                    InputProps={{ className: classes.textInput }}
                    rows={matches ? 5 : 10}
                    multiline
                    fullWidth
                    value={prior.diagnosis}
                    onChange={(e) =>
                      setPrior({ ...prior, diagnosis: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>

              {/* IMAGES */}
              <Grid item xs={12} className={classes.imageGridContainer}>
                <ClaimImageGrid
                  handleImageFiles={handleImageFiles}
                  prior={prior}
                  imageURLs={imageURLs}
                  deleteImages={deleteImages}
                />
              </Grid>

              {/* PARTS */}
              <EditParts
                partNumbers={partNumbers}
                setPartNumbers={setPartNumbers}
                quantity={quantity}
                setQuantity={setQuantity}
                errors={errors}
              />

              <Grid
                item
                container
                alignItems="center"
                xs={12}
                className={classes.gridItem}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                  className={classes.buttons}
                >
                  <Button
                    className={classes.cancelButton}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    className={classes.button}
                    disabled={loading || success}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Save changes
                    {loading && (
                      <CircularProgress
                        size={20}
                        className={classes.circularProgress}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <Snackbar
            open={success}
            onClose={handleCloseSuccessSnackbar}
            autoHideDuration={2000}
          >
            <Alert
              onClose={handleCloseSuccessSnackbar}
              severity="success"
              style={{ alignItems: "center" }}
            >
              Claim succesfully edited!
            </Alert>
          </Snackbar>
          <Snackbar
            open={imageFormatError}
            autoHideDuration={6000}
            onClose={handleCloseImageFormatSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseImageFormatSnackbar}
              severity="error"
              style={{ alignItems: "center" }}
            >
              Images in these formats only: jpeg, svg, jpg, png, gif, pdf
            </Alert>
          </Snackbar>
          <Snackbar
            open={fileSizeError}
            autoHideDuration={6000}
            onClose={handleCloseFileSizeSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseFileSizeSnackbar}
              severity="error"
              style={{ alignItems: "center" }}
            >
              Maximum per each file: 5MB
            </Alert>
          </Snackbar>
          <Snackbar
            open={maxFilesError}
            autoHideDuration={6000}
            onClose={handleCloseMaxFilesSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseMaxFilesSnackbar}
              severity="error"
              style={{ alignItems: "center" }}
            >
              Maximum files allowed: 6
            </Alert>
          </Snackbar>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditClaim;
