import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "400px",
  },
  error: {
    color: theme.palette.secondary.light,
    marginBottom: 0,
  },
  header: {
    padding: "10px 10px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px 18px",
    },
  },
  gridItem: {
    padding: "5px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  formControl: {
    width: "100%",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  textInput: {
    padding: "12px 11px 13px",
    width: "100%",
    ...theme.typography.body2,
    borderRadius: "5px",
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}`,
      borderColor: theme.palette.common.mediumGrey,
    },
  },
  fieldset: {
    "& fieldset": {
      border: `1px solid ${theme.palette.common.mediumGrey}!important`,
      borderColor: `${theme.palette.common.mediumGrey}!important`,
    },
  },
  select: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    "& .MuiSelect-root": {
      padding: "11px 32px 10px 10px",
    },
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 5,
    paddingTop: 13,
    paddingBottom: 12,
  },
  helperText: {
    width: 314,
    marginBottom: "5px",
  },
  snackbar: {
    alignItems: "center",
  },
}));

export default function EditUser(props) {
  const {
    currentUser,
    selectedUser,
    setOpenEditUser,
    dealers,
    setDealers,
    users,
    setUsers,
  } = props;
  const classes = useStyles();
  const [selectedDealer, setSelectedDealer] = useState({});
  const [currEmail, setCurrEmail] = useState(selectedUser.email);
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    const newDealer = dealers.find(
      (dealer) => dealer.name === selectedUser.dealer
    );
    setSelectedDealer(newDealer || {});
  }, [selectedUser.dealer, dealers]);

  const handleCloseErrorSnackbar = (event, reason) => {
    setOpenErrorSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenEditUser(false);
  };

  const handleDealerChange = (e) => {
    if (e.target.value === "") {
      setSelectedDealer({});
    } else {
      const newDealer = dealers.find(
        (dealer) => dealer.name === e.target.value
      );
      setSelectedDealer(newDealer || {});
      setCurrEmail("");
      setNewEmail("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const handleUserChange = (e) => {
    if (e.target.value === "") {
      setCurrEmail();
      setNewEmail();
    } else {
      const newUser = selectedDealer.accounts.find(
        (account) => account.email === e.target.value
      );
      setCurrEmail(newUser.email || "");
      setNewEmail("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const handleEditUser = () => {
    const newErrors = {};
    if (!selectedDealer.name) {
      newErrors.dealer = "Must not be empty";
    }
    if (!newEmail) {
      newErrors.email = "Must not be empty";
    }
    if (!newPassword) {
      newErrors.password = "Must not be empty";
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = "Must not be empty";
    }
    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else if (Object.keys(newErrors).length === 0) {
      axios
        .patch("/user/dealer", {
          newEmail,
          email: currEmail,
          password: newPassword,
        })
        .then((res) => {
          const dealerIndex = dealers.findIndex(
            (dealer) => dealer.name === selectedDealer.name
          );
          const newDealers = dealers;
          newDealers[dealerIndex].accounts = res.data.accounts;
          setDealers(newDealers);
          const userIndex = users.findIndex(
            (dealer) => Object.keys(dealer)[0] === selectedDealer.name
          );
          const newUsers = users;
          const newEmails = res.data.accounts.map((account) => account.email);
          newUsers[userIndex][selectedDealer.name] = newEmails;
          setUsers(newUsers);
          console.log(newDealers, newUsers);

          setOpenSuccessSnackbar(true);
        })
        .catch((e) => {
          setOpenErrorSnackbar(true);
          console.log(e);
        });
    }
  };

  return (
    <>
      <Box className={classes.header}>
        <Typography variant="h3" id="modal-title">
          Edit a User | {currentUser?.name}
        </Typography>
        <Typography variant="subtitle2" id="modal-description">
          Edit a user for a dealer account
        </Typography>
      </Box>

      <Grid item xs={12} className={classes.gridItem}>
        <FormControl className={classes.formControl} fullWidth>
          <Typography
            component="label"
            htmlFor="select-dealer"
            className={classes.inputLabel}
          >
            Select a Dealer
          </Typography>
          <Select
            style={{ marginTop: 0 }}
            variant="filled"
            className={classes.select}
            value={selectedDealer.name || ""}
            disableUnderline
            fullWidth
            id="select-dealer"
            onChange={handleDealerChange}
            inputProps={{
              "aria-label": "Select dealer",
            }}
          >
            <MenuItem value={""} disabled>
              <em>Select dealer:</em>
            </MenuItem>
            {dealers.map((dealer, i) => {
              return (
                <MenuItem key={i} value={dealer.name}>
                  {dealer.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {errors.dealer && (
          <Typography variant="subtitle2" className={classes.error}>
            {errors.dealer}
          </Typography>
        )}
      </Grid>

      {Object.keys(selectedDealer).length > 0 && (
        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl} fullWidth>
            <Typography
              component="label"
              htmlFor="select-user"
              className={classes.inputLabel}
            >
              Select a User
            </Typography>
            <Select
              style={{ marginTop: 0 }}
              variant="filled"
              className={classes.select}
              value={currEmail}
              disableUnderline
              fullWidth
              id="select-user"
              onChange={handleUserChange}
              inputProps={{
                "aria-label": "Select user",
              }}
            >
              <MenuItem value={""} disabled>
                <em>Select user:</em>
              </MenuItem>
              {selectedDealer.accounts.map((user, i) => {
                return (
                  <MenuItem key={i} value={user.email}>
                    {user.email}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {errors.user && (
            <Typography variant="subtitle2" className={classes.error}>
              {errors.user}
            </Typography>
          )}
        </Grid>
      )}

      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <Typography
              component="label"
              className={classes.inputLabel}
              htmlFor="email"
            >
              New Email Address
            </Typography>
            <TextField
              value={newEmail}
              disabled={currEmail === ""}
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="email"
              error={errors.email ? true : false}
              variant="outlined"
              fullWidth
              onChange={(e) => setNewEmail(e.target.value)}
            />
            {errors.email && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.email}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <FormHelperText
              className={classes.helperText}
              id="password-helper-text"
            >
              Password Advice: Make sure your password is unique and contains a
              combination of uppercase letters, lowercase letters, numbers, and
              symbols.
            </FormHelperText>
            <Typography
              component="label"
              className={classes.inputLabel}
              htmlFor="new-password"
            >
              New Password
            </Typography>
            <TextField
              value={newPassword}
              disabled={currEmail === ""}
              aria-describedby="password-helper-text"
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="new-password"
              error={errors.password ? true : false}
              variant="outlined"
              type="password"
              fullWidth
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {errors.password && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.password}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <Typography
              component="label"
              className={classes.inputLabel}
              htmlFor="confirm-password"
            >
              Confirm Password
            </Typography>
            <TextField
              value={confirmPassword}
              disabled={currEmail === ""}
              inputProps={{ className: classes.textInput }}
              InputProps={{ className: classes.fieldset }}
              id="confirm-password"
              error={errors.confirmPassword ? true : false}
              variant="outlined"
              type="password"
              fullWidth
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {errors.confirmPassword && (
              <Typography variant="subtitle2" className={classes.error}>
                {errors.confirmPassword}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <Button
            fullWidth
            disabled={openSuccessSnackbar}
            type="submit"
            onClick={handleEditUser}
            className={classes.submitButton}
            variant="contained"
            color="primary"
            startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
          >
            Save Information
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSuccessSnackbar}
      >
        <Alert
          className={classes.snackbar}
          onClose={handleCloseSuccessSnackbar}
          severity="success"
        >
          Success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          style={{ alignItems: "center" }}
        >
          Something went wrong, please try again
        </Alert>
      </Snackbar>
    </>
  );
}
