import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormHelperText from "@material-ui/core/FormHelperText";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.secondary.light,
    marginBottom: 0,
  },
  circularProgress: {
    position: "absolute",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  heading: {
    marginBottom: 25,
  },
  input: {
    marginTop: "0!important",
    width: 314,
    border: `1px solid ${theme.palette.common.mediumGrey}`,
    borderRadius: "5px",
    padding: "5px 10px",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
  },
  submitButton: {
    marginTop: 20,
    paddingTop: 13,
    paddingBottom: 12,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  helperText: {
    width: 314,
  },
  snackbar: {
    alignItems: "center",
  },
}));

export default function EditProfile(props) {
  const { currentUser } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [dealer, setDealer] = useState({
    ...currentUser,
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    axios
      .patch("/user/password", { dealer })
      .then(() => {
        setLoading(false);
        setErrors({});
        setDealer({
          ...dealer,
          currentPassword: "",
          password: "",
          confirmPassword: "",
        });
        setOpenSuccessSnackbar(true);
      })
      .catch((err) => {
        const errors = err.response.data;
        setErrors(errors);
        setLoading(false);
      });
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.heading}>
          <Typography variant="h3" id="modal-title">
            Account Settings | {currentUser?.name}
          </Typography>
          <Typography variant="subtitle2" id="modal-description">
            Access and change private details
          </Typography>
        </Box>

        <FormControl className={classes.formControl}>
          <Typography
            component="label"
            className={classes.inputLabel}
            htmlFor="current-password"
          >
            Current Password
          </Typography>
          <Input
            className={classes.input}
            id="current-password"
            error={errors.currentPassword ? true : false}
            disableUnderline
            type="password"
            fullWidth
            onChange={(e) =>
              setDealer({ ...dealer, currentPassword: e.target.value })
            }
          />
          {errors.currentPassword && (
            <Typography variant="subtitle2" className={classes.error}>
              {errors.currentPassword}
            </Typography>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormHelperText
            className={classes.helperText}
            id="password-helper-text"
          >
            Password Advice: Make sure your password is unique and contains a
            combination of uppercase letters, lowercase letters, numbers, and
            symbols.
          </FormHelperText>
          <Typography
            component="label"
            className={classes.inputLabel}
            htmlFor="new-password"
          >
            New Password
          </Typography>
          <Input
            aria-describedby="password-helper-text"
            className={classes.input}
            id="new-password"
            error={errors.password ? true : false}
            disableUnderline
            type="password"
            fullWidth
            onChange={(e) => setDealer({ ...dealer, password: e.target.value })}
          />
          {errors.password && (
            <Typography variant="subtitle2" className={classes.error}>
              {errors.password}
            </Typography>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <Typography
            component="label"
            className={classes.inputLabel}
            htmlFor="confirm-password"
          >
            Confirm Password
          </Typography>
          <Input
            className={classes.input}
            id="confirm-password"
            error={errors.confirmPassword ? true : false}
            disableUnderline
            type="password"
            fullWidth
            onChange={(e) =>
              setDealer({ ...dealer, confirmPassword: e.target.value })
            }
          />
          {errors.confirmPassword && (
            <Typography variant="subtitle2" className={classes.error}>
              {errors.confirmPassword}
            </Typography>
          )}
        </FormControl>

        <Button
          disabled={loading || openSuccessSnackbar}
          type="submit"
          onClick={handleSubmit}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
        >
          Save Changes
          {loading && (
            <CircularProgress size={20} className={classes.circularProgress} />
          )}
        </Button>
      </Box>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSuccessSnackbar}
      >
        <Alert
          className={classes.snackbar}
          onClose={handleCloseSuccessSnackbar}
          severity="success"
        >
          Success!
        </Alert>
      </Snackbar>
    </>
  );
}
