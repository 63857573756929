import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import brandNames from "../../../util/brands";
import SaveAltRounded from "@material-ui/icons/SaveAltRounded";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "800px",
  },
  header: {
    padding: "10px 10px 18px",
  },
  gridItem: {
    padding: "8px 10px",
  },
  fieldName: {
    ...theme.typography.h4,
    padding: "5px 0",
  },
  button: {
    padding: "12px 30px 13px",
    height: "100%",
    width: "100%",
  },
  buttons: {
    marginTop: 20,
    gap: "10px",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      paddingBottom: 15,
    },
  },
  fileUpload: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 5,
    },
  },
  dialogTitle: {
    padding: "40px 40px 20px",
    "& h2": {
      ...theme.typography.h2,
    },
  },
  dialogButtons: {
    padding: "0 40px 30px",
  },
  dialogCancelButton: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  dialogButton: {
    padding: "12px 30px 13px",
  },
}));

const ViewRego = (props) => {
  const { currentUser, selectedRego, regos, setRegos, setOpenViewRego } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isAdmin = currentUser.name === process.env.REACT_APP_ADMIN_ID;
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [extension, setExtension] = useState("");
  const rego = selectedRego;

  const handleOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenViewRego(false);
  };

  useEffect(() => {
    let mounted = true;
    axios
      .get(`/pdi/${selectedRego.vin}`, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        if (mounted) {
          let name = res.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/"/g, "")
            .split(".");
          const a = window.URL.createObjectURL(
            new Blob([res.data], {
              type: res.headers["content-type"],
            })
          );
          document.querySelector("#aa").href = a;
          setExtension(name[name.length - 1]);
        }
      })
      .catch((e) => console.log(e.response));
  }, [selectedRego.vin]);

  const handleDelete = () => {
    axios
      .delete(`/deleteRego/${selectedRego.vin}`)
      .then(() => {
        setOpenDeleteConfirmation(false);
        const updatedRegos = regos.filter((rego) => {
          return selectedRego.vin !== rego.vin;
        });
        setRegos(updatedRegos);
        setOpenSuccessSnackbar(true);
      })
      .catch((e) => console.log(e));
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box className={classes.header}>
          <Typography variant="h3" id="modal-title">
            View Registration | {currentUser?.name}
          </Typography>
          <Typography variant="subtitle2" id="modal-description">
            Access customer information
          </Typography>
        </Box>
        <Grid container className={classes.root}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>Customer Name</Typography>
            <Typography>{rego.name}</Typography>
          </Grid>

          <Grid item xs={6} md={4} className={classes.gridItem}>
            <Typography className={classes.fieldName}>
              Customer Address
            </Typography>
            <Typography>{rego.address}</Typography>
          </Grid>

          <Grid item xs={6} md={2} className={classes.gridItem}>
            <Typography className={classes.fieldName}>Post Code</Typography>
            <Typography>{rego.postCode}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>
              Customer Email
            </Typography>
            <Typography>{rego.email}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>
              Customer Phone
            </Typography>
            <Typography>{rego.phone}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.gridItem}
            style={{ marginTop: 5, paddingBottom: isMobile ? 5 : 0 }}
          >
            <Typography variant="h3">Vehicle information</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>Brand</Typography>
            <Typography>{brandNames[rego.brand]}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>Model</Typography>
            <Typography>{rego.model}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>VIN #</Typography>
            <Typography>{rego.vin}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>
              Registration Plate Number
            </Typography>
            <Typography>{rego.plate}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>Key Number</Typography>
            <Typography>{rego.keyNumber || "-"}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>Colour</Typography>
            <Typography>{rego.colour}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.gridItem}>
            <Typography className={classes.fieldName}>Date of Sale</Typography>
            <Typography>
              {format(new Date(rego.dateOfSale), "dd/MM/yyyy") || ""}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              className={classes.buttons}
            >
              {isMobile && (
                <Grid item xs={12}>
                  <Typography variant="h3">Documentation</Typography>
                </Grid>
              )}
              <Grid item className={classes.fileUpload} xs={12} sm="auto">
                <a
                  id="aa"
                  download={`Download.${extension}`}
                  href={"!#"}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                  }}
                >
                  <Button
                    className={classes.button}
                    startIcon={<SaveAltRounded style={{ fontSize: "15px" }} />}
                    color="primary"
                    variant="contained"
                    component="span"
                    disabled={extension ? false : true}
                  >
                    Download PDI
                  </Button>
                </a>
              </Grid>
              {isAdmin && (
                <Grid item xs={12} sm="auto">
                  <Button
                    disabled={openSuccessSnackbar}
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={handleOpenDeleteConfirmation}
                  >
                    Delete
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <Dialog
        className={classes.dialog}
        open={openDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          Are you sure you want to delete this rego?
        </DialogTitle>
        <DialogActions className={classes.dialogButtons}>
          <Button
            className={classes.dialogCancelButton}
            onClick={handleCloseDeleteConfirmation}
          >
            Cancel
          </Button>
          <Button
            className={classes.dialogButton}
            variant="contained"
            onClick={handleDelete}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSuccessSnackbar}
        onClose={handleCloseSuccessSnackbar}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          style={{ alignItems: "center" }}
        >
          Success!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ViewRego;
