import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";

import brandNames from "../../util/brands";

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    position: "absolute",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  heading: {
    marginBottom: 25,
  },
  filesContainer: {
    display: "flex",
    flexDirection: "column",
  },
  select: {
    backgroundColor: theme.palette.common.white,
    minWidth: 317,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.common.black}`,
    "& .MuiSelect-root": {
      padding: "13px 32px 12px 14px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 260,
    },
  },
  uploadButton: {
    alignSelf: "end",
    marginTop: 22,
  },
  uploadIcon: {
    fontSize: "14px",
  },
  inputLabel: {
    ...theme.typography.h4,
    padding: "5px 0",
  },
  filesListTitle: {
    paddingBottom: 9,
  },
  fileRow: {
    width: "100%",
    padding: 0,
    margin: "0 0 10px",
  },
  file: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    alignItems: "center",
    paddingRight: 3,
    paddingLeft: 19,
    backgroundColor: theme.palette.common.mediumLightGrey,
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    color: theme.palette.common.black,

    wordWrap: "wrap",
  },
  fileText: {
    textDecoration: "none",
    flexGrow: 1,
    paddingTop: 13,
    paddingBottom: 12,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  submitButton: {
    marginTop: 20,
    paddingTop: 13,
    paddingBottom: 12,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UploadFiles(props) {
  const { currentUser, setOpenUploadFiles } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [brand, setBrand] = useState("");
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [progress, setProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const fileInputRef = useRef(null);

  const handleCloseSuccessSnackbar = (event, reason) => {
    setOpenSuccessSnackbar(false);
    setOpenUploadFiles(false);
  };

  // Restart user flow when a brand is selected
  useEffect(() => {
    setNewFiles([]);
    const array = [];
    setFiles([]);
    setProgress(true);
    axios
      .get(`/techLength/${brand}`)
      .then((res) => {
        if (res.data.value > 0) {
          for (let index = 0; index < res.data.value; index++) {
            axios.defaults.headers.common["Authorization"] = localStorage.Token;
            axios
              .get(`/techDocs/${brand}/${index}`, {
                responseType: "arraybuffer",
              })
              .then((res) => {
                const n = res.headers["content-disposition"]
                  .split("filename=")[1]
                  .replace(/"/g, "");
                const url = window.URL.createObjectURL(
                  new Blob([res.data], { type: res.headers["content-type"] })
                );
                array.push({ [n]: url });
                setFiles([...array]);
              })
              .catch((e) => {
                setProgress(false);
              });
          }
        }
        setProgress(false);
      })
      .catch((e) => {
        setProgress(false);
        console.log(e);
      });
  }, [brand]);

  const handleSelectFiles = (e) => {
    let fileList = Array.from(e.target.files);
    let arr = [];
    if (newFiles.length === 0) {
      setNewFiles(fileList);
    } else {
      setNewFiles([...newFiles, ...fileList]);
    }
    fileList.forEach((file) => {
      arr.push({ [file.name]: "#" });
      setFiles([...arr, ...files]);
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (newFiles.length > 0) {
        const data = new FormData();
        data.append("files", newFiles);
        for (let i = 0; i < newFiles.length; i++) {
          data.append("files", newFiles[i]);
        }
        await axios.post(`/uploadFiles/${brand}`, data);
      }
      if (removedFiles.length > 0) {
        await axios.post(`/deleteFiles/${brand}`, removedFiles);
      }

      setLoading(false);
      setOpenSuccessSnackbar(true);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setOpenErrorSnackbar(true);
    }
  };

  const handleDelete = (f) => {
    const a = files.filter((n) => n !== f);
    const b = newFiles.filter((p) => p.name !== Object.keys(f)[0]);
    setFiles(a);
    setNewFiles(b);
    if (Object.values(f)[0] !== "#") {
      setRemovedFiles([...removedFiles, Object.keys(f)[0]]);
    }
  };

  const options = [];
  Object.keys(brandNames).forEach((brand, idx) => {
    options.push(
      <MenuItem key={idx} value={brand}>
        {brandNames[brand]}
      </MenuItem>
    );
  });

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.heading}>
          <Typography variant="h3" id="modal-title">
            Upload | {currentUser?.name}
          </Typography>
          <Typography variant="subtitle2" id="modal-description">
            Upload files to the database
          </Typography>
        </Box>

        <Box>
          <Box className={classes.filesContainer}>
            <FormControl className={classes.formControl}>
              <Typography
                component="label"
                className={classes.inputLabel}
                htmlFor="select-brand"
              >
                Select brand
              </Typography>
              <Select
                variant="filled"
                className={classes.select}
                disableUnderline
                displayEmpty
                fullWidth
                id="select-brand"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                inputProps={{ "aria-label": "Select brand" }}
              >
                {options.map((option) => option)}
              </Select>
            </FormControl>
            {brand && !progress && (
              <>
                <input
                  hidden
                  ref={fileInputRef}
                  id="fileButton"
                  type="file"
                  multiple
                  onClick={(e) => (e.target.value = null)}
                  onChange={handleSelectFiles}
                />

                <Button
                  className={classes.uploadButton}
                  aria-label="Upload files"
                  onClick={() => fileInputRef.current.click()}
                  startIcon={
                    <SvgIcon
                      component={UploadIcon}
                      className={classes.uploadIcon}
                      viewBox="-5 -4 22 22"
                    />
                  }
                >
                  Upload more files
                </Button>

                {files.length > 0 && (
                  <>
                    <Typography variant="h4" className={classes.filesListTitle}>
                      Selected files...
                    </Typography>
                    <List disablePadding>
                      {files.map((file, idx) => {
                        return (
                          <ListItem
                            className={classes.fileRow}
                            disableGutters
                            key={idx}
                          >
                            <Box className={classes.file}>
                              <Typography
                                className={classes.fileText}
                                variant="h4"
                                component="a"
                                href={Object.values(file)[0]}
                                download={Object.keys(file)[0]}
                              >
                                {Object.keys(file)[0]}
                              </Typography>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="delete"
                                  className={classes.delete}
                                  onClick={() => handleDelete(file)}
                                >
                                  <DeleteIcon
                                    style={{
                                      color: theme.palette.common.black,
                                      fontSize: "15px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                )}
              </>
            )}
            {progress && <CircularProgress />}
          </Box>
          <Box>
            <Button
              disabled={
                loading ||
                (newFiles.length === 0 && removedFiles.length === 0) ||
                openSuccessSnackbar
              }
              type="submit"
              fullWidth
              onClick={handleSubmit}
              className={classes.submitButton}
              variant="contained"
              color="primary"
              startIcon={<SaveRoundedIcon style={{ fontSize: "15px" }} />}
            >
              Save Changes
              {loading && (
                <CircularProgress
                  size={20}
                  className={classes.circularProgress}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnackbar(false)}
      >
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error">
          Something went wrong, please try again
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSuccessSnackbar}
      >
        <Alert
          className={classes.snackbar}
          onClose={handleCloseSuccessSnackbar}
          severity="success"
        >
          Success!
        </Alert>
      </Snackbar>
    </>
  );
}
